import React, { useState } from "react";
import GlobalInput from "../../shared/components/globalInput";
import { LeftOutlined, MailOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import usePageTitle from "../../hooks/usePageTitle";
import usePostData from "../../hooks/usePostData";
import Spinner from "../../shared/components/spinner";
import Alert from "../../shared/components/alert";
import { baseDomain } from "../../util/util";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, set_email] = useState();
  usePageTitle("Forgot Your Password? Reset It Here!");
  const { postData, loading, message } = usePostData();

  function handleForgotPassword(e) {
    const data = { email };
    e.preventDefault();
    postData(
      `user/resend-email?isForgotPassword=1&link=http://${baseDomain}/reset-password?token=`,
      data,
      (data) => {},
      "alert"
    );
  }
  return (
    <div
      class="account-section bg_img"
      style={{ background: "url(/assets/images/bg/bg-5.jpg)" }}
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-5 col-lg-7">
            <div class="account-card">
              <div class="account-card__header login_bg bg_img overlay--one">
                <h2 class="section-title">Forgot Password</h2>
              </div>
              <div class="account-card__body">
                {message && <Alert type={message.type} msg={message.msg} />}
                <form onSubmit={handleForgotPassword} class="mt-4">
                  <GlobalInput
                    label="Email"
                    preIcon={
                      <MailOutlined style={{ color: "#ccc", fontSize: 20 }} />
                    }
                    getValue={(val) => {
                      set_email(val.toLowerCase());
                    }}
                    inputPlaceholder="enter your email here"
                    inputType="text"
                    inputVal={email}
                  />
                  <button
                    style={{ fontSize: 14 }}
                    className="btn-unstyled base--color btn d-flex align-items-center"
                    onClick={() => navigate("/login")}
                    disabled={loading}
                  >
                    <LeftOutlined className="mr-2 " />
                    Back to Login
                  </button>
                  <div class="mt-3">
                    <button
                      type="submit"
                      disabled={loading || !email}
                      class="cmn-btn "
                    >
                      {loading ? (
                        <>
                          <Spinner />
                          Please wait...
                        </>
                      ) : (
                        "Send Request"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
