import React, { useEffect, useState } from "react";
import GlobalInput from "../../../shared/components/globalInput";
import { acceptIntegers } from "../../../util/helper_fun";
import Alert from "../../../shared/components/alert";
import { useAppContext } from "../../../contexts/app_context";

const EmailOTP = ({ getCode }) => {
  const [value, setvalue] = useState();
  useEffect(() => {
    value ? getCode(value) : getCode();
  }, [value]);
  const { globalError } = useAppContext();

  return (
    <>
      {globalError && (
        <div className="mb-2">
          <Alert type="error" msg={globalError} />
        </div>
      )}
      <GlobalInput
        label={"Enter code"}
        getValue={(v) => setvalue(acceptIntegers(v))}
        inputVal={value}
      />
    </>
  );
};

export default EmailOTP;
