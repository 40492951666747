import React, { useEffect, useState } from "react";
import MobileNav from "./navigation/mobile_nav";
import { golden_color, small_fontszie } from "../util/util";
import useToggle from "./../hooks/useToggle";
import Logo from "./components/logo";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { activeLink, truncateEmail } from "../util/helper_fun";
import NotificationCounter from "./components/notification_counter";
import { menuData } from "../data";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Header = ({ userData, isUser }) => {
  const [data, setdata] = useState();
  useEffect(() => {
    setdata(userData);
  }, [userData]);
  const [open_navbar, toggle_navbar] = useToggle(false);
  const [show_balance, toggle_balance] = useToggle(false);
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className="position-relative">
      <MobileNav
        is_active={open_navbar}
        close_sidebar={() => toggle_navbar(false)}
      />
      <header class="header">
        <div class="header__bottom">
          <div class="container-fluid">
            <nav
              style={{ flexWrap: "nowrap" }}
              class="navbar position-relative p-0 d-flex w-100 align-items-center"
            >
              <div className="d-flex">
                <button
                  class="m-0 btn p-0 d-lg-none d-block btn-unstyled"
                  type="button"
                  onClick={() => toggle_navbar(true)}
                  style={{ marginLeft: -10 }}
                >
                  <MenuOutlined style={{ fontSize: 20, color: "white" }} />
                </button>
                <Logo />
              </div>
              <ul
                style={{ gap: 30 }}
                className=" d-lg-flex d-none justify-content-end mx-3 my-0 main-menu py-0 "
              >
                {menuData.map((item, index) => (
                  <li key={index} className="">
                    <Link
                      to={item.link}
                      className={`${
                        activeLink(location, item.link) && "base--color"
                      } nav-link`}
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
              <ul class="account-menu mobile-acc-menu d-flex align-items-center mr-0 p-0">
                {!isUser ? (
                  <li class="icon  text-decoration-none mr-3">
                    <a
                      style={{ color: golden_color, fontSize: small_fontszie }}
                      href="/login"
                    >
                      Log In
                    </a>
                  </li>
                ) : (
                  data?.email && (
                    <li class="icon mr-1 styled-font text-decoration-none d-flex m-0 flex-column">
                      <span
                        style={{ color: "rgb(144 140 129)" }}
                        className="user_detail_text"
                      >
                        {truncateEmail(data.email)}
                      </span>
                      <span
                        style={{ fontWeight: "bold" }}
                        className="user_detail_text base--color d-flex align-items-center justify-content-center"
                      >
                        {show_balance
                          ? Number(data?.balance).toLocaleString() + " USD"
                          : "***"}
                        {show_balance ? (
                          <EyeOutlined
                            size={14}
                            className="ml-2 base--color mb-1"
                            onClick={() => toggle_balance(false)}
                          />
                        ) : (
                          <EyeInvisibleOutlined
                            size={14}
                            className="ml-2 base--color mb-1"
                            onClick={() => toggle_balance(true)}
                          />
                        )}
                      </span>
                    </li>
                  )
                )}
                {!isUser ? (
                  <li
                    style={{
                      background: golden_color,
                      fontSize: small_fontszie,
                    }}
                    class=" p-1 btn"
                    role="button"
                  >
                    <a className="text-dark" href="/register">
                      Register
                    </a>
                  </li>
                ) : (
                  data?.photo && (
                    <li class=" p-0 btn" role="button ">
                      <span className="d-flex align-items-end">
                        <img
                          src={data.photo}
                          style={{
                            overflow: "hidden",
                            height: 28,
                            width: 28,
                            maxWidth: 28,
                          }}
                          className="profile_pic rounded-circle"
                          alt=""
                        />
                      </span>
                    </li>
                  )
                )}
                {data && data.unreadNotificationCount > 0 && (
                  <li
                    class="btn p-0 d-none d-lg-block position-relative ml-1"
                    role="button"
                  >
                    <NotificationCounter
                      count={data?.unreadNotificationCount}
                    />
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>{" "}
        <div
          style={{ gap: 5 }}
          className="d-flex d-lg-none w-100 justify-content-between py-1 px-3 align-items-center"
        >
          <button
            onClick={() => navigate("/plan")}
            className="text-white glow styled-font  p-0 cmn-btn"
          >
            Activate Trade
          </button>
          <button
            onClick={() => navigate("/plan/purchase")}
            className="text-white glow styled-font p-0 cmn-btn"
          >
            Purchase Bot
          </button>
          {data && data.unreadNotificationCount > 0 && (
            <span className="position-relative">
              <NotificationCounter count={data?.unreadNotificationCount} />
            </span>
          )}
        </div>
      </header>
    </div>
  );
};

export default Header;
