import React from "react";

const Main = ({
  totalText,
  totalValue,
  name,
  value,
  icon,
  btn_text,
  btnFn,
  isDollar = true,
}) => {
  return (
    <div style={{ flexWrap: "wrap" }} class="mb-5 mt-5 w-100 d-flex">
      <div class="col-md-6 col-12 mb-30">
        <div class="d-widget d-flex flex-wrap">
          <div class="col-8">
            <span class="caption">{totalText}</span>
            <h4 class="currency-amount ">
              {isDollar && "$"}
              {totalValue || "0"}
            </h4>
          </div>
          <div class="col-4">
            <div class="icon ml-auto">{icon || "icon"}</div>
          </div>
          {btn_text && (
            <span className="d-flex mt-3 justify-content-center w-100">
              <button
                style={{ backgroundColor: "#000" }}
                className="btn styled-font w-100 base--color p-1"
                onClick={btnFn}
              >
                {btn_text}
              </button>
            </span>
          )}
        </div>
      </div>
      {(value || name) && (
        <div class="col-md-6 col-12 mb-30">
          <div class="d-widget d-flex flex-wrap">
            <div class="col-8">
              <span class="caption">{name}</span>
              <h4 class="currency-amount ">{value || "0"}</h4>
            </div>
            <div class="col-4">
              <div class="icon ml-auto">{icon || "icon"}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Main;
