import React, { useEffect, useRef, useState } from "react";
import { QRCode } from "antd";
import GlobalInput from "../../../../shared/components/globalInput";
import { handleCopy } from "../../../../util/helper_fun";
import PopTooltip from "./../../../../shared/components/pop_tool_tip";

const CryptoModal = ({ text, additionText }) => {
  const inputRef = useRef(null);
  const [active_tooltip, set_active_tooltip] = useState(false);
  
  return (
    <>
      <div
        style={{ marginTop: -30 }}
        className="d-flex w-100 justify-content-center"
      >
        {text && <QRCode bgColor="white" size={200} value={text} />}
      </div>
      <GlobalInput
        postIcon={
          <span role="button" className="base--color position-relative btn">
            copy{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-copy"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
              />
            </svg>
            <PopTooltip active={active_tooltip} />
          </span>
        }
        inputFn={() => {
          set_active_tooltip(true);
          handleCopy(inputRef, () => {
            set_active_tooltip(false);
          });
        }}
        inputVal={text}
        readOnly={true}
        inputRef={inputRef}
      />
      <span style={{ color: "#ccc" }} className="text-center">
        {additionText}
      </span>
    </>
  );
};

export default CryptoModal;
