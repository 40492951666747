import React from "react";

const CustomError = ({title, description}) => {
  return (
    <div class="text-light col styled-font">
      <div class="container d-flex justify-content-center align-items-center min-vh-100">
        <div class="text-center">
          <h1 class="display-3 base--color">Oops!</h1>
          <h2 class="display-5 mb-4">{title} Not Found</h2>
          <p class="lead">{description}</p>
          <p>
            Please try again later,{" "}
            <a href="/support" className="base--color">
              {" "}
              contact support{" "}
            </a>{" "}
            or go to{" "}
            <a className="base--color" href="/">
              {" "}
              Homepage
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default CustomError;
