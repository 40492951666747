import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useGetPrivateData from "../../../hooks/useGetPrivateData";
import useGlobalLoading from "./../../../hooks/useGlobalLoading";

const Account = () => {
  const { getPrivateData, loading } = useGetPrivateData();
  const [data, set_data] = useState();
  useEffect(() => {
    getPrivateData("/total", (data) => {
      set_data(data);
    });
  }, []);
  const account_data = [
    {
      name: "Account Balance",
      value: "$" + Number(data?.balance).toLocaleString(),
      icon: <i class="las la-dollar-sign"></i>,
    },
    {
      name: "Total Deposit",
      value: "$" + data?.deposit.sum.toLocaleString(),
      icon: <i class="las la-wallet"></i>,
      slug: "See more",
      url: "/dashboard/deposit",
    },
    {
      name: "Total Withdrawal",
      value: "$" + data?.withdraw.sum,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
          fill="currentColor"
          class="bi bi-wallet-fill"
          viewBox="0 0 16 16"
        >
          <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2z" />
          <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5z" />
        </svg>
      ),
      slug: "See more",
      url: "/dashboard/withdraw",
    },
    {
      name: "Total Profit",
      value: "$" + data?.invest.sum.toLocaleString(),
      icon: <i class="las la-credit-card"></i>,
      slug: "See more",
      url: "/dashboard/history",
    },
    {
      name: "Total Referral Earnings",
      value: "$" + data?.referral.sum?.toLocaleString(),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
          fill="currentColor"
          class="bi bi-people"
          viewBox="0 0 16 16"
        >
          <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4" />
        </svg>
      ),
      slug: "See more",
      url: "/dashboard/referral",
    },
    {
      name: "Total Investments",
      value: data?.invest.length.toLocaleString(),
      icon: <i class="las la-cubes "></i>,
      slug: "See more",
      url: "/dashboard/history",
    },
    {
      name: "Total Purchase",
      value: data?.purchase.length.toLocaleString(),
      icon: <i class="las la-cubes "></i>,
      slug: "See more",
      url: "/dashboard/purchase",
    },
  ];
  useGlobalLoading([loading], loading);
  return (
    !loading && (
      <div class="row mb-none-30 mt-5">
        {account_data.map((item, index) => (
          <div key={index} class="col-12 col-md-6 mb-30">
            <div class="d-widget d-flex flex-wrap">
              <div class="col-8">
                <span class="caption">{item.name}</span>
                <h4 class="currency-amount">{item.value}</h4>
              </div>
              <div class="col-4">
                <div class="icon ml-auto">{item.icon}</div>
              </div>
              {item.slug && (
                <span className="d-flex d-lg-none mt-3 justify-content-center w-100">
                  <Link
                    style={{ backgroundColor: "#000" }}
                    className="btn styled-font w-100 base--color p-1"
                    to={item.url}
                  >
                    {item.slug}
                  </Link>
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    )
  );
};

export default Account;
