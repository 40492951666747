import React, { useEffect, useState } from "react";
import usePageTitle from "../../hooks/usePageTitle";
import Hero from "../../shared/components/hero";
import { Outlet } from "react-router-dom";
import useToggle from "./../../hooks/useToggle";
import { my_nav_items } from "../../data";
import { useAppContext } from "../../contexts/app_context";
import Preloader from "../../shared/components/preloader";
import Error401 from "../../shared/components/Error401";
import Sidebar from './../../shared/components/Sidebar';

const Dashboard = ({ isAdmin, isUser = null }) => {
  const { name } = useAppContext();
  usePageTitle(name || "" + ":Dashboard");
  const [show, toggleShow] = useToggle(false);
  const [data, setdata] = useState([]);
  useEffect(() => {
    const data = isAdmin
      ? my_nav_items
      : my_nav_items.filter((item) => item.name !== "Admin");
    setdata(data);
  }, [isAdmin]);
  if (isUser === null) {
    return <Preloader customDisplay={true} />;
  }
  if (isUser === false) {
    return <Error401 />;
  }
 

  return (
    isUser === true && (
      <div class=" pb-120">
        <Hero />
        <div style={{ flexWrap: "nowrap" }} className={`row w-100 h-100 `}>
          <div className="col-auto p-0">
            <Sidebar show={show} toggle_show={toggleShow} data={data} />
          </div>
          <div className="col-10 p-0 ml-2">
            <Outlet />
          </div>
        </div>
      </div>
    )
  );
};

export default Dashboard;
