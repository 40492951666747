import React, { useState } from "react";
import { axiosPrivate } from "../api/axios";
import { useAppContext } from "./../contexts/app_context";
import useGlobalError from "./useGlobalError";
import useGlobalLoading from "./useGlobalLoading";

const useDelePrivateData = () => {
  const { handleNotice } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  useGlobalError([message], message?.msg);
  useGlobalLoading([loading], loading);
  async function deletePrivateData(url, successFunction, noticeType) {
    try {
      setMessage();
      setLoading(true);
      const response = await axiosPrivate.delete(url);
      if (noticeType === "alert") {
        successFunction(response.data.data);
        setMessage({ msg: response.data.message, type: response.data.type });
      } else {
        handleNotice(response.data.message, response.data.type, () => {
          successFunction(response.data.data);
        });
      }
      setLoading(false);
    } catch (error) {
      setMessage({
        msg: error.response?.data.message || error.message,
        type: error.response?.data.type || "error",
      });

      setLoading(false);
    }
  }
  return { deletePrivateData, loading, message };
};

export default useDelePrivateData;
