import React, { useEffect } from "react";
import useGetPrivateData from "./useGetPrivateData";

const useGetDataOnLoad = (
  url,
  getData = () => {},
  dependency = [],
  throwError
) => {
  const { getPrivateData } = useGetPrivateData(throwError);
  useEffect(() => {
    getPrivateData(url, (d) => {
      getData(d);
    });
  }, dependency);
};

export default useGetDataOnLoad;
