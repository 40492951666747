import React from "react";
import { Helmet } from "react-helmet";
import { Tawkto_ID } from "../util/util";

const Tawkto = () => {
  return (
    <Helmet>
      <script
        src={`https://embed.tawk.to/${Tawkto_ID}`}
        crossorigin="*"
      ></script>
    </Helmet>
  );
};

export default Tawkto;
