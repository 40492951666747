import React, { useEffect, useState } from "react";
import GlobalInput from "../../../shared/components/globalInput";
import {
  acceptPositiveIntegers,
  capitalizeFirstLetter,
} from "../../../util/helper_fun";
import useGetPrivateData from "../../../hooks/useGetPrivateData";

const AddNewPlan = ({ getData = () => {}, error = () => {}, isEdit }) => {
  const [name, setname] = useState();
  const [profit, setprofit] = useState();
  const [duration, setduration] = useState();
  const [durationDays, setDurationDays] = useState();
  const [referralPer, setreferralPer] = useState();
  const [min, setmin] = useState();
  const [max, setmax] = useState();
  const { getPrivateData } = useGetPrivateData();
  useEffect(() => {
    !name ||
    !profit ||
    !duration ||
    !referralPer ||
    !min ||
    !max ||
    !durationDays
      ? error(false)
      : error(true);
    getData({
      name,
      profit: profit + (profit?.includes("%") ? "" : "%"),
      min,
      max,
      duration,
      days: durationDays,
      commission: referralPer + (referralPer?.includes("%") ? "" : "%"),
    });
  }, [name, profit, duration, referralPer, min, max, durationDays]);
  useEffect(() => {
    isEdit &&
      getPrivateData(`/plan/${capitalizeFirstLetter(isEdit)}`, (data) => {
        setmin(data.min);
        setmax(data.max);
        setname(data.name);
        setduration(data.duration);
        setreferralPer(data.commission);
        setprofit(data.profit);
        setDurationDays(data.durationInSecs / 86400);
      });
  }, [isEdit]);
  return (
    <>
      <GlobalInput
        getValue={(v) => {
          setname(v ? capitalizeFirstLetter(v) : "");
        }}
        label={"Plan"}
        inputVal={name}
      />
      <GlobalInput
        getValue={(v) => {
          setprofit(acceptPositiveIntegers(v));
        }}
        inputPlaceholder={"E.g 15"}
        label={"Profit"}
        inputVal={profit}
      />
      <GlobalInput
        noSanitize={true}
        getValue={(v) => {
          setduration(v);
        }}
        label={"Duration"}
        inputVal={duration}
        inputPlaceholder={"E.g 24 hours"}
      />
      <GlobalInput
        noSanitize={true}
        getValue={(v) => {
          setDurationDays(acceptPositiveIntegers(v));
        }}
        label={"Duration in Days"}
        inputVal={durationDays}
        inputPlaceholder={"E.g 4"}
      />
      <GlobalInput
        getValue={(v) => {
          setreferralPer(acceptPositiveIntegers(v));
        }}
        label={"Referral Percentage"}
        inputVal={referralPer}
        inputPlaceholder={"E.g 5"}
      />
      <GlobalInput
        getValue={(v) => {
          setmin(acceptPositiveIntegers(v));
        }}
        label={"Minimum price"}
        inputVal={min}
      />
      <GlobalInput
        getValue={(v) => {
          setmax(acceptPositiveIntegers(v));
        }}
        label={"Maximum Price"}
        inputVal={max}
      />
    </>
  );
};

export default AddNewPlan;
