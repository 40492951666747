import React, { useEffect, useState } from "react";
import useGetDataOnLoad from "../../../hooks/useGetLoadData";
import { useAppContext } from "../../../contexts/app_context";
import {
  capitalizeFirstLetter,
  handleSetModal,
  truncateEmail,
} from "../../../util/helper_fun";
import Main from "../../dashboard/components/main_data";
import HistoryData from "../../dashboard/components/history_data";
import usePutPrivateData from "../../../hooks/usePutPrivateData";
import CryptoModal from "../../dashboard/components/modals/cryptoModal";

const AdminWithdraw = () => {
  const [data, set_data] = useState([]);
  const [raw_data, set_raw_data] = useState([]);
  const [index, set_index] = useState(0);
  const [completed, set_completed] = useState(0);
  const [pending, set_pending] = useState(0);
  const [declined, set_declined] = useState(0);
  const [totalData, setTotalData] = useState();
  const [da, set_d] = useState([]);
  const { postData } = usePutPrivateData();
  useGetDataOnLoad(
    "admin/withdraw",
    (d) => {
      set_raw_data(
        d.map((item, index) => {
          return {
            Email: <span>{truncateEmail(item.email)}</span>,
            "Transaction ID": item.id,
            Amount: Number(item.amount).toLocaleString(),
            Status: (
              <span
                className={`${
                  item.status === "pending"
                    ? "glow bg-transparent base--color"
                    : item.status === "declined"
                    ? "bg-danger text-white"
                    : "bg-success text-white"
                } p-1 rounded`}
              >
                {item.status === "pending" ? "confirm" : item.status}
              </span>
            ),
            Date: item.date,
            id: item._id,
            address: item.address,
          };
        })
      );
      setTotalData(d.length);
      set_d(d);
    },
    []
  );
  const { setShow } = useAppContext();
  const tabData = [
    { name: "All", length: raw_data.length },
    { name: "Pending", length: pending },
    { name: "Completed", length: completed },
    { name: "Declined", length: declined },
  ];
  useEffect(() => {
    const filteredDataPending = raw_data.filter(
      (item) => item.Status.props.children === "confirm"
    );
    const filteredDataCompleted = raw_data.filter(
      (item) => item.Status.props.children === "completed"
    );
    const filteredDataDeclined = raw_data.filter(
      (item) => item.Status.props.children === "declined"
    );
    set_completed(filteredDataCompleted.length);
    set_pending(filteredDataPending.length);
    set_declined(filteredDataDeclined.length);
    if (index === 0) {
      set_data(raw_data);
    }
    if (index === 1) {
      set_data(filteredDataPending);
    }
    if (index === 2) {
      set_data(filteredDataCompleted);
    }
    if (index === 3) {
      set_data(filteredDataDeclined);
    }
  }, [index, raw_data]);
  async function confirmDeposit(id, email, action, amount, address) {
    const status = action === "decline" ? "declined" : "completed";
    setShow((prev) => [
      ...prev,
      handleSetModal(
        <>
          <span className="mb-5">
            {"Are you sure you want to " +
              action +
              " this withdrawal request with ID: " +
              id +
              " ?" }
          </span>
          {address && (
            <CryptoModal
              text={address}
              additionText={"Copy address and pay " + email}
            />
          )}
          
        </>,
        capitalizeFirstLetter(action),
        capitalizeFirstLetter(action) + " withdrawal",
        () => {
          postData("withdraw", { email, amount, status, id }, () => {
            window.location.reload();
          });
        }
      ),
    ]);
  }
  function findEmail(params) {
    let foundEmail = null;
  
    da.forEach(item => {
      const itemKeys = Object.keys(item);
      if (itemKeys.includes('_id') && item['_id'] === params) {
        foundEmail = item['email'];
      }
    });
  
    return foundEmail;
  }
  return (
    <>
      <Main
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            class="bi bi-wallet-fill"
            viewBox="0 0 16 16"
          >
            <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.248.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2z" />
            <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5z" />
          </svg>
        }
        totalText={"Total Withdrawal"}
        totalValue={totalData}
        isDollar={false}
      />{" "}
      <HistoryData
        data={data}
        condition={"Email"}
        tab={tabData}
        activeTabIndex={(data) => {
          set_index(data);
        }}
        data_name={"withdraw"}
        isUSD={false}
        dropdownItem={[
          {
            name: "Confirm",
            fnx: (d) => {
              const id = d["Transaction ID"];
              const amount = d.Amount;
              confirmDeposit(id, findEmail(d.id), "confirm", amount, d.address);
            },
          },
          {
            name: "Decline",
            fnx: (d) => {
              const id = d["Transaction ID"];
              const amount = d.Amount;
              confirmDeposit(id, findEmail(d.id), "decline", amount);
            },
          },
        ]}
        dropdownBtnName="Action"
        disabled={data.map((item) =>
          item.Status.props.children === "confirm" ? false : true
        )}
        hideColumn={["id", "address"]}
      />
    </>
  );
};

export default AdminWithdraw;
