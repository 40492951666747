import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  InfoCircleFilled,
  WarningFilled,
} from "@ant-design/icons";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import usePutPublicData from "./../../hooks/usePutPublicData";
import usePageTitle from "../../hooks/usePageTitle";
import Preloader from "../../shared/components/preloader";

const VerifyEmail = () => {
  const items = [
    {
      type: "error",
      icon: (
        <ExclamationCircleFilled style={{ color: "#cca354", fontSize: 120 }} />
      ),
    },
    {
      type: "success",
      icon: <CheckCircleFilled style={{ color: "#cca354", fontSize: 120 }} />,
    },
    {
      type: "warning",
      icon: <WarningFilled style={{ color: "#cca354", fontSize: 120 }} />,
    },
    {
      type: "info",
      icon: <InfoCircleFilled style={{ color: "#cca354", fontSize: 120 }} />,
    },
  ];
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  usePageTitle(`Verify Your Email: Complete the Registration Process`);
  const { postData, loading, message } = usePutPublicData();
  const paramsToken = searchParams.get("token");
  function handleVerifyEmail(e) {
    const data = {};
    postData(
      `user/verify?token=${paramsToken}`,
      data,
      (data) => {
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      },
      "alert"
    );
  }
  useEffect(() => {
    paramsToken ? handleVerifyEmail() : navigate("/login");
  }, []);

  return message && !loading ? (
    <div
      class="account-section bg_img"
      style={{ background: "url(/assets/images/bg/bg-5.jpg)" }}
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-5 col-lg-7">
            <div class="account-card glow">
              <div class="account-card__body">
                <div class="my_result ">
                  <span style={{}} className="">
                    {
                      items.find(
                        (item) => item.type === message?.type.toLowerCase()
                      ).icon
                    }
                  </span>
                  <p
                    dangerouslySetInnerHTML={{ __html: message?.msg }}
                    className="my_result_text"
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Preloader />
  );
};

export default VerifyEmail;
