import React from "react";

const EmptyArray = ({ data_name }) => {
  return (
    <div
      style={{ width: "95%", gap: 15 }}
      className="d-flex mt-5 align-items-center flex-column justify-content0center "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        fill="currentColor"
        class="bi bi-search"
        viewBox="0 0 16 16"
      >
        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
      </svg>
      <p className="styled-font">No {data_name || ""} data found</p>
    </div>
  );
};

export default EmptyArray;
