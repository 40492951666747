import React from "react";
import { useNavigate } from "react-router-dom";

const NotificationCounter = ({ count }) => {
  const navigate = useNavigate();
  return (
    <div role="button" onClick={() => navigate("/dashboard/notification")}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        class="bi bi-bell-fill gray-color"
        viewBox="0 0 16 16"
      >
        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
      </svg>
      <div
        style={{
          width: 14,
          height: 14,
          fontSize: 11,
          right: -2,
          top: -2,
          color: "white",
          background: "#cca354",
          // textAlign: "center",
        }}
        className="glow d-flex position-absolute align-items-center justify-content-center rounded-circle p-2"
      >
        {count}
      </div>
    </div>
  );
};

export default NotificationCounter;
