import React, { useEffect, useState } from "react";
import GlobalInput from "../../../../shared/components/globalInput";
import useGetPrivateData from "../../../../hooks/useGetPrivateData";

const Wallet = ({ getValue }) => {
  const [value, setvalue] = useState();
  const { getPrivateData: getName } = useGetPrivateData();
  useEffect(() => {
    getName("user?params=address", (d) => setvalue(d));
  }, []);
  useEffect(() => {
    value ? getValue(value) : getValue();
  }, [value]);

  return (
    <>
      <GlobalInput
        getValue={(v) => setvalue(v)}
        inputVal={value}
        label={"Edit Wallet Address"}
      />
    </>
  );
};

export default Wallet;
