import React from "react";
import { useAppContext } from "../../../contexts/app_context";

const HowItWorks = () => {
  const {siteData}=useAppContext()
  return (
    <section
      class="pt-120 pb-120 bg_img"
      style={{ background: "url(/assets/images/bg/bg-5.jpg)" }}
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 text-center">
            <div class="section-header">
              <h2 class="section-title">
                <span class="font-weight-normal">How</span>{" "}
                <b class="base--color">{siteData?.app_name}</b>{" "}
                <span class="font-weight-normal">Works</span>
              </h2>
              <p>
                Get involved in our tremendous platform and Invest. We will
                utilize your money and give you profit in your wallet
                automatically.
              </p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center mb-none-30">
          <div class="col-lg-4 col-md-6 work-item mb-30">
            <div class="work-card text-center">
              <div class="work-card__icon">
                <i class="las la-user base--color"></i>
                <span class="step-number">01</span>
              </div>
              <div class="work-card__content">
                <h4 class="base--color mb-3">Create Account</h4>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 work-item mb-30">
            <div class="work-card text-center">
              <div class="work-card__icon">
                <i class="las la-hand-holding-usd base--color"></i>
                <span class="step-number">02</span>
              </div>
              <div class="work-card__content">
                <h4 class="base--color mb-3">Invest</h4>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 work-item mb-30">
            <div class="work-card text-center">
              <div class="work-card__icon">
                <i class="las la-wallet base--color"></i>
                <span class="step-number">03</span>
              </div>
              <div class="work-card__content">
                <h4 class="base--color mb-3">Earn</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
