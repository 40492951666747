import React, { useState } from "react";
import useGetDataOnLoad from "./../../../hooks/useGetLoadData";
import { Link } from "react-router-dom";

const Overview = () => {
  const [data, setdata] = useState([]);
  useGetDataOnLoad("admin/total", (d) => setdata(d));
  const site_data = [
    {
      name: " All Users",
      value: data?.totalUsers,
      icon: <i class="las la-dollar-sign"></i>,
      slug: "See more",
      url: "/admin/user",
    },
    {
      name: "All Deposit",
      value: data.totalDeposit,
      icon: <i class="las la-wallet"></i>,
      slug: "See more",
      url: "/admin/deposit",
    },
    {
      name: "All Withdrawal",
      value: data?.totalWithdrawal,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
          fill="currentColor"
          class="bi bi-wallet-fill"
          viewBox="0 0 16 16"
        >
          <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2z" />
          <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5z" />
        </svg>
      ),
      slug: "See more",
      url: "/admin/withdraw",
    },
    {
      name: "Total Investments",
      value: data?.totalInvestment,
      icon: <i class="las la-cubes "></i>,
      slug: "See more",
      url: "/admin/invest",
    },
    {
      name: "Total Purchases",
      value: data?.totalPurchase,
      icon: <i class="las la-cubes "></i>,
      slug: "See more",
      url: "/dashboard/purchase",
    },
    {
      name: "Total Signal",
      value: data?.totalSignal,
      icon: <i class="las la-cubes "></i>,
      slug: "See more",
      url: "/dashboard/signal",
    },
    {
      name: "Total Management Purchase",
      value: data?.totalManagement,
      icon: <i class="las la-cubes "></i>,
      slug: "See more",
      url: "/dashboard/management",
    },
  ];
  return (
    <div class="row mb-none-30 mt-5">
      {site_data.map((item, index) => (
        <div key={index} class="col-12 col-md-6 mb-30">
          <div class="d-widget d-flex flex-wrap">
            <div class="col-8">
              <span class="caption">{item.name}</span>
              <h4 class="currency-amount">{item.value}</h4>
            </div>
            <div class="col-4">
              <div class="icon ml-auto">{item.icon}</div>
            </div>
            <span className="d-flex mt-3 justify-content-center w-100">
              <Link
                style={{ backgroundColor: "#000" }}
                className="btn styled-font w-100 base--color p-1"
                to={item.url}
              >
                {item.slug}
              </Link>
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Overview;
