import usePageTitle from "../../hooks/usePageTitle";
import Hero from "../../shared/components/hero";
import Accordion from "./conmponent/Accordion";
import React from 'react'

const FAQ = ({ isUser, isAdmin }) => {
  usePageTitle(`Explore Frequently Asked Questions: Your Queries Answered!`);
  return (
    <>
      <Hero />
      <section class="pt-120 pb-120">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6 text-center">
              <div class="section-header">
                <h2 class="section-title">
                  <span class="font-weight-normal">Frequently Asked</span>{" "}
                  <b class="base--color">Questions</b>
                </h2>
                <p>
                  We answer some of your Frequently Asked Questions regarding
                  our platform. If you have a question that is not answered
                  here, Please contact us.
                </p>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-8">
              <Accordion isAdmin={isAdmin} />
            </div>
          </div>
        </div>
      </section>
      <section class="pb-120">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-8">
              <div
                class="cta-wrapper bg_img border-radius--10 text-center"
                style={{
                  background: "url(/assets/images/bg/bg-8.jpg)",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <h2 class="title mb-3">Start Your Financial Journey Now!</h2>
                <p>
                  Experience a Cutting-Edge Money-Making Platform! Invest in
                  Stability for Your Future and Unlock Rapid Profits. We not
                  only assure the fastest and most thrilling returns on your
                  investments, but also guarantee the utmost security for your
                  finances.
                </p>
                <p>
                  Explore the world of Forex trading and discover the potential
                  of Forex bots. Secure your financial future while achieving
                  swift gains. Join us today and make your investments count!
                </p>
                {!isUser && (
                  <a href="/register" class="cmn-btn mt-4">
                    Join Us
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FAQ;
