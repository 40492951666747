import React from "react";
import Accordion from "./../../faq/conmponent/Accordion";

const FAQ = () => {
  return (
    <section class="pt-120 pb-120">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 text-center">
            <div class="section-header">
              <h2 class="section-title">
                <span class="font-weight-normal">Frequently Asked</span>{" "}
                <b class="base--color">Questions</b>
              </h2>
              <p>
                Explore answers to commonly asked questions about our services,
                trading, security measures, and more. Find solutions to queries
                that help simplify your experience and decision-making process.
              </p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <Accordion />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
