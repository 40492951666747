import React, { useState } from "react";
import GlobalInput from "../../../shared/components/globalInput";
import Alert from "../../../shared/components/alert";
import { useAppContext } from "../../../contexts/app_context";

const Newsletter = () => {
  const [message, setmessage] = useState();
  const { setGlobalLoading } = useAppContext();
  return (
    <section class="pb-120">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div
              class="subscribe-wrapper bg_img"
              style={{ background: "url(assets/images/bg/bg-5.jpg)" }}
            >
              <div class="row align-items-center">
                <div class="col-lg-5">
                  <h2 class="title"> Subscribe To Our Newsletter</h2>
                </div>
                <div class="col-lg-7 mt-lg-0 mt-4">
                  {message && <Alert type="error" msg={message} />}
                  <form class="">
                    <GlobalInput
                      inputPlaceholder={"Email Address"}
                      getValue={(e) => {}}
                      postIcon={
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setGlobalLoading(true);
                            setTimeout(() => {
                              setGlobalLoading(false);
                              setmessage(
                                "Error occured trying to suscribe. Try again later"
                              );
                            }, 1000 * 2);
                          }}
                          class="subscribe-btn btn btn-unstyled text-light w-auto"
                        >
                          Send
                        </button>
                      }
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;
