import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  InfoCircleFilled,
  WarningFilled,
} from "@ant-design/icons";
import React from "react";

const Alert = ({ type = "success", msg }) => {
  const items = [
    {
      type: "error",
      icon: (
        <ExclamationCircleFilled style={{ color: "#cca354", fontSize: 20 }} />
      ),
    },
    {
      type: "success",
      icon: <CheckCircleFilled style={{ color: "#cca354", fontSize: 20 }} />,
    },
    {
      type: "warning",
      icon: <WarningFilled style={{ color: "#cca354", fontSize: 20 }} />,
    },
    {
      type: "info",
      icon: <InfoCircleFilled style={{ color: "#cca354", fontSize: 20 }} />,
    },
  ];
  return (
    <div class="my_alert position-relative glow my-1">
      <span style={{ top: 2, left: 5 }} className="position-absolute mb-2">
        {items.find((item) => item.type === type.toLowerCase()).icon}
      </span>
      <p
        dangerouslySetInnerHTML={{ __html: msg }}
        className="my-4 px-4"
        style={{ fontSize: 13, textAlign: "center" }}
      ></p>
    </div>
  );
};

export default Alert;
