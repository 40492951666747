import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  InfoCircleFilled,
  WarningFilled,
} from "@ant-design/icons";
import React from "react";

const Notification = ({ type = "success", msg, show }) => {
  const items = [
    {
      type: "error",
      icon: (
        <ExclamationCircleFilled style={{ color: "#cca354", fontSize: 20 }} />
      ),
    },
    {
      type: "success",
      icon: <CheckCircleFilled style={{ color: "#cca354", fontSize: 20 }} />,
    },
    {
      type: "warning",
      icon: <WarningFilled style={{ color: "#cca354", fontSize: 20 }} />,
    },
    {
      type: "info",
      icon: <InfoCircleFilled style={{ color: "#cca354", fontSize: 20 }} />,
    },
  ];

  return (
    <div style={{ display: show ? "block" : "none", }} class="notification">
      <span style={{ top: 3, left: 5 }} className="position-absolute">
        {items.find((item) => item.type === type.toLowerCase()).icon}
      </span>
      <p className="my-4 px-4" style={{ fontSize: 14, textAlign: "center" }}>
        {msg}
      </p>
      <div class="progress-container">
        <div class="progress-bar"></div>
      </div>
    </div>
  );
};

export default Notification;
