import React from "react";

const Preloader = ({ opacity, customDisplay }) => {
  return (
    <div
      style={{ opacity: opacity ? 0.8 : 1, }}
      className={`${customDisplay?"d-flex":""} preloader`}
    >
      <div class="preloader-container">
        <span class="animated-preloader"></span>
      </div>
    </div>
  );
};

export default Preloader;
