import React, { useState } from "react";
import GlobalInput from "./../../../shared/components/globalInput";

const SiteWallet = ({ sitewallet, saveFnx = () => {} }) => {
  const [siteWallet, setSiteWallet] = useState(sitewallet);

  return (
    <div className="mt-5">
      <GlobalInput
        noSanitize={true}
        inputVal={siteWallet}
        getValue={(v) => setSiteWallet(v)}
        label={"Change Site Wallet"}
      />
      <button
        disabled={!siteWallet || siteWallet === sitewallet}
        className="cmn-btn col-12 col-lg-6 mx-auto"
        onClick={() => saveFnx({ wallet: siteWallet })}
      >
        save
      </button>
    </div>
  );
};

export default SiteWallet;
