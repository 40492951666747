import React from 'react';
import { useAppContext } from "../contexts/app_context";

const Footer = () => {
  const { siteData } = useAppContext();
  const currentYear = new Date().getFullYear()
  return (
    <footer class="footer bg_img" style={{background:"url(assets/images/bg/bg-7.jpg)"}}>
      <div class="footer__top">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
              <a href="/" class="footer-logo">
                <img src="/assets/images/logo.png" alt="image" />
              </a>
              <ul class="footer-short-menu d-flex flex-wrap justify-content-center mt-4">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/privacy">Privacy & Policy</a>
                </li>
                <li>
                  <a href="/legal">Terms & Conditions</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="footer__bottom">
        <div class="container">
          <div class="row">
            <div class="col-md-6 text-md-left text-center">
              <p>
                © {currentYear}{" "}
                <a href="/" class="base--color">
                  {siteData?.app_name}
                </a>
                . All rights reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
