import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
const appContext = createContext();
export function useAppContext(params) {
  return useContext(appContext);
}

const AppContextProvider = ({ children }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [notification_data, set_notification_data] = useState();
  const [show, setShow] = useState([]);
  const [openModal, setOpenModal] = useState([]);
  const [hideServiceTab, setHideServiceTab] = useState(false);
  const [globalError, setGlobalError] = useState();
  const [internalError, setInternalError] = useState(false);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [name, setName] = useState();
  const [siteData, setSiteData] = useState();
  const [open, setOpen] = useState({
    open: false,
    content: "",
  });
  function openImgModal(params) {
    setOpen({
      open: true,
      content: params,
    });
  }
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  function handleNotice(msg, type, noticeFxn) {
    set_notification_data({
      show: true,
      msg,
      type,
    });
    setTimeout(() => {
      set_notification_data();
      noticeFxn();
    }, 2000);
  }
  useEffect(() => {
    if (internalError) {
      setGlobalLoading(false);
    }
  }, [internalError]);

  function handleClose(params) {
    setOpenModal(show.filter((_, i) => i !== params));
    setShow(show.filter((_, i) => i !== params));
    setGlobalError();
  }
  useEffect(() => {
    show.length > 0 && setOpenModal(show.map((_, i) => i));
  }, [show]);
  function closeImgModal(onCloseFx) {
    setOpen({
      open: false,
      content: "",
    });
  }
  
  return (
    <appContext.Provider
      value={{
        screenWidth,
        handleNotice,
        notification_data,
        handleClose,
        show,
        setShow,
        openModal,
        setOpenModal,
        hideServiceTab,
        setHideServiceTab,
        setGlobalError,
        globalError,
        setGlobalLoading,
        globalLoading,
        openImgModal,
        closeImgModal,
        open,
        internalError,
        setInternalError,
        name,
        setName,
        siteData,
        setSiteData,
      }}
    >
      {children}
    </appContext.Provider>
  );
};

export default AppContextProvider;
