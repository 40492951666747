import React, { useEffect } from "react";
import { useAppContext } from "../contexts/app_context";

const useUpdateModal = (dependencies, headerText, condition) => {
  const { setShow } = useAppContext();
  useEffect(() => {
    setShow((prev) =>
      prev.map((i) => {
        if (headerText === i.headerText) {
          return {
            ...i,
            condition: condition ? true : false,
          };
        } else {
          return i;
        }
      })
    );
  }, dependencies);
};

export default useUpdateModal;
