import React, { useEffect, useState } from "react";
import { axiosPrivate } from "../api/axios";
import { useAppContext } from "../contexts/app_context";
import useGlobalError from "./useGlobalError";
import useGlobalLoading from "./useGlobalLoading";

const useGetPrivateData = (throwError = true) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const { setInternalError } = useAppContext();
  useGlobalError([message], message?.msg);
  useGlobalLoading([loading], loading);
  async function getPrivateData(url, successFunction) {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(url);
      successFunction(response.data.data);
      setInternalError(false);
      setLoading(false);
    } catch (error) {
      setMessage({
        msg: error.response?.data.message || error.message,
        type: error.response?.data.type || "error",
      });
      setLoading(false);
      setInternalError(throwError);
    }
  }

  return { getPrivateData, loading, message };
};

export default useGetPrivateData;
