import React, { useState } from "react";
import GlobalInput from "./../../../shared/components/globalInput";

const Tiktok = ({ tiktok, saveFnx = () => {} }) => {
  const [tikTok, setTiktok] = useState(tiktok);

  return (
    <div className="mt-5">
      <GlobalInput
        noSanitize={true}
        inputVal={tikTok}
        getValue={(v) => setTiktok(v)}
        label={"Change Tiktok Username"}
      />
      <button
        disabled={!tikTok || tikTok === tiktok}
        className="cmn-btn col-12 col-lg-6 mx-auto"
        onClick={() => saveFnx({ tiktok: tikTok })}
      >
        save
      </button>
    </div>
  );
};

export default Tiktok;
