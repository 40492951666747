import React from "react";

const Logo = () => {
  return (
    <a class="site-logo site-title ml-2 ml-md-0" href="/">
      <img src="/assets/images/logo.png" alt="site-Logo-here" />
    </a>
  );
};

export default Logo;
