import React from "react";
import Logo from "../components/logo";
import { CloseOutlined, RightOutlined } from "@ant-design/icons";
import { menuData } from "../../data";
import { useLocation } from "react-router-dom";
import { activeLink } from "../../util/helper_fun";
import { useAppContext } from "./../../contexts/app_context";

const MobileNav = ({ is_active, close_sidebar }) => {
  const { siteData } = useAppContext();
  const location = useLocation();
  const currentYear = new Date().getFullYear()
  return (
    <div
      style={{
        zIndex: 9999,
        height: "100vh",
        background: "#000",
        position: "fixed",
        top: 0,
      }}
      className={`${is_active ? "w-100 w-md-50" : ""}  my-navbar`}
    >
      <div className="position-relative h-100 w-100 col">
        <div
          style={{
            borderBottomColor: "#333",
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
          }}
          className="d-flex justify-content-between p-3 w-100 align-items-center"
        >
          <div style={{ width: 200 }} className="">
            <Logo />
          </div>
          <button className="btn btn-unstyled p-1" onClick={close_sidebar}>
            <CloseOutlined style={{ color: "#fff", fontSize: 20 }} />
          </button>
        </div>
        <ul className="w-100 mt-4">
          {menuData.map((item, index) => (
            <li
              key={index}
              className={`${
                activeLink(location, item.link) && "active"
              } p-3 my-navbar-item`}
            >
              <a
                href={item.link}
                className="d-flex justify-content-between align-items-center text-decoration-none"
              >
                <span className="d-flex align-items-center ">
                  {item.icon}
                  {item.name}
                </span>
                <RightOutlined style={{ fontSize: 18 }} />
              </a>
            </li>
          ))}
        </ul>
        <div
          style={{ bottom: 0, color: "#999" }}
          className="p-3 position-absolute w-100 align-items-center mb-4 d-flex flex-column"
        >
          <span className="">{siteData?.address}</span>
          <span style={{ fontSize: "14px" }}>
            © {currentYear}{" "}
            <a className="base--color" href="/">
              {siteData?.app_name}{" "}
            </a>{" "}
            All right reserved.
          </span>
        </div>
      </div>
    </div>
  );
};

export default MobileNav;
