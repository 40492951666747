import React, { useEffect, useState } from "react";
import useGetDataOnLoad from "../../../hooks/useGetLoadData";
import { useAppContext } from "../../../contexts/app_context";
import { handleSetModal, truncateEmail } from "../../../util/helper_fun";
import Main from "../../dashboard/components/main_data";
import HistoryData from "../../dashboard/components/history_data";
import usePutPrivateData from "../../../hooks/usePutPrivateData";
import { hideSensitiveData } from "../../../util/util";

const AdminDeposit = () => {
  const [data, set_data] = useState([]);
  const [raw_data, set_raw_data] = useState([]);
  const [index, set_index] = useState(0);
  const [completed, set_completed] = useState(0);
  const [pending, set_pending] = useState(0);
  const [totalData, setTotalData] = useState();
  const [da, set_d] = useState([]);
  const { postData } = usePutPrivateData();
  useGetDataOnLoad(
    "admin/deposit",
    (d) => {
      set_raw_data(
        d.map((item, index) => {
          return {
            Email: <span>{truncateEmail(item.email)}</span>,
            "Transaction ID": item.transactionID,
            Amount: Number(item.amount).toLocaleString(),
            Status: (
              <span
                role="button"
                onClick={() => {
                  item.status === "pending" &&
                    confirmDeposit(
                      item.transactionID,
                      item.email,
                      item.amount,
                      item._id
                    );
                }}
                className={`${
                  item.status === "pending"
                    ? "glow bg-transparent base--color"
                    : "bg-success text-white"
                } p-1 rounded`}
              >
                {item.status === "pending" ? "Confirm" : item.status}
              </span>
            ),
            Date: item.date,
            id: item._id,
          };
        })
      );
      setTotalData(d.length);
      set_d(d);
    },
    []
  );
  const { setShow } = useAppContext();
  const tabData = [
    { name: "All", length: raw_data.length },
    { name: "Pending", length: pending },
    { name: "Completed", length: completed },
  ];
  useEffect(() => {
    const filteredDataPending = raw_data.filter(
      (item) => item.Status.props.children === "Confirm"
    );
    const filteredDataCompleted = raw_data.filter(
      (item) => item.Status.props.children === "completed"
    );
    set_completed(filteredDataCompleted.length);
    set_pending(filteredDataPending.length);
    if (index === 0) {
      set_data(raw_data);
    }
    if (index === 1) {
      set_data(filteredDataPending);
    }
    if (index === 2) {
      set_data(filteredDataCompleted);
    }
  }, [index, raw_data]);
  function confirmDeposit(transactionID, email, amount, id) {
    const status = "completed";
    setShow((prev) => [
      ...prev,
      handleSetModal(
        "Are you sure you want to confirm this deposit with ID: " +
          transactionID +
          " ?",
        "Confirm",
        "Confirm deposit",
        () => {
          postData("deposit", { email, amount, status, id }, () => {
            window.location.reload();
          });
        }
      ),
    ]);
  }
  return (
    <>
      <Main
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            class="bi bi-wallet2"
            viewBox="0 0 16 16"
          >
            <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5z" />
          </svg>
        }
        totalText={"Total Deposit"}
        totalValue={totalData}
        isDollar={false}
      />{" "}
      <HistoryData
        data={data}
        condition={"Email"}
        tab={tabData}
        activeTabIndex={(data) => {
          set_index(data);
        }}
        data_name={"deposit"}
        isUSD={false}
        hideColumn={["id"]}
      />
    </>
  );
};

export default AdminDeposit;
