import React, { useEffect, useState } from "react";
import Tab from "../tab";
import Alert from "./../../../../shared/components/alert";

const Card = () => {
  const [data, set_data] = useState();
  const [error, set_error] = useState();
  return (
    <>
      <Tab
        getItem={(i) => set_data(i)}
        error={(e) => {
          set_error(e);
        }}
      />
      <div
        class="package-card border-0 text-center col-12 col-sm-10 col-md-8 col-lg-6 bg_img"
        style={{ background: "url(/assets/images/bg/bg-4.png)" }}
      >
        {error && <Alert type="error" msg={error?.msg} />}
        {data?.content}
        <button
          disabled={data?.disableBtn}
          onClick={data?.fxn}
          class="cmn-btn btn-md mt-4"
        >
          {data?.name}
        </button>
      </div>
    </>
  );
};

export default Card;
