import React, { useEffect } from "react";
import { useAppContext } from "./../contexts/app_context";

const useGlobalError = (params = [], condtion) => {
  const { setGlobalError } = useAppContext();
  useEffect(() => {
    setGlobalError(condtion);
  }, params);
};

export default useGlobalError;
