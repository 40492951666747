import React from "react";
import SiteName from "../components/SiteName";
import SiteAddress from "../components/SiteAddress";
import SiteNumber from "../components/SiteNumber";
import SiteWallet from "../components/SiteWallet";
import usePutPrivateData from "../../../hooks/usePutPrivateData";
import SiteEmail from "../components/SiteEmail";
import Telegram from "./../components/telegram";
import Tiktok from "./../components/Tiktok";
import Facebook from "./../components/Facebook";
import axios from "axios";
import { domain } from "../../../util/util";

const Site = ({ siteData }) => {
  const { postData } = usePutPrivateData();
  function handleUpdateData(params) {
    postData("site", params, () => {});
  }
  return (
    <div className="col-10 col-lg-6 ml-5">
      <SiteName
        sitename={siteData?.app_name}
        saveFnx={(d) => handleUpdateData(d)}
      />
      <SiteNumber
        sitenumber={siteData?.phone}
        saveFnx={(d) => handleUpdateData(d)}
      />
      <SiteEmail
        siteemail={siteData?.email}
        saveFnx={(d) => handleUpdateData(d)}
      />
      <SiteWallet
        sitewallet={siteData?.wallet}
        saveFnx={(d) => handleUpdateData(d)}
      />
      <Telegram
        telegram={siteData?.telegram}
        saveFnx={(d) => handleUpdateData(d)}
      />
      <Tiktok tiktok={siteData?.tiktok} saveFnx={(d) => handleUpdateData(d)} />
      <Facebook
        facebook={siteData?.facebook}
        saveFnx={(d) => handleUpdateData(d)}
      />
      <SiteAddress
        siteaddress={siteData?.address}
        saveFnx={(d) => handleUpdateData(d)}
      />
      <div className="mt-5">
        <button
          className="cmn-btn col-12 col-lg-6 mx-auto"
          onClick={async () => await axios.get(domain + "update_news")}
        >
          Update News
        </button>
      </div>
    </div>
  );
};

export default Site;
