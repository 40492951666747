import React, { useEffect, useState } from "react";
import useGetPublicData from "../../../hooks/useGetPublicData";
import useGlobalLoading from "../../../hooks/useGlobalLoading";

const Payment = ({ name }) => {
  const { getPublicData, loading } = useGetPublicData();
  const [data, setdata] = useState();
  useEffect(() => {
    getPublicData("/payment", (data) => {
      setdata(data);
    });
  }, []);
  useGlobalLoading([loading], loading);

  return (
    data && (
      <section class="pb-120">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6 text-center">
              <div class="section-header">
                <h2 class="section-title">
                  <span class="font-weight-normal">
                    Secure Payment Options for{" "}
                  </span>{" "}
                  <b class="base--color">Seamless Transactions</b>
                </h2>
                <p>
                  At {name}, we offer a range of secure payment methods for your
                  convenience. Choose from various trusted options, ensuring
                  hassle-free and swift transactions. Our commitment to security
                  and efficiency guarantees a smooth payment experience,
                  allowing you to focus on your financial goals with confidence.
                </p>
              </div>
            </div>
          </div>
          <div
            style={{ gap: 10 }}
            className="d-flex w-100  flex-md-row flex-column align-items-center justify-content-center"
          >
            {data.map((item, index) => (
              <div key={index} class="single-slide">
                <div style={{ height: 150, width: 150 }} class="brand-item p-2">
                  <img src={`assets/images/png/${item.slug}.png`} alt="image" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    )
  );
};

export default Payment;
