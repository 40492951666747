import React from 'react';
import { Outlet } from "react-router-dom";
import usePageTitle from "./../../hooks/usePageTitle";
import Hero from "../../shared/components/hero";

const Blog = () => {
  usePageTitle("Blog: Latest Financial News");

  return (
    <>
      <Hero />
      <section class="pt-120 pb-120 border-top-1">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6 text-center">
              <div class="section-header">
                <h2 class="section-title">
                  <span class="font-weight-normal">Stay Informed With </span>{" "}
                  <b class="base--color">Our Latest Insights</b>
                </h2>
                <p>
                  Explore our comprehensive repository of the most recent news,
                  updates, and insights from the financial world. Stay ahead
                  with expert analysis, market trends, company announcements,
                  and articles covering a spectrum of topics crucial for
                  informed decision-making in the ever-evolving financial
                  landscape.
                </p>
              </div>
            </div>
          </div>
          <Outlet />
        </div>
      </section>
    </>
  );
};

export default Blog;
