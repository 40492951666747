import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { handleSetModal } from "../../util/helper_fun";
import { useAppContext } from "../../contexts/app_context";
import useDelePrivateData from "../../hooks/useDeletePrivateData";
import useGlobalLoading from "../../hooks/useGlobalLoading";
import useGlobalError from "../../hooks/useGlobalError";
import useGlobalDeleteData from "../../hooks/useGlobalDeleteData";

const EditDelete = ({
  id,
  handleEdit = () => {},
  error = () => {},
  url,
  isEdit = true,
  isDelete,
  center,
  successFn = () => {},
}) => {
  const { handleDelete } = useGlobalDeleteData(url, () => {
    successFn();
  });

  return (
    <div
      style={{ justifyContent: center ? "center" : "end" }}
      className="d-flex w-100"
    >
      {isEdit && (
        <button
          style={{ fontSize: 24 }}
          className="bg-transparent text-secondary fs-4"
          onClick={handleEdit}
        >
          <EditOutlined />
        </button>
      )}
      {isDelete && (
        <button
          style={{ fontSize: 24 }}
          className="bg-transparent text-secondary fs-4"
          onClick={() => {
            handleDelete(id, "Delete Item");
          }}
        >
          <DeleteOutlined />
        </button>
      )}
    </div>
  );
};

export default EditDelete;
