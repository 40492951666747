import React, { useEffect, useState } from "react";
import GlobalInput from "../../../../shared/components/globalInput";
import useGetPrivateData from "../../../../hooks/useGetPrivateData";
import { isEmailValid } from "../../../../util/helper_fun";
import useUpdateModal from "../../../../hooks/useUpdateModal";


const EWallet = ({ headerText }) => {
  const [error, seterror] = useState();
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const { getPrivateData } = useGetPrivateData();
  useEffect(() => {
    getPrivateData("/user?params=email", (data) => {
      setEmail(data);
    });
  }, []);
  useEffect(() => {
    let e;
    email
      ? isEmailValid(email, (err) => {
          e = err;
        })
        ? seterror()
        : seterror(e)
      : seterror();
  }, [email]);
  useUpdateModal(
    [name, email],
    headerText,
    email && name && !error ? true : false
  );
  return (
    <>
      <GlobalInput
        getValue={(v) => {
          setName(v);
        }}
        inputVal={name}
        label={"Enter name"}
        inputPlaceholder={"Alice"}
      />
      <GlobalInput
        label={"Enter email"}
        error={error}
        inputVal={email}
        getValue={(e) => {
          setEmail(e);
        }}
      />
    </>
  );
};

export default EWallet;
