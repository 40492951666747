import React, { useEffect, useState } from "react";
import { useAppContext } from "../contexts/app_context";
import { uppy } from "../util/util";

const useUploadImage = (getPhoto = () => {}) => {
  const { closeImgModal } = useAppContext();
  useEffect(() => {
    uppy.on("complete", (result) => {
      if (result.successful[0].preview) {
        getPhoto({
          data: result.successful[0],
          preview: result.successful[0].preview,
        });
        closeImgModal();
      }
    });
    return () => {
      uppy.off("complete");
    };
  }, [uppy]);
};

export default useUploadImage;
