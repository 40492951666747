import React from "react";
import { useLocation } from "react-router-dom";
import { generateBreadcrumb } from "../../util/helper_fun";
import { useAppContext } from "../../contexts/app_context";

const Hero = () => {
  const location = useLocation();
  const breadCrum = generateBreadcrumb(location.pathname);
  const { name } = useAppContext();
  return (
    <section
      class="inner-hero bg_img hero_bg"
      style={{ background: "url(/assets/images/bg/bg-1.jpg)" }}
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            {name && <h2 class="page-title">Welcome {name}</h2>}
            <ul class="page-breadcrumb">
              {breadCrum.map((item, index) =>
                index !== breadCrum.length - 1 ? (
                  <li key={index}>
                    <a href={item.path}>{item.label}</a>
                  </li>
                ) : (
                  <li key={index}>{item.label}</li>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
