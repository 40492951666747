import React from "react";

const AddNewBtn = ({ doStn = () => {} }) => {
  return (
    <div className="w-100 d-flex justify-content-end">
      <button onClick={doStn} className="cmn-btn w-auto px-3 py-2">
        Add New
      </button>
    </div>
  );
};

export default AddNewBtn;
