import React, { useState } from "react";
import GlobalInput from "./../../../shared/components/globalInput";

const SiteAddress = ({ siteaddress, saveFnx = () => {} }) => {
  const [siteAddress, setSiteAddress] = useState(siteaddress);

  return (
    <div className="mt-5">
      <GlobalInput
        isTextArea={true}
        noSanitize={true}
        inputVal={siteAddress}
        getValue={(v) => setSiteAddress(v)}
        label={"Change Site Address"}
      />
      <button
        disabled={!siteAddress || siteAddress === siteaddress}
        className="cmn-btn col-12 col-lg-6 mx-auto"
        onClick={() => saveFnx({ address: siteAddress })}
      >
        save
      </button>
    </div>
  );
};

export default SiteAddress;
