import React, { useState } from "react";
import GlobalInput from "./../../../shared/components/globalInput";

const SiteNumber = ({ sitenumber, saveFnx = () => {} }) => {
  const [siteNumber, setSiteNumber] = useState(sitenumber);

  return (
    <div className="mt-5">
      <GlobalInput
        noSanitize={true}
        inputVal={siteNumber}
        getValue={(v) => setSiteNumber(v)}
        label={"Change Site Number"}
      />
      <button
        disabled={!siteNumber || siteNumber === sitenumber}
        className="cmn-btn col-12 col-lg-6 mx-auto"
        onClick={() => saveFnx({ phone: siteNumber })}
      >
        save
      </button>
    </div>
  );
};

export default SiteNumber;
