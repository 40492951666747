import React, { useEffect, useState } from "react";
import Dropdown from "../../../../shared/components/dropdown";
import useGetPrivateData from "../../../../hooks/useGetPrivateData";
import useUpdateModal from "../../../../hooks/useUpdateModal";
import GlobalInput from "./../../../../shared/components/globalInput";
import {
  acceptPositiveIntegers,
  handleSetModal,
} from "../../../../util/helper_fun";
import { useAppContext } from "../../../../contexts/app_context";
import BinanceModal from "./binanceModal";
const PaymentMethodModal = ({
  get_s_payment,
  get_value,
  text,
  getRate,
  getData,
}) => {
  const [data, setData] = useState([]);
  const [rate, setRate] = useState();
  const [s_payment, set_s_payment] = useState();
  const [value, setValue] = useState();
  const { getPrivateData } = useGetPrivateData();
  const { getPrivateData: fetchRate } = useGetPrivateData();
  const { setShow } = useAppContext();

  useEffect(() => {
    getPrivateData("payment", (data) => {
      setData(
        data.map((item, index) => {
          return {
            ...item,
            img: `/assets/images/png/${item.slug}.png`,
          };
        })
      );
    });
    fetchRate("rate", (data) => {
      setRate(data);
      getRate(data);
    });
  }, []);
  useUpdateModal([value, s_payment], text, value && s_payment);
  useEffect(() => {
    data.length > 0 && getData(data);
  }, [data]);
  function handleBinance(params) {
    setShow((prev) => [
      ...prev,
      handleSetModal(<BinanceModal />, false, "Purchase USDT", () => {}),
    ]);
  }
  return (
    <>
      <GlobalInput
        label={"Enter amount in USD"}
        postIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            class="bi bi-currency-dollar base--color"
            viewBox="0 0 16 16"
          >
            <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
          </svg>
        }
        getValue={(v) => {
          setValue(acceptPositiveIntegers(v));
          get_value(acceptPositiveIntegers(v));
        }}
        inputVal={value}
        inputType={"text"}
      />
      <Dropdown
        label={"Choose payment method"}
        current={"Select payment method"}
        get_index={(d) => {
          set_s_payment(d);
          get_s_payment(d);
        }}
        data={data}
      />
      {s_payment && s_payment.slug === "crypto" && (
        <span>
          Don't know how to purchase USDT? Click{" "}
          <span
            role="button"
            onClick={handleBinance}
            className="styled-font base--color"
          >
            here
          </span>
        </span>
      )}
    </>
  );
};

export default PaymentMethodModal;
