import React from "react";
import { useAppContext } from "../contexts/app_context";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/esm/Button";

const ImgModal = ({ content, show }) => {
  const { closeImgModal } = useAppContext();
  return (
    <Modal show={show} onHide={closeImgModal}>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <Button onClick={closeImgModal}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImgModal;
