import React, { useEffect, useState } from "react";
import Name from "./user_components/name";
import Password from "./user_components/password";
import Email from "./user_components/email";
import usePutPrivateData from "./../../../hooks/usePutPrivateData";
import EmailOTP from "./email_otp";
import { useAppContext } from "../../../contexts/app_context";
import { handleSetModal } from "../../../util/helper_fun";
import usePutPublicData from "./../../../hooks/usePutPublicData";
import Wallet from "./user_components/wallet";

const Tab = ({ getItem, error }) => {
  const [active, setactive] = useState(0);
  const [name, setName] = useState();
  const [address, setAddress] = useState();
  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState();
  const [npassword, set_npassword] = useState();
  const [password, set_password] = useState();
  const [cpassword, set_cpassword] = useState();
  const [password_error, set_password_error] = useState();
  const { postData: updateEmail } = usePutPrivateData();
  const { postData: updatePassword } = usePutPrivateData();
  const { postData: verifyOTP } = usePutPublicData();
  const { postData: updateName } = usePutPrivateData();
  const { postData: saveEmail } = usePutPrivateData();
  const { setShow } = useAppContext();

  function handleReset(e) {
    set_password_error();
    if (npassword !== cpassword) {
      set_password_error("Password and confirm password must match.");
      return;
    }
    updatePassword(
      "user/edit_password",
      { password, new_password: npassword },
      () => {}
    );
  }
  const tab = [
    {
      disableBtn: !name,
      name: "Edit Name",
      fxn: () => {
        updateName("user", { name }, () => {});
      },
      content: (
        <Name
          getValue={(v) => {
            setName(v);
          }}
        />
      ),
    },
    {
      disableBtn: !address,
      name: "Edit Address",
      fxn: () => {
        updateName("user", { address }, () => {});
      },
      content: (
        <Wallet
          getValue={(v) => {
            setAddress(v);
          }}
        />
      ),
    },
    {
      disableBtn: !email || emailError,
      name: "Edit Email",
      fxn: () => {
        updateEmail("user/edit_email", { email }, () => {
          let code;
          setShow((prev) => [
            ...prev,
            handleSetModal(
              <EmailOTP
                getCode={(d) => {
                  code = d;
                }}
              />,
              "Confirm OTP",
              "Enter Six-Digit Code",
              () => {
                verifyOTP(`user/verify?token=${code}`, {}, () => {
                  saveEmail("user", { email }, () => {
                    setShow([]);
                  });
                });
              }
            ),
          ]);
        });
      },
      content: (
        <Email
          getEmail={(e) => {
            setEmail(e);
          }}
          getEmailError={(e) => setEmailError(e)}
        />
      ),
    },
    {
      disableBtn: !password || !npassword || !cpassword,
      name: "Reset Password",
      fxn: () => {
        handleReset();
      },
      content: (
        <Password
          oldpwd={(p) => set_password(p)}
          npwd={(p) => set_npassword(p)}
          cpwd={(p) => set_cpassword(p)}
          error={password_error}
        />
      ),
    },
  ];
  useEffect(() => {
    error();
    getItem(tab[active]);
  }, [name, email, cpassword, npassword, password, emailError, password_error]);

  return (
    <div className="w-100 mb-5">
      <div className="d-flex justify-content-center ">
        {tab.map((item, index) => (
          <span
            key={index}
            className={`${index === active && "active_bottom"} styled-font p-2`}
            onClick={() => {
              setactive(index);
              getItem(item);
            }}
            role="button"
          >
            {item.name}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Tab;
