import React, { useState } from "react";
import GlobalInput from "./../../../shared/components/globalInput";

const SiteName = ({ sitename, saveFnx = () => {} }) => {
  const [siteName, setSiteName] = useState(sitename);

  return (
    <div className="">
      <GlobalInput
        noSanitize={true}
        inputVal={siteName}
        getValue={(v) => setSiteName(v)}
        label={"Change Site Name"}
      />
      <button
        disabled={!siteName || siteName === sitename}
        className="cmn-btn col-12 col-lg-6 mx-auto"
        onClick={() => saveFnx({ app_name: siteName })}
      >
        save
      </button>
    </div>
  );
};

export default SiteName;
