import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Header from "./shared/header";
import Login from "./pages/login/login";
import "./assets/css/mystyle.css";
import { useEffect, useState } from "react";
import { getCurrentPageName } from "./util/helper_fun";
import Register from "./pages/register/register";
import ForgotPassword from "./pages/fg_password/fg_password";
import ResetPassword from "./pages/r-password/r_password";
import AuthHeader from "./shared/auth_header";
import Notification from "./shared/components/notification";
import { useAppContext } from "./contexts/app_context";
import ResendEmail from "./pages/resend_email/resend_email";
import VerifyEmail from "./pages/verify_email/verify_email";
import Dashboard from "./pages/dashboard/dashboard";
import Preloader from "./shared/components/preloader";
import Account from "./pages/dashboard/content/account";
import Deposit from "./pages/dashboard/content/deposit";
import History from "./pages/dashboard/content/history";
import Transaction from "./pages/dashboard/content/transaction";
import Withdraw from "./pages/dashboard/content/withdraw";
import Invest from "./pages/plan/content/invest";
import Purchase from "./pages/plan/content/purchase";
import Profit from "./pages/plan/content/profit";
import Plans from "./pages/plan/content/invest/plans";
import Services from "./pages/plan/sevice";
import Plan from "./pages/plan/content/invest/plan";
import MyModal from "./shared/modal";
import User from "./pages/dashboard/content/user";
import Support from "./pages/dashboard/content/support";
import "flag-icons/css/flag-icons.min.css";
import ImgModal from "./shared/imgModal";
import PurchasePlan from "./pages/plan/content/purchase/purchasePlan";
import PurchasePlans from "./pages/plan/content/purchase/purchasePlans";
import PurchaseHistory from "./pages/dashboard/content/purchase";
import About from "./pages/about/about";
import FAQ from "./pages/faq/faq";
import Footer from "./shared/footer";
import Legal from "./pages/legal/legal";
import Error500 from "./shared/components/error500";
import Privacy from "./pages/privacy/privacy";
import NotFound from "./pages/404/404";
import Home from "./pages/home/home";
import Blog from "./pages/blog/Blog";
import Posts from "./pages/blog/contents/Posts";
import PostDetails from "./pages/blog/contents/PostDetails";
import Testimony from "./pages/Testimony/Testimony";
import Overview from "./pages/Admin/contents/Overview";
import Site from "./pages/Admin/contents/Site";
import Admin from "./pages/Admin/Admin";
import AdminDeposit from "./pages/Admin/contents/AdminDeposit";
import AdminUser from "./pages/Admin/contents/AdminUser";
import useGetDataOnLoad from "./hooks/useGetLoadData";
import AdminWithdraw from "./pages/Admin/contents/AdminWithdraw";
import AdminInvestment from "./pages/Admin/contents/AdminInvestment";
import AdminPurchase from "./pages/Admin/contents/AdminPurchase";
import AdminMessage from "./pages/Admin/contents/AdminMessage";
import NotificationList from "./pages/dashboard/content/Notification";
import Cookies from "js-cookie";
import useGetPublicData from "./hooks/useGetPublicData";
import { Tawkto_ID } from "./util/util";
import Tawkto from "./shared/Tawkto";
import Signal from "./pages/plan/content/signal/signal";
import Management from "./pages/plan/content/management/management";
import SignalHistory from "./pages/dashboard/content/signal";
import AdminSignal from "./pages/Admin/contents/AdminSignal";
import AdminManagement from "./pages/Admin/contents/AdminManagement";
import ManagementHistory from "./pages/dashboard/content/management";

const pages = [
  "login",
  "register",
  "forgot-password",
  "reset-password",
  "verify-email",
  "resend-email",
];

function App() {
  const {
    notification_data,
    show,
    openModal,
    open,
    globalLoading,
    internalError,
    setName,
    siteData,
    setSiteData,
  } = useAppContext();
  const [close_navbar, set_close_navbar] = useState(false);
  const [userData, setUserData] = useState();
  const tele = window.Telegram.WebApp;
  useEffect(() => {
    tele.ready();
  }, []);

  useEffect(() => {
    const handleUrlChange = () => {
      const isPage = getCurrentPageName(pages);
      set_close_navbar(isPage);
    };

    handleUrlChange(); // To set initial state on component mount

    window.addEventListener("popstate", handleUrlChange);

    return () => {
      window.removeEventListener("popstate", handleUrlChange);
    };
  }, [pages]);
  useGetDataOnLoad(
    "user",
    (d) => {
      setUserData(d);
      setName(d.name);
    },
    [],
    false
  );
  const { getPublicData } = useGetPublicData();
  useEffect(() => {
    getPublicData("site", (data) => {
      setSiteData(data);
    });
  }, []);
  useEffect(() => {
    const script = document.createElement("script");
    script.setAttribute("src", `https://embed.tawk.to/${Tawkto_ID}`);
    script.setAttribute("crossorigin", "*");
    script.async = true;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);
  const cookies = Cookies.get("user");
  const isUser = cookies ? true : false;
  return (
    <>
      <div className="page-wrapper">
        <Notification
          show={notification_data?.show}
          msg={notification_data?.msg}
          type={notification_data?.type}
        />
        <Router>
          {!close_navbar ? (
            !internalError && <Header userData={userData} isUser={isUser} />
          ) : (
            <AuthHeader />
          )}
          {!internalError && (
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/resend-email" element={<ResendEmail />} />
              <Route path="/about" element={<About />} />
              <Route
                path="/faq"
                element={<FAQ isUser={isUser} isAdmin={userData?.isAdmin} />}
              />
              <Route path="/legal" element={<Legal />} />
              <Route path="/verify-email" element={<VerifyEmail />} />
              <Route
                path="/testimony"
                element={<Testimony isAdmin={userData?.isAdmin} />}
              />
              <Route
                path="/admin/"
                element={<Admin isAdmin={userData?.isAdmin} />}
              >
                <Route path="/admin/" element={<Overview />} />
                <Route path="/admin/deposit" element={<AdminDeposit />} />
                <Route path="/admin/withdraw" element={<AdminWithdraw />} />
                <Route path="/admin/user" element={<AdminUser />} />
                <Route path="/admin/invest" element={<AdminInvestment />} />
                <Route path="/admin/purchase" element={<AdminPurchase />} />
                <Route path="/admin/signal" element={<AdminSignal />} />
                <Route path="/admin/management" element={<AdminManagement />} />
                <Route path="/admin/message" element={<AdminMessage />} />
                <Route
                  path="/admin/site"
                  element={<Site siteData={siteData} />}
                />
              </Route>
              <Route path="/plan/" element={<Services />}>
                <Route path="/plan/" element={<Invest />}>
                  <Route
                    path="/plan/"
                    element={
                      <Plans isAdmin={userData?.isAdmin} isUser={isUser} />
                    }
                  />
                  <Route path="/plan/:name" element={<Plan />} />
                </Route>
                <Route path="/plan/purchase/" element={<Purchase />}>
                  <Route
                    path="/plan/purchase/"
                    element={
                      <PurchasePlans
                        isAdmin={userData?.isAdmin}
                        isUser={isUser}
                      />
                    }
                  />
                  <Route
                    path="/plan/purchase/:name"
                    element={<PurchasePlan />}
                  />
                </Route>
                <Route path="/plan/profit" element={<Profit />} />
                <Route
                  path="/plan/signal/"
                  element={
                    <Signal isAdmin={userData?.isAdmin} isUser={isUser} />
                  }
                />
                <Route
                  path="/plan/management/"
                  element={
                    <Management isAdmin={userData?.isAdmin} isUser={isUser} />
                  }
                />
              </Route>
              <Route
                path="/dashboard/"
                element={
                  <Dashboard isAdmin={userData?.isAdmin} isUser={isUser} />
                }
              >
                <Route path="/dashboard/" element={<Account />} />
                <Route path="/dashboard/deposit" element={<Deposit />} />
                <Route path="/dashboard/history" element={<History />} />
                <Route path="/dashboard/referral" element={<Transaction />} />
                <Route path="/dashboard/withdraw" element={<Withdraw />} />
                <Route path="/dashboard/signal" element={<SignalHistory />} />
                <Route
                  path="/dashboard/management"
                  element={<ManagementHistory />}
                />
                <Route
                  path="/dashboard/notification"
                  element={<NotificationList />}
                />
                <Route
                  path="/dashboard/purchase"
                  element={<PurchaseHistory />}
                />
                <Route
                  path="/dashboard/user"
                  element={<User userData={userData} />}
                />
              </Route>
              <Route path="/support" element={<Support />} />
              <Route path="*" element={<NotFound />} />
              <Route path="/" element={<Home />} />
              <Route path="/blog/" element={<Blog />}>
                <Route path="/blog/" element={<Posts />} />
              </Route>
              <Route path="/blog/:id" element={<PostDetails />} />
            </Routes>
          )}
          {!globalLoading && !internalError && <Footer />}
          {globalLoading && !internalError && (
            <Preloader customDisplay={globalLoading} />
          )}
          {internalError && !globalLoading && <Error500 />}
        </Router>
        {show.map((item, index) => (
          <MyModal
            headerText={item.headerText}
            content={item.content}
            show={openModal.includes(index)}
            actionFxn={() => item.actionFx()}
            actionBtnName={item.btn_name}
            id={index}
            key={index}
            condition={item.condition}
          />
        ))}

        <ImgModal content={open?.content} show={open?.open} />
        <Tawkto />
      </div>
    </>
  );
}

export default App;
