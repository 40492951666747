import React, { useEffect, useState } from "react";
import useGetPublicData from "../../../hooks/useGetPublicData";
import useGlobalLoading from "./../../../hooks/useGlobalLoading";
import AddNewBtn from "../../../shared/components/addNewBtn";
import EditDelete from "../../../shared/components/editDelete";
import usePutPrivateData from "../../../hooks/usePutPrivateData";
import usePostPrivateData from "../../../hooks/usePostPrivateData";
import useUpdateModal from "../../../hooks/useUpdateModal";
import AddFAQ from "./AddNewFaq";
import { handleSetModal } from "../../../util/helper_fun";
import { useAppContext } from "../../../contexts/app_context";

const Accordion = ({ isAdmin }) => {
  const { getPublicData } = useGetPublicData();
  const { setShow } = useAppContext();
  const [data, setdata] = useState([]);
  const [error, setError] = useState();
  useEffect(() => {
    getPublicData("/faq", (data) => {
      setdata(data);
    });
  }, []);
  function handleAddNewFAQ(params) {
    postPrivateData("/faq", params, () => {
      setShow([]);
      setdata((prev) => [...prev, { ...params, _id: data }]);
    });
  }
  function handleEditFAQ(id, params) {
    postData("faq/" + id, params, () => {
      setShow([]);
      setdata(
        data.map((item) => {
          if (item._id === id) {
            return {
              ...params,
            };
          }
          return item;
        })
      );
    });
  }
  useUpdateModal([error], "Add New FAQ" || "Edit FAQ", error);
  const { postPrivateData } = usePostPrivateData();
  const { postData } = usePutPrivateData();
  function addNewPlan(params) {
    let id;
    setShow((prev) => [
      ...prev,
      handleSetModal(
        <AddFAQ
          getData={(d) => {
            id = d;
          }}
          error={(e) => {
            setError(e);
          }}
          id={params}
        />,
        !params ? "Add" : "Edit",
        !params ? "Add New FAQ" : "Edit FAQ",
        () => {
          params ? handleEditFAQ(params, id) : handleAddNewFAQ(id);
        }
      ),
    ]);
  }
  return (
    <div class="accordion cmn-accordion" id="accordionExample">
      {isAdmin && <AddNewBtn doStn={() => addNewPlan(undefined)} />}
      {data.map((it, index) => (
        <div className="w-100 my-2">
          {isAdmin && (
            <EditDelete
              handleEdit={() => {
                addNewPlan(it._id);
              }}
              isDelete={true}
              url={"faq/"}
              id={it._id}
              successFn={() =>
                setdata(data.filter((it1) => it._id !== it1._id))
              }
            />
          )}{" "}
          <div class="card">
            <div class="card-header" id={`heading${index}`}>
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target={"#collapse" + index}
                  aria-expanded="false"
                  aria-controls={"collapse" + index}
                >
                  <i class="las la-question-circle"></i>
                  <span>{it.title}</span>
                </button>
              </h2>
            </div>

            <div
              id={"collapse" + index}
              class="collapse"
              aria-labelledby={`heading${index}`}
              data-parent="#accordionExample"
            >
              <div class="card-body">{it.text}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
