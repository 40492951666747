import React, { useState } from "react";
import GlobalInput from "./../../../shared/components/globalInput";

const Telegram = ({ telegram, saveFnx = () => {} }) => {
  const [teleGram, setTeleGram] = useState(telegram);

  return (
    <div className="mt-5">
      <GlobalInput
        noSanitize={true}
        inputVal={teleGram}
        getValue={(v) => setTeleGram(v)}
        label={"Change Telegram Username"}
      />
      <button
        disabled={!teleGram || teleGram === telegram}
        className="cmn-btn col-12 col-lg-6 mx-auto"
        onClick={() => saveFnx({ telegram: teleGram })}
      >
        save
      </button>
    </div>
  );
};

export default Telegram;
