import React, { useEffect, useState } from "react";
import GlobalInput from "../../../../shared/components/globalInput";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useGetPrivateData from "../../../../hooks/useGetPrivateData";
import {
  acceptPositiveIntegers,
  calculateValueAndPercentage,
  capitalizeFirstLetter,
  handleSetModal,
} from "../../../../util/helper_fun";
import { useAppContext } from "../../../../contexts/app_context";
import usePostPrivateData from "./../../../../hooks/usePostPrivateData";
import { useUserAuthentication } from "./../../../../hooks/useUserAuth";
import CustomError from "../../../../shared/components/customError";
const Plan = () => {
  const { getPrivateData } = useGetPrivateData();
  const { postPrivateData, message } = usePostPrivateData();
  const [value, setValue] = useState();
  const [error, setError] = useState();
  const [output, setOutput] = useState();
  const [selected_plan, setSelectedPlan] = useState();
  const { name } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { setShow, setOpenModal, setHideServiceTab } = useAppContext();
  useUserAuthentication();
  useEffect(() => {
    getPrivateData(`/plan/${capitalizeFirstLetter(name)}`, (data) => {
      setSelectedPlan(data);
    });
  }, []);
  function checkError(params) {
    setError();
    if (isNaN(Number(selected_plan.max))) {
      if (Number(selected_plan.min) > Number(value)) {
        setError(
          `Amount must be greater than or equal to ${Number(
            selected_plan.min
          ).toLocaleString()}USD`
        );
        setOutput();
        return;
      } else {
        setOutput(
          calculateValueAndPercentage(Number(value), selected_plan.profit)
        );
      }
    } else {
      if (
        Number(selected_plan.min) > Number(value) ||
        Number(selected_plan.max) < Number(value)
      ) {
        setError(
          `Amount must be between ${Number(
            selected_plan.min
          ).toLocaleString()}USD and ${Number(
            selected_plan.max
          ).toLocaleString()}USD`
        );
        setOutput();
        return;
      } else {
        setOutput(
          calculateValueAndPercentage(Number(value), selected_plan.profit)
        );
      }
    }
  }
  useEffect(() => {
    if (value) {
      checkError();
    } else {
      setOutput();
      setError();
    }
  }, [value]);
  function activatePlan(params) {
    postPrivateData(
      `invest/${selected_plan.name}`,
      { amount: value },
      (data) => {
        navigate("/dashboard/history");
      }
    );
  }
  useEffect(() => {
    if (message) {
      setShow((prev) => [
        ...prev,
        handleSetModal("", "Deposit Now", "Insufficient Balance", () => {
          navigate("/dashboard/deposit");
          setOpenModal([]);
        }),
      ]);
    }
  }, [message]);
  useEffect(() => {
    if (selected_plan && Object.keys(selected_plan).length > 0) {
      setHideServiceTab(
        location.pathname
          ?.toLowerCase()
          ?.includes(selected_plan.name?.toLowerCase())
      );
    }
  }, [selected_plan, location]);
  return selected_plan && Object.keys(selected_plan).length > 0 ? (
    <div class="col-12 col-sm-10 col-md-8 col-lg-6 mb-30">
      <div
        style={{ background: "url(/assets/images/bg/bg-4.png)" }}
        class="package-card text-center bg_img"
      >
        <h4 class="package-card__title base--color mb-2">
          {selected_plan?.name || name}
        </h4>
        <div class="package-card__range mt-5 ">
          {output && (
            <>
              <span className="base--color">${output} </span> for{" "}
              {selected_plan.duration}
            </>
          )}
        </div>
        <GlobalInput
          error={error}
          inputType={"text"}
          label={"Amount to invest"}
          getValue={(v) => {
            setValue(acceptPositiveIntegers(v));
          }}
          inputVal={value}
        />
        <button
          disabled={!value || error}
          onClick={activatePlan}
          class="cmn-btn btn-md mt-4"
        >
          Proceed
        </button>
      </div>
    </div>
  ) : (
    <CustomError
      title={"Plan"}
      description={
        "The plan you requested was not found. Choose a dfferent plan"
      }
    />
  );
};

export default Plan;
