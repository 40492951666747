import React from "react";
import { isUser } from "../../util/util";
import Plan from "./contents/plan";
import WhyUs from "./contents/whyUs";
import Calculator from "./contents/calculator";
import HowItWorks from "./contents/HowItWorks";
import FAQ from "./contents/FAQ";
import Review from "./contents/Review";
import CTA from "./contents/CTA";
import Payment from "./contents/Payment";
import Blog from "./contents/Blog";
import Newsletter from "./contents/Newsletter";
import Livechart from "./contents/Livechart";
import { useAppContext } from "../../contexts/app_context";
import usePageTitle from "../../hooks/usePageTitle";

const Home = () => {
  const { siteData } = useAppContext();
  usePageTitle(
    `Welcome ${
      siteData ? "to " + siteData.app_name : ""
    }: Your Gateway to Financial Success`
  );
  return (
    <>
      <section
        class="hero bg_img"
        style={{ background: "url(/assets/images/bg/hero1.jpg)" }}
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-5 col-lg-8">
              <div class="hero__content">
                <h2 class="hero__title">
                  <span class="text-white font-weight-normal">
                    Unlock Your Financial Potential with
                  </span>{" "}
                  <b class="base--color">Our Expert Forex Investments</b>
                </h2>
                <p class="text-white f-size-18 mt-3">
                  Discover the Unrivaled Excellence of Our Forex Investment
                  Firm: Where Trust, Professionalism, and Reliability Converge.
                  Dive into a World of Exceptional Returns, Backed by Ironclad
                  Security Measures. Our Commitment Ensures Your Investment
                  Journey Is Both Lucrative and Protected. Join Us and
                  Experience a New Standard in Financial Growth and Peace of
                  Mind
                </p>
                {!isUser && (
                  <a
                    href="#0"
                    class="cmn-btn text-uppercase w-auto px-3 font-weight-600 mt-4"
                  >
                    Sign Up
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Livechart />
      <section
        class="about-section pt-120 pb-120 bg_img"
        style={{ background: "url(/assets/images/bg/bg-2.jpg)" }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-6 offset-lg-6">
              <div class="about-content">
                <h2 class="section-title mb-3">
                  <span class="font-weight-normal">Discover Our Legacy:</span>{" "}
                  <b class="base--color">Trusted, Successful, Unmatched.</b>
                </h2>
                <p>
                  At {siteData?.app_name}, our expertise extends beyond
                  traditional investments. We specialize in providing
                  cutting-edge bot solutions designed to elevate your trading
                  strategies. With a focus on innovation and performance, our
                  bots offer a strategic advantage in today's dynamic market.
                  Join us and embrace the future of automated trading with
                  confidence.
                </p>
                <p class="mt-4">
                  Driven by a passion for empowering investors, we've cultivated
                  a culture of expertise and reliability. Our team of seasoned
                  professionals harnesses cutting-edge strategies and market
                  insights to deliver consistent, high-yield results.
                </p>
                <a href="/about" class="cmn-btn mt-4 w-auto px-3 py-2">
                  MORE INFO
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Plan />
      <Calculator />
      <Payment name={siteData?.app_name} />
      <WhyUs />
      <HowItWorks />
      <FAQ />
      {/* <Review /> */}
      <CTA />
      <Blog />
      <Newsletter />
    </>
  );
};

export default Home;
