import React, { useEffect, useState } from "react";
import Hero from "../../../shared/components/hero";
import GlobalInput from "../../../shared/components/globalInput";
import useGetPrivateData from "../../../hooks/useGetPrivateData";
import { isEmailValid } from "../../../util/helper_fun";
import usePostData from "./../../../hooks/usePostData";
import Alert from "../../../shared/components/alert";
import Preloader from "../../../shared/components/preloader";
import usePageTitle from "../../../hooks/usePageTitle";
import useGetSiteData from "./../../../hooks/useGetSiteData";

const Support = () => {
  const [email, set_email] = useState();
  const [email_error, set_email_error] = useState();
  const [name, set_name] = useState();
  const [message, set_message] = useState();
  const [address, setAddress] = useState();
  const [phone, setPhone] = useState();
  const [siteEmail, setSiteEmail] = useState();
  const { getPrivateData: getName, loading: gettingName } =
    useGetPrivateData(false);
  const { getPrivateData: getEmail, loading: gettingEmail } =
    useGetPrivateData(false);
  const { postData, loading, message: dataMessage } = usePostData();
  useGetSiteData("address", (d) => setAddress(d));
  useGetSiteData("phone", (d) => setPhone(d));
  useGetSiteData("email", (d) => setSiteEmail(d));
  useEffect(() => {
    email
      ? !isEmailValid(email, (e) => {})
        ? isEmailValid(email, (e) => {
            set_email_error(e);
          })
        : set_email_error()
      : set_email_error();
  }, [email]);
  useEffect(() => {
    getName("user?params=name", (d) => set_name(d));
    getEmail("user?params=email", (d) => set_email(d));
  }, []);
  function handlePostMessage(e) {
    e.preventDefault();
    const data = {
      email,
      message,
      name,
    };
    postData("support", data, () => {}, "alert");
  }
  usePageTitle("Support: Your 24/7 Support Center");
  return (
    <>
      <Hero />
      <section class="pt-120 pb-120">
        <div class="container">
          <div class="contact-wrapper">
            <div class="row">
              <div
                class="col-lg-6 contact-thumb bg_img"
                style={{
                  backgroundImage: `url(/assets/images/bg/bg-1.jpg)`,
                }}
              ></div>
              <div class="col-lg-6 contact-form-wrapper">
                <h2 class="font-weight-bold">Leave a message</h2>
                {dataMessage && (
                  <div className="my-2">
                    <Alert type={dataMessage?.type} msg={dataMessage?.msg} />
                  </div>
                )}

                <form onSubmit={handlePostMessage} class="contact-form mt-4">
                  <div class="form-row">
                    <GlobalInput
                      customCSS={"col-lg-6"}
                      label={"Name"}
                      getValue={(v) => {
                        set_name(v);
                      }}
                      inputVal={name}
                    />
                    <GlobalInput
                      customCSS={"col-lg-6"}
                      label={"Email"}
                      getValue={(v) => {
                        set_email(v);
                      }}
                      inputVal={email}
                      error={email_error}
                    />
                    <GlobalInput
                      customCSS={"col-lg-12"}
                      isTextArea={true}
                      label={"Message"}
                      getValue={(v) => {
                        set_message(v);
                      }}
                      noSanitize={true}
                      inputVal={message}
                    />

                    <div class="col-lg-12">
                      <button
                        type="submit"
                        disabled={!name || !email || !message}
                        class="cmn-btn"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="container pt-120">
          <div class="row justify-content-center">
            <div class="col-lg-10 mb-50">
              <h2 class="font-weight-bold">Quick</h2>
              <h2 class="font-weight-bold">Support.</h2>
              <span>You can get all information</span>
            </div>
            <div class="col-lg-10">
              <div class="row mb-none-30">
                <div class="col-md-4 col-sm-6 mb-30">
                  <div class="contact-item">
                    <i class="fas fa-phone-alt"></i>
                    <h5 class="mt-2">Call or WhatsApp Us</h5>
                    <div class="mt-4">
                      <p>
                        <a href={"https://wa.me/" + phone}>{phone}</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-30">
                  <div class="contact-item">
                    <i class="fas fa-envelope"></i>
                    <h5 class="mt-2">Mail Us</h5>
                    <div class="mt-4">
                      <p>{siteEmail}</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-30">
                  <div class="contact-item">
                    <i class="fas fa-map-marker-alt"></i>
                    <h5 class="mt-2">Visit Us</h5>
                    <div class="mt-4">
                      <p>{address}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {(loading || gettingEmail || gettingName) && <Preloader opacity={true} />}
    </>
  );
};

export default Support;
