import React, { useEffect, useState } from "react";
import useGetPrivateData from "../../../hooks/useGetPrivateData";
import HistoryData from "../../dashboard/components/history_data";
import Main from "./../../dashboard/components/main_data";
import { useAppContext } from "../../../contexts/app_context";
import { truncateEmail } from "../../../util/helper_fun";

const AdminPurchase = () => {
  const [data, set_data] = useState([]);
  const [raw_data, set_raw_data] = useState([]);
  const [dat, set_dat] = useState([]);
  const [index, set_index] = useState(0);
  const [completed, set_completed] = useState(0);
  const [pending, set_pending] = useState(0);
  const [totalData, setTotalData] = useState();
  const { getPrivateData } = useGetPrivateData();
  const { screenWidth } = useAppContext();
  useEffect(() => {
    getPrivateData("admin/purchase", (data) => {
      set_dat(data);
      setTotalData(data.length);
    });
  }, []);
  useEffect(() => {
    const d = dat.map((item) => {
      if (screenWidth <= 992) {
        return {
          Amount: Number(item.amount).toLocaleString(),
          "Purchase ID": item.id,
          Email: (
            <span
              
            >
              {truncateEmail(item.email)}
            </span>
          ),
          Status: (
            <span
              className={`${
                item.status === "active"
                  ? "bg-success"
                  : "bg-danger"
              } p-1 rounded text-white`}
            >
              {item.status}
            </span>
          ),
          Plan: item.plan,
          Date: item.date,
        };
      } else {
        // Otherwise, arrange by ID
        return {
          "Purchase ID": item.id,
          Amount: Number(item.amount).toLocaleString(),
          Email: (
            <span
            >
              {truncateEmail(item.email)}
            </span>
          ),
          Status: (
            <span
              className={`${
                item.status === "active"
                  ? "bg-success"
                  : "bg-danger "
              } p-1 rounded text-white`}
            >
              {item.status}
            </span>
          ),
          Plan: item.plan,
          Date: item.date,
        };
      }
    });
    set_raw_data(d);
  }, [screenWidth, dat]);
  const tabData = [
    { name: "All", length: raw_data.length },
    { name: "Active", length: pending },
    { name: "Expired", length: completed },
  ];
  useEffect(() => {
    const filteredDataPending = raw_data.filter(
      (item) => item.Status.props.children === "active"
    );
    const filteredDataCompleted = raw_data.filter(
      (item) => item.Status.props.children === "expired"
    );
    set_completed(filteredDataCompleted.length);
    set_pending(filteredDataPending.length);
    if (index === 0) {
      set_data(raw_data);
    }
    if (index === 1) {
      set_data(filteredDataPending);
    }
    if (index === 2) {
      set_data(filteredDataCompleted);
    }
  }, [index, raw_data]);

  return (
    <>
      <Main
        totalText={"Total Purchase"}
        totalValue={totalData}
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            class="bi bi-receipt"
            viewBox="0 0 16 16"
          >
            <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27m.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0z" />
            <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5" />
          </svg>
        }
        isDollar={false}
      />
      <HistoryData
        data={data}
        condition={"Amount"}
        tab={tabData}
        activeTabIndex={(data) => {
          set_index(data);
        }}
        data_name={"purchase"}
      />
    </>
  );
};

export default AdminPurchase;
