import React, { useEffect, useState } from "react";
import GlobalInput from "../../../../shared/components/globalInput";
import { useLocation, useNavigate } from "react-router-dom";
import {
  acceptPositiveIntegers,
  handleSetModal,
} from "../../../../util/helper_fun";
import { useAppContext } from "../../../../contexts/app_context";
import usePostPrivateData from "./../../../../hooks/usePostPrivateData";
import useIsUserError from "../../../../hooks/useIsUserError";
const Signal = ({ isUser }) => {
  const { postPrivateData, message } = usePostPrivateData();
  const [value, setValue] = useState();
  const [error, setError] = useState();
  const [output, setOutput] = useState();
  const [telegram, setTelegram] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { setShow, setOpenModal, siteData } = useAppContext();

  const minDays = 20;
  const dolPerDay = 5;
  function checkError(params) {
    setError();
    if (minDays > Number(value)) {
      setError(
        `Number of days must be greater than or equal to 20 working Days`
      );
      setOutput();
      return;
    } else {
      setOutput((dolPerDay * Number(value)).toFixed(0));
    }
  }
  useEffect(() => {
    if (value) {
      checkError();
    } else {
      setOutput();
      setError();
    }
  }, [value]);
  function activatePlan(params) {
    postPrivateData(
      `signal`,
      { duration: value, amount: output, telegram },
      (data) => {
        navigate("/dashboard/signal");
      }
    );
  }
  useEffect(() => {
    if (message) {
      setShow((prev) => [
        ...prev,
        handleSetModal("", "Deposit Now", "Insufficient Balance", () => {
          navigate("/dashboard/deposit");
          setOpenModal([]);
        }),
      ]);
    }
  }, [message]);
  const { handleError } = useIsUserError(
    <span className=" ">
      You must be a logged user to purchase signal. Go to{" "}
      <a className="base--color styled-font" href="/login">
        Log In
      </a>{" "}
      or{" "}
      <a className="base--color styled-font" href="/register">
        Register{" "}
      </a>
      .
    </span>
  );

  return (
    <div class="col-12 col-sm-10 col-md-8 col-lg-6 mb-30">
      <div
        style={{ background: "url(/assets/images/bg/bg-4.png)" }}
        class="package-card text-center bg_img"
      >
        <h4 class="package-card__title base--color mb-2">Purchase Signal</h4>
        <div class="package-card__range mt-5 ">
          {output && (
            <>
              <span className="base--color">${output} </span> for {value}{" "}
              working days and you'll be added automatically to Our Premium
              Telegram Signal Group.
            </>
          )}
        </div>
        <GlobalInput
          error={error}
          inputType={"text"}
          label={"Number of Trading Days"}
          getValue={(v) => {
            setValue(acceptPositiveIntegers(v));
          }}
          inputVal={value}
        />
        <GlobalInput
          inputType={"text"}
          label={"Enter your telegram username"}
          getValue={(v) => {
            setTelegram(v);
          }}
          inputVal={telegram}
        />
        <div className="w-100 mt-3 styled-font">
          <p style={{ fontSize: 14, color: "#ccc" }} className="">
            By continuing, you have agreed to {siteData?.app_name}'s{" "}
            <a href="/legal" className="base--color styled-font">
              terms
            </a>{" "}
            &{" "}
            <a className="base--color styled-font" href="/legal">
              conditions
            </a>
          </p>
        </div>
        <button
          disabled={!value || error || !telegram}
          onClick={() => (isUser ? activatePlan() : handleError())}
          class="cmn-btn btn-md mt-4"
        >
          Purchase Signal
        </button>
      </div>
    </div>
  );
};

export default Signal;
