import React, { useEffect, useRef, useState } from "react";
import GlobalInput from "../../../../shared/components/globalInput";
import useUpdateModal from "../../../../hooks/useUpdateModal";


const CompleteCrypto = ({ headerTxt, getId, textLabel }) => {
  const [value, setValue] = useState();
  const [error, setError] = useState();

  const pasteFromClipboard = async () => {
    setError();
    try {
      const text = await navigator.clipboard.readText();
      setValue(text);
    } catch (err) {
      setError("Failed to read clipboard. Paste manually.");
    }
  };
  useUpdateModal([value], headerTxt, value ? true : false);
  useEffect(() => {
    getId(value);
  }, [value]);

  return (
    <>
      <GlobalInput
        label={textLabel}
        postIcon={
          <span role="button" className="btn base--color">
            Paste
          </span>
        }
        getValue={(v) => {
          setValue(v);
        }}
        inputVal={value}
        inputFn={() => {
          pasteFromClipboard();
        }}
        error={error}
      />
    </>
  );
};

export default CompleteCrypto;
