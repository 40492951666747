import React, { useEffect, useRef, useState } from "react";
import GlobalInput from "../../shared/components/globalInput";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { acceptPositiveIntegers } from "../../util/helper_fun";
import { useAppContext } from "../../contexts/app_context";
import { Dashboard } from "@uppy/react";
import { uppy } from "../../util/util";
import useUploadImage from "../../hooks/useUploadImage";
import useGetPrivateData from "./../../hooks/useGetPrivateData";
import useGlobalLoading from "../../hooks/useGlobalLoading";
import useGlobalError from "../../hooks/useGlobalError";

const AddNewTest = ({ getData = () => {}, error = () => {}, id }) => {
  const [name, setname] = useState();
  const [text, setText] = useState();
  const [img, setImg] = useState(
    "https://avataaars.io/?accessoriesType=Prescription01&avatarStyle=Circle&clotheColor=Gray01&clotheType=Overall&eyeType=Hearts&eyebrowType=UpDown&facialHairColor=Auburn&facialHairType=BeardMedium&hairColor=BlondeGolden&hatColor=Gray02&mouthType=Sad&skinColor=DarkBrown&topType=ShortHairFrizzle"
  );
  const [photo, setPhoto] = useState();
  const [video, setVideo] = useState();
  const [rating, setRating] = useState(4);
  const fileInputRef = useRef(null);
  const { openImgModal } = useAppContext();

  useUploadImage((data) => {
    setImg(data.preview);
    setPhoto(data.data.data);
  });
  useEffect(() => {
    getData({
      name,
      photo: photo || img,
      text,
      rating,
      video: video || null,
      img,
    });
  }, [name, rating, photo, text, video]);
  useEffect(() => {
    !name || !rating || !img || !text ? error(false) : error(true);
  }, [name, rating, photo, text]);
  const { getPrivateData, loading } = useGetPrivateData();
  useGlobalLoading([loading], loading);
  useEffect(() => {
    id &&
      getPrivateData(`testimony/${id}`, (d) => {
        setPhoto(d.photo);
        setImg(d.photo);
        setname(d.name);
        setRating(d.rating);
        setVideo(d.video);
        setText(d.text);
      });
  }, [id]);
  return (
    <>
      <div className="d-flex flex-column align-items-center justify-content-center">
        <img
          src={img}
          height={120}
          width={120}
          className="rounded-circle"
          alt=""
        />
        <button
          style={{ fontSize: 24 }}
          className="bg-transparent text-secondary fs-4"
          onClick={() =>
            openImgModal(
              <Dashboard uppy={uppy} plugins={["Webcam", "ImageEditor"]} />
            )
          }
        >
          <EditOutlined />
        </button>
      </div>
      <GlobalInput
        noSanitize={true}
        getValue={(v) => {
          setname(v);
        }}
        label={"Name"}
        inputVal={name}
      />
      <GlobalInput
        getValue={(v) => {
          setText(v);
        }}
        label={"Enter text here"}
        inputVal={text}
        isTextArea={true}
      />
      <GlobalInput
        getValue={(v) => {
          setRating(acceptPositiveIntegers(v));
        }}
        label={"Rating(max-5,min-1)"}
        inputVal={rating}
      />
      <button
        onClick={() => fileInputRef.current.click()}
        className=" btn-unstyled text-light btn"
      >
        Upload Video
      </button>
      <input
        type="file"
        accept="video/*"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={() => setVideo(fileInputRef.current.files[0])}
      />
      {video && (
        <div className="d-flex align-items-center mt-1">
          <span style={{ fontSize: 16 }} className="text-success styled-font">
            {video.name || video}
          </span>
          <button
            style={{ fontSize: 16 }}
            className="bg-transparent text-danger"
            onClick={() => setVideo()}
          >
            <DeleteOutlined />
          </button>
        </div>
      )}
    </>
  );
};

export default AddNewTest;
