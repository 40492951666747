import React, { useEffect, useState } from "react";
import useGetPublicData from "../../../hooks/useGetPublicData";
import useGlobalLoading from "../../../hooks/useGlobalLoading";
import { formatDate } from "../../../util/helper_fun";

const Posts = () => {
  const [data, setdata] = useState();
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const { getPublicData, loading } = useGetPublicData();
  useEffect(() => {
    getPublicData("/news?page=" + page, (data) => {
      setdata(data.data);
      setTotalPage(data.metadata);
    });
  }, [page]);
  useGlobalLoading([loading], loading);

  return (
    <>
      {" "}
      <div className="row justify-content-center mb-none-30">
        {data?.map((item, index) => (
          <div key={index} class="col-lg-4 col-md-6 mb-30">
            <div class="blog-card">
              <div class="blog-card__thumb">
                <img src={item.urlToImage} alt="image" />
              </div>
              <div class="blog-card__content">
                <h4 class="blog-card__title mb-3">
                  <a href={"/blog/" + item._id}>{item.title}</a>
                </h4>
                <ul class="blog-card__meta d-flex flex-wrap mb-4">
                  <li>
                    <i class="las la-user"></i>
                    <a onClick={(e) => e.preventDefault()} href="#0">
                      {item.author || "Anonymous"}
                    </a>
                  </li>
                  <li>
                    <i class="las la-calendar"></i>
                    <a onClick={(e) => e.preventDefault()} href="/blog">
                      {formatDate(item.publishedAt)}
                    </a>
                  </li>
                </ul>
                <p>{item.description}</p>
                <a href={"/blog/" + item._id} class="cmn-btn btn-md mt-4">
                  Read More
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="w-100 d-flex justify-content-between px-3 py-5">
        <button
          className="cmn-btn w-auto px-3 py-2"
          onClick={() => setPage(page - 1)}
          disabled={page === 1}
        >
          Load Less
        </button>
        <button
          onClick={() => setPage(page + 1)}
          className="cmn-btn w-auto px-3 py-2"
          disabled={!totalPage?.hasMore}
        >
          Load More
        </button>
      </div>
    </>
  );
};

export default Posts;
