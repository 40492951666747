import React, { useEffect, useRef, useState } from "react";
import { useAppContext } from "../contexts/app_context";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Alert from "./components/alert";

const MyModal = ({
  headerText,
  content,
  actionBtnName,
  show,
  actionFxn,
  id,
  condition = true,
}) => {
  const { handleClose, globalError } = useAppContext();
  function handleAction(params) {
    actionFxn();
  }
  return (
    <>
      <Modal
        contentClassName="package-card bg_img position-relative"
        show={show}
        onHide={handleClose}
        key={id}
      >
        <Modal.Header className="my_border_bottom base--color mb-5">
          <Modal.Title className="text-center w-100">
            <div className="w-100 d-flex flex-wrap">{headerText}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {globalError && <Alert type="error" msg={globalError} />}
          <div className="my-4"></div>
          <div style={{ fontSize: 16 }} class="package-card__range">
            {content}
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex my_border_top align-items-center mt-5">
          {actionBtnName && (
            <button
              disabled={!condition}
              className="cmn-btn"
              onClick={handleAction}
            >
              {actionBtnName}
            </button>
          )}
          <button
            style={{
              background: "transparent",
              justifyContent: actionBtnName ? "space-between" : "flex-end",
            }}
            className="cmn-btn base--color my_border"
            onClick={() => {
              handleClose(id);
            }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MyModal;
