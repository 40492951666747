import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfigProvider, Menu } from "antd";
import Cookies from "js-cookie";

const Sidebar = ({ show, toggle_show, data }) => {
  let active;

  const navigate = useNavigate();
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  return (
    <>
      <button
        style={{
          backgroundColor: "transparent",
          marginLeft: !show ? 35 : 20,
        }}
        onClick={() => toggle_show(!show)}
      >
        {!show ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            class="bi bi-arrow-left-square base--color"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm11.5 5.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            class="bi bi-arrow-right-square base--color"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm4.5 5.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"
            />
          </svg>
        )}
      </button>
      <div
        style={{
          marginTop: 10,
          paddingLeft: 10,
        }}
      >
        <ConfigProvider
          theme={{
            components: {
              Menu: {
                darkItemBg: "transparent",
                darkItemSelectedBg: "#b78b36",
                darkItemHoverBg: "#b78b3680",
                collapsedWidth: 50,
              },
            },
          }}
        >
          {" "}
          <Menu
            defaultSelectedKeys={["0"]}
            onClick={({ key }) => (active = key)}
            onSelect={() => {
              if (data[active].link) {
                navigate(data[active].link);
                toggle_show(true);
              } else {
                Cookies.remove("user");
                window.location.href = "/register";
              }
            }}
            mode="inline"
            theme="dark"
            inlineCollapsed={show}
            items={data.map((item, index) =>
              getItem(item.name, index, item.icon)
            )}
          />
        </ConfigProvider>
      </div>
    </>
  );
};

export default Sidebar;
