import React, { useEffect, useState } from "react";
import useGetPublicData from "../../../hooks/useGetPublicData";
import { gotoLocation, isUser } from "../../../util/util";
import useIsUserError from "../../../hooks/useIsUserError";

const Plan = () => {
  const { getPublicData: getInvest } = useGetPublicData();
  const { getPublicData: getPurchase } = useGetPublicData();
  const [invest, setInvest] = useState();
  const [purchase, setPurchase] = useState();
  useEffect(() => {
    getInvest("/plan", (data) => {
      setInvest(data[0]);
    });
    getPurchase("/purchase", (data) => {
      setPurchase(data[0]);
    });
  }, []);
  const { handleError } = useIsUserError(
    <span className=" ">
      You must be a logged user to choose a plan. Go to{" "}
      <a className="base--color styled-font" href="/login">
        Log In
      </a>{" "}
      or{" "}
      <a className="base--color styled-font" href="/register">
        Register{" "}
      </a>
      .
    </span>
  );
  function handleInvestNow(name) {
    isUser ? gotoLocation(`/plan/${name}`) : handleError();
  }
  function handlePurchaseNow(name) {
    isUser ? gotoLocation(`/plan/purchase/${name}`) : handleError();
  }
  return (
    <section class="pt-120 pb-120">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 text-center">
            <div class="section-header">
              <h2 class="section-title">
                <span class="font-weight-normal">Unlocking Potential:</span>{" "}
                <b class="base--color">Our Diverse Services</b>
              </h2>
              <p>
                From Expert Fund Management to Advanced Bot Sales, We've Got You
                Covered.
              </p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center align-items-center mb-none-30">
          {invest && (
            <div style={{ height: "75vh" }} class="col-12 col-md-6 mb-30">
              <div
                style={{ background: "url(/assets/images/bg/bg-4.png)" }}
                class="package-card text-center bg_img"
              >
                <h4 class="package-card__title base--color mb-2">
                  {invest?.name} Plan
                </h4>
                <ul class="package-card__features mt-4">
                  <li>Profit of {invest?.profit}</li>
                  <li>After {invest?.duration}</li>
                  <li>Referral Percentage of {invest?.commission}</li>
                </ul>
                <div class="package-card__range mt-5 base--color">
                  ${Number(invest?.min).toLocaleString()} -{" "}
                  {!isNaN(Number(invest?.max)) ? "$" : ""}
                  {isNaN(Number(invest?.max))
                    ? invest?.max
                    : Number(invest?.max).toLocaleString()}{" "}
                </div>
                <button
                  onClick={() => handleInvestNow(invest?.name.toLowerCase())}
                  class="cmn-btn btn-md mt-4"
                >
                  Invest Now
                </button>
              </div>
            </div>
          )}
          {purchase && (
            <div style={{ height: "75vh" }} class="col-12 col-md-6 mb-30">
              <div
                class="package-card text-center bg_img"
                style={{ background: "url(/assets/images/bg/bg-4.png)" }}
              >
                <h4 class="package-card__title base--color mb-2">
                  {purchase?.name} Plan
                </h4>
                <div class="package-card__range mt-5 ">
                  <span className="base--color">
                    ${Number(purchase?.price).toLocaleString()}
                  </span>{" "}
                  for {purchase?.duration}
                </div>
                <ul class="package-card__features mt-4">
                  <li>Referral Percentage of {purchase?.commission}</li>
                </ul>

                <button
                  onClick={() =>
                    handlePurchaseNow(purchase?.name.toLowerCase())
                  }
                  style={{ marginTop: 120 }}
                  class="cmn-btn btn-md"
                >
                  Purchase Now
                </button>
              </div>
            </div>
          )}
        </div>

        <div class="row mt-50">
          <div class="col-lg-12 text-center">
            <a href="/plan" class="cmn-btn w-auto px-3 py-2">
              View All Services
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Plan;
