import React from "react";
import Hero from "../../shared/components/hero";
import usePageTitle from "../../hooks/usePageTitle";
import { useAppContext } from "../../contexts/app_context";

const About = () => {
  const { siteData } = useAppContext;
  usePageTitle(
    `Discover Our Story: ${siteData?.app_name} and Our Commitment to Excellence`
  );
  return (
    <>
      <Hero />
      <section
        class="pt-120 pb-120 bg_img"
        style={{background:"url(/assets/images/bg/bg-5.jpg)"}}
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6 text-center">
              <div class="section-header">
                <h2 class="section-title">
                  <span class="font-weight-normal">How</span>{" "}
                  <b class="base--color">{siteData?.app_name}</b>{" "}
                  <span class="font-weight-normal">Works</span>
                </h2>
                <p>
                  Invest your funds to yield automatic returns, or opt for the
                  acquisition of advanced trading bots.
                </p>
              </div>
            </div>
          </div>
          <div class="row justify-content-center mb-none-30">
            <div class="col-lg-4 col-md-6 work-item mb-30">
              <div class="work-card text-center">
                <div class="work-card__icon">
                  <i class="las la-user base--color"></i>
                  <span class="step-number">01</span>
                </div>
                <div class="work-card__content">
                  <h4 class="base--color mb-3">Create Account</h4>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 work-item mb-30">
              <div class="work-card text-center">
                <div class="work-card__icon">
                  <i class="las la-hand-holding-usd base--color"></i>
                  <span class="step-number">02</span>
                </div>
                <div class="work-card__content">
                  <h4 class="base--color mb-3">Invest</h4>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 work-item mb-30">
              <div class="work-card text-center">
                <div class="work-card__icon">
                  <i class="las la-wallet base--color"></i>
                  <span class="step-number">03</span>
                </div>
                <div class="work-card__content">
                  <h4 class="base--color mb-3">Earn</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="about-section pt-120 pb-120 bg_img"
        style={{
          background: "url(/assets/images/bg/bg-2.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-6 offset-lg-6">
              <div class="about-content">
                <h2 class="section-title mb-3">
                  <span class="font-weight-normal">About</span>{" "}
                  <b class="base--color">Us</b>
                </h2>
                {/* <p>
                  Welcome to our global financial firm, specializing in Forex
                  trading excellence. Our seasoned traders are dedicated to
                  multiplying your funds through skillful Forex trades. In
                  addition to our Forex expertise, we offer a proven, guaranteed
                  trading bot. Experience consistent results and secure your
                  financial future with us. Explore our expertise in Forex
                  trading, led by seasoned traders and powered by robust
                  automation.
                </p>
                <p class="mt-4">
                  Our goal is to provide our investors with a reliable source of
                  high income, while minimizing any possible risks and offering
                  a high-quality service, allowing us to automate and simplify
                  the relations between the investors and the trustees. We work
                  towards increasing your profit margin by profitable
                  investment. We look forward to you being part of our
                  community.
                </p> */}
                <a href="/support" class="cmn-btn mt-4">
                  MORE INFO
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
