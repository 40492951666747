import React from "react";
import Hero from "../../shared/components/hero";
import { Outlet } from "react-router-dom";
import Tab from "./components/tab";
import { useAppContext } from "./../../contexts/app_context";
import usePageTitle from "../../hooks/usePageTitle";

const Services = () => {
  const { hideServiceTab, siteData } = useAppContext();
  usePageTitle(
    `Elevating Your Financial Future: Our Tailored Services at ${siteData?.app_name}`
  );
  return (
    <>
      <Hero />
      <section class="pt-120 pb-120">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 text-center">
              <div class="section-header">
                <h2 class="section-title">
                  <span class="font-weight-normal">
                    We can trade for you or empower your trades with our bots.{" "}
                  </span>{" "}
                  <b class="base--color">Choose your path to success</b>
                </h2>
              </div>
              {!hideServiceTab && <Tab />}
            </div>
          </div>
          <div class="row justify-content-center mb-none-30">
            <Outlet />
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
