import React, { useEffect, useState } from "react";
import GlobalInput from "../../../shared/components/globalInput";
import {
  acceptPositiveIntegers,
  capitalizeFirstLetter,
} from "../../../util/helper_fun";
import useGetPrivateData from "../../../hooks/useGetPrivateData";

const AddNewPurchase = ({ getData = () => {}, error = () => {}, isEdit }) => {
  const [name, setname] = useState();
  const [price, setprice] = useState();
  const [duration, setduration] = useState();
  const [referralPer, setreferralPer] = useState();

  const { getPrivateData } = useGetPrivateData();
  useEffect(() => {
    !name || !price || !duration || !referralPer ? error(false) : error(true);
    getData({
      name,
      price,
      duration,
      commission: referralPer + (referralPer?.includes("%") ? "" : "%"),
    });
  }, [name, price, duration, referralPer]);
  useEffect(() => {
    isEdit &&
      getPrivateData(`/purchase/${capitalizeFirstLetter(isEdit)}`, (data) => {
        setname(data.name);
        setduration(data.duration);
        setreferralPer(data.commission);
        setprice(data.price);
      });
  }, [isEdit]);
  
  return (
    <>
      <GlobalInput
        getValue={(v) => {
          setname(v ? capitalizeFirstLetter(v) : "");
        }}
        label={"Purchase"}
        inputVal={name}
      />
      <GlobalInput
        getValue={(v) => {
          setprice(acceptPositiveIntegers(v));
        }}
        inputPlaceholder={"E.g 9000"}
        label={"Price"}
        inputVal={price}
      />
      <GlobalInput
        noSanitize={true}
        getValue={(v) => {
          setduration(v);
        }}
        label={"Duration"}
        inputVal={duration}
        inputPlaceholder={"E.g 3 Months"}
      />

      <GlobalInput
        getValue={(v) => {
          setreferralPer(acceptPositiveIntegers(v));
        }}
        label={"Referral Percentage"}
        inputVal={referralPer}
        inputPlaceholder={"E.g 5"}
      />
    </>
  );
};

export default AddNewPurchase;
