import React, { useEffect, useState } from "react";
import { handleSetModal } from "../../../../util/helper_fun";

import CardModal from "./cardModal";
import EWallet from "./e_walletModal";
import CryptoModal from "./cryptoModal";
import { useAppContext } from "../../../../contexts/app_context";
import CompleteCrypto from "./completeCrypto";
import usePostPrivateData from "./../../../../hooks/usePostPrivateData";
import PaymentMethodModal from "./paymentMethodModal";
import useGetPublicData from "./../../../../hooks/useGetPublicData";

const DepositModal = ({ getData, text }) => {
  const [value, setValue] = useState();
  const [data, setdata] = useState([]);
  const [rate, setrate] = useState();
  const [wallet, setwallet] = useState();
  const [s_payment, set_s_payment] = useState();
  const { postPrivateData, loading, message } = usePostPrivateData();
  const { setShow, setGlobalError, setGlobalLoading } = useAppContext();
  const { getPublicData } = useGetPublicData();
  useEffect(() => {
    getPublicData("site?params=wallet", (data) => {
      setwallet(data);
    });
  }, []);

  function handleCreateDeposit(params) {
    const data = {
      name: s_payment.name,
      amount: value,
      isRecommended: s_payment.recommended,
      transactionID: params,
    };
    setGlobalError();
    postPrivateData("/deposit", data, () => {
      setShow([]);
    });
  }
  function completeDeposit(params) {
    let id;
    setShow((prev) => [
      ...prev,
      handleSetModal(
        <CompleteCrypto
          getId={(i) => {
            id = i;
          }}
          headerTxt={"Complete Deposit"}
          textLabel={"Enter or Paste Transaction ID "}
        />,
        "Save",
        "Complete Deposit",
        () => {
          handleCreateDeposit(id);
        }
      ),
    ]);
  }
  useEffect(() => {
    if (value && s_payment && wallet) {
      const contents = [
        {
          content: <CardModal headerTxt={data[0].name} />,
          btn_text: `Deposit`,
          fxn: () => {
            handleCreateDeposit();
          },
        },
        {
          content: <EWallet headerText={data[1].name} />,
          btn_text: `Deposit`,
          fxn: () => {
            handleCreateDeposit();
          },
        },
        {
          content: (
            <CryptoModal
              text={wallet}
              additionText={
                " Use the QR code or copy the ERC-20 wallet address to deposit."
              }
            />
          ),
          btn_text: `Yes! I have deposited`,
          fxn: () => {
            completeDeposit();
          },
        },
      ];
      const getSelectedItemIndex = data.findIndex(
        (it) => it._id === s_payment._id
      );
      getData({
        ...s_payment,
        value,
        content: contents[getSelectedItemIndex].content,
        btn_text: contents[getSelectedItemIndex].btn_text,
        fxn: contents[getSelectedItemIndex].fxn,
      });
    }
  }, [value, s_payment, data, wallet]);
  useEffect(() => {
    if (message) {
      setGlobalLoading(true);
      setTimeout(() => {
        setGlobalError(message.msg);
        setGlobalLoading(false);
      }, 1000 * 3);
    }
  }, [message]);
  return (
    <PaymentMethodModal
      get_s_payment={(s) => {
        set_s_payment(s);
      }}
      get_value={(v) => setValue(v)}
      text={text}
      getData={(d) => {
        setdata(d);
      }}
      getRate={(r) => setrate(r)}
    />
  );
};

export default DepositModal;
