import { useEffect } from "react";
import { useAppContext } from "../contexts/app_context";

const usePageTitle = (title) => {
  const { siteData } = useAppContext();
  useEffect(() => {
    document.title = `${siteData?.app_name || ""} | ${title}`;
  }, [title, siteData]);
};

export default usePageTitle;
