import React, { useEffect, useState } from "react";
import GlobalInput from "../../shared/components/globalInput";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LeftOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { selectMultiple } from "../../util/helper_fun";
import usePageTitle from "../../hooks/usePageTitle";
import Spinner from "../../shared/components/spinner";
import Alert from "./../../shared/components/alert";
import usePutPublicData from "../../hooks/usePutPublicData";

const ResetPassword = () => {
  usePageTitle("Reset Your Password: Regain Access to Your Account");
  const [pIndex, setPIndex] = useState([]);
  const [password, set_password] = useState();
  const [confirm_password, set_confirm_password] = useState();
  const [error, set_error] = useState();
  const [searchParam, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { postData, loading, message } = usePutPublicData();
  const tokenParams = searchParam.get("token");
  useEffect(() => {
    !tokenParams && navigate("/login");
  }, []);

  function handleReset(e) {
    set_error(
      password !== confirm_password &&
        "Password and confirm password must match."
    );
    const data = { password };
    e.preventDefault();
    if (password !== confirm_password) {
      return;
    }
    postData(`user/reset-password?token=${tokenParams}`, data, (data) => {
      navigate("/login");
    });
  }
  return (
    <div class="account-section bg_img">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-5 col-lg-7">
            <div class="account-card">
              <div class="account-card__header login_bg bg_img overlay--one">
                <h2 class="section-title">Reset Password</h2>
              </div>
              <div class="account-card__body">
                {message && <Alert type={message.type} msg={message.msg} />}
                <form onSubmit={handleReset} class="mt-4">
                  <GlobalInput
                    label="Password"
                    preIcon={
                      <LockOutlined style={{ color: "#ccc", fontSize: 20 }} />
                    }
                    getValue={(val) => {
                      set_password(val);
                    }}
                    inputPlaceholder="enter password"
                    inputType={pIndex.includes(1) ? "text" : "password"}
                    postIcon={
                      !pIndex.includes(1) ? (
                        <EyeOutlined style={{ color: "#ccc", fontSize: 20 }} />
                      ) : (
                        <EyeInvisibleOutlined
                          style={{ color: "#ccc", fontSize: 20 }}
                        />
                      )
                    }
                    inputFn={() => {
                      setPIndex(selectMultiple(pIndex, 1));
                    }}
                    inputVal={password}
                  />
                  <GlobalInput
                    label="Confirm Password"
                    preIcon={
                      <LockOutlined style={{ color: "#ccc", fontSize: 20 }} />
                    }
                    getValue={(val) => {
                      set_confirm_password(val);
                    }}
                    inputPlaceholder="re-enter password"
                    inputType={pIndex.includes(2) ? "text" : "password"}
                    postIcon={
                      !pIndex.includes(2) ? (
                        <EyeOutlined style={{ color: "#ccc", fontSize: 20 }} />
                      ) : (
                        <EyeInvisibleOutlined
                          style={{ color: "#ccc", fontSize: 20 }}
                        />
                      )
                    }
                    inputFn={() => {
                      setPIndex(selectMultiple(pIndex, 2));
                    }}
                    inputVal={confirm_password}
                    error={error}
                  />
                  <button
                    style={{ fontSize: 14 }}
                    className="btn-unstyled base--color btn d-flex align-items-center"
                    onClick={() => navigate("/login")}
                  >
                    <LeftOutlined className="mr-2 " />
                    Back to Login
                  </button>
                  <div class="mt-3">
                    <button
                      type="submit"
                      disabled={loading || !password}
                      class="cmn-btn "
                    >
                      {loading ? (
                        <>
                          <Spinner />
                          Please wait...
                        </>
                      ) : (
                        "Reset Password"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
