import React, { useEffect, useRef, useState } from "react";
import Main from "../../dashboard/components/main_data";
import useGetDataOnLoad from "../../../hooks/useGetLoadData";
import HistoryData from "../../dashboard/components/history_data";
import GlobalInput from "./../../../shared/components/globalInput";
import { SearchOutlined } from "@ant-design/icons";
import PageNavigation from "../../../shared/components/PageNavigation";
import useGlobalDeleteData from "./../../../hooks/useGlobalDeleteData";
import usePutPrivateData from "../../../hooks/usePutPrivateData";
import {
  acceptPositiveIntegers,
  capitalizeFirstLetter,
  handleSetModal,
  truncateEmail,
} from "../../../util/helper_fun";
import { useAppContext } from "../../../contexts/app_context";
import EmailModal from "../../../shared/components/EMailModal";
import usePostPrivateData from "../../../hooks/usePostPrivateData";

const AdminUser = () => {
  const [data, set_data] = useState([]);
  const [data_raw, set_data_raw] = useState([]);
  const [value, setValue] = useState();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [da, set_d] = useState([]);
  const { setShow } = useAppContext();
  useGetDataOnLoad(
    "admin/user?page=" + page,
    (d) => {
      set_data_raw(
        d.data.map((item, index) => {
          return {
            Email: <span>{truncateEmail(item.email)}</span>,
            Name: item.name,
            Balance: (
              <span
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  width: 100,
                }}
              >
                {Number(item.balance).toLocaleString()} USD
              </span>
            ),
            Status: item.status,
            Country: item.country,
            Date: (
              <span
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  width: 100,
                }}
              >
                {item.date}
              </span>
            ),
            id: item._id,
          };
        })
      );
      setTotalPage(d.metadata.totalPages);
      set_d(d.data);
    },
    [page]
  );
  useEffect(() => {
    if (value) {
      const searchRaw = da
        .filter((i) => {
          if (i.email.toLowerCase().includes(value.toLowerCase())) {
            return i;
          }
        })
        .map((i) => i._id);
      const search = data.filter((item) => searchRaw.includes(item.id));
      set_data(search);
    } else {
      set_data(data_raw);
    }
  }, [value, data_raw]);
  const { handleDelete } = useGlobalDeleteData("admin/user/");
  const { postData } = usePutPrivateData();
  const { postPrivateData } = usePostPrivateData();
  function handleEditUser(username, email) {
    const Status = "inactive";
    setShow((prev) => [
      ...prev,
      handleSetModal(
        <p className="styled-font">
          Are you sure you want to Deactivate this user?
        </p>,
        "Deactivate",
        "Deactivate" + " " + username,
        () => {
          postData("admin/user/" + email, { status: Status }, () => {
            setShow([]);
            set_data(
              data.map((item) => {
                if (item.Email.props.children === email) {
                  return {
                    ...item,
                    Status,
                  };
                }
                return item;
              })
            );
          });
        }
      ),
    ]);
  }
  function handleSendMessage(email) {
    let data;
    setShow((prev) => [
      ...prev,
      handleSetModal(
        <EmailModal
          getValue={(e) => (data = e)}
          xEmail={email}
          headerTxt={"Send Message" + (email ? " to " + email : "")}
        />,
        "Send",
        "Send Message" + (email ? " to " + email : ""),
        () => {
          postPrivateData("admin/email", data, () => {
            setShow([]);
          });
        }
      ),
    ]);
  }
  function handleEditUserBalance(bal, user, email) {
    let b;
    setShow((prev) => [
      ...prev,
      handleSetModal(
        <>
          <span className="mb-2 base--color styled-font">
            Prev Bal: {bal} USD
          </span>
          <GlobalInput
            label={"Enter amount here"}
            getValue={(v) => {
              b = acceptPositiveIntegers(v);
            }}
          />
        </>,
        "Edit Balance",
        "Edit " + user + "'s Balance",
        () => {
          postData("admin/user/" + email, { balance: b || 0 }, () => {
            setShow([]);
            set_data(
              data.map((item) => {
                if (item.Email.props.children === email) {
                  return {
                    ...item,
                    Balance: (
                      <span
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          width: 100,
                        }}
                      >
                        {Number(b).toLocaleString() || 0} USD
                      </span>
                    ),
                  };
                }
                return item;
              })
            );
          });
        }
      ),
    ]);
  }
  function findEmail(params) {
    let foundEmail = null;
  
    da.forEach(item => {
      const itemKeys = Object.keys(item);
      if (itemKeys.includes('_id') && item['_id'] === params) {
        foundEmail = item['email'];
      }
    });
  
    return foundEmail;
  }
  return (
    <>
      <Main
        totalText={"Total Users"}
        totalValue={data.length}
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            class="bi bi-person"
            viewBox="0 0 16 16"
          >
            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664z" />
          </svg>
        }
        isDollar={false}
      />
      {da.length > 0 && (
        <div className="col-10 col-md-6 col-lg-4">
          <GlobalInput
            postIcon={<SearchOutlined className="base--color" />}
            inputCustomSize="-sm"
            inputPlaceholder={"search user by email"}
            getValue={(v) => setValue(v)}
            inputVal={value}
          />
        </div>
      )}
      <HistoryData
        data={data}
        condition={"Email"}
        tab={[]}
        activeTabIndex={(data) => {}}
        data_name={"user"}
        isUSD={false}
        dropdownItem={[
          {
            name: "Edit Balance",
            fnx: (d) => {
              const user = d.Name;
              const userBal = d.Balance.props.children[0];
              console.log(findEmail(d.id))
              handleEditUserBalance(userBal, user, findEmail(d.id));
            },
          },
          {
            name: "Deactivate",
            fnx: (d) => {
              const user = d.Name;
              handleEditUser(user, findEmail(d.id));
            },
          },
          {
            name: "Delete",
            fnx: (d) => {
              const user = d.Name;
              handleDelete(
                findEmail(d.id),
                "Delete " + user,
                set_data(data.filter((item) => item.Name !== user))
              );
            },
          },
          {
            name: "Send Message",
            fnx: (d) => {
              handleSendMessage(findEmail(d.id));
            },
          },
        ]}
        dropdownBtnName="Action"
        hideColumn={["id"]}
      />
      {totalPage > 10 && (
        <PageNavigation totalPage={totalPage} getPage={(d) => setPage(d)} />
      )}
    </>
  );
};

export default AdminUser;
