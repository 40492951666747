import React, { useEffect, useState } from "react";
import GlobalInput from "../../../../shared/components/globalInput";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { selectMultiple } from "../../../../util/helper_fun";
import Alert from "../../../../shared/components/alert";
import { useAppContext } from "../../../../contexts/app_context";

const Password = ({ oldpwd, npwd, cpwd, error }) => {
  const [pIndex, setPIndex] = useState([]);
  const [password, set_password] = useState();
  const [npassword, set_npassword] = useState();
  const [confirm_password, set_confirm_password] = useState();
  useEffect(() => {
    oldpwd(password);
    npwd(npassword);
    cpwd(confirm_password);
  }, [password, npassword, confirm_password]);
  const { globalError } = useAppContext();

  return (
    <>
      {globalError && (
        <div className="mb-2">
          <Alert type="error" msg={globalError.msg} />
        </div>
      )}
      <GlobalInput
        label="Password"
        preIcon={<LockOutlined style={{ color: "#ccc", fontSize: 20 }} />}
        getValue={(val) => {
          set_password(val);
        }}
        inputPlaceholder="enter password"
        inputType={pIndex.includes(0) ? "text" : "password"}
        postIcon={
          !pIndex.includes(0) ? (
            <EyeOutlined style={{ color: "#ccc", fontSize: 20 }} />
          ) : (
            <EyeInvisibleOutlined style={{ color: "#ccc", fontSize: 20 }} />
          )
        }
        inputFn={() => {
          setPIndex(selectMultiple(pIndex, 0));
        }}
        inputVal={password}
      />
      <GlobalInput
        label="New Password"
        preIcon={<LockOutlined style={{ color: "#ccc", fontSize: 20 }} />}
        getValue={(val) => {
          set_npassword(val);
        }}
        inputPlaceholder="enter password"
        inputType={pIndex.includes(1) ? "text" : "password"}
        postIcon={
          !pIndex.includes(1) ? (
            <EyeOutlined style={{ color: "#ccc", fontSize: 20 }} />
          ) : (
            <EyeInvisibleOutlined style={{ color: "#ccc", fontSize: 20 }} />
          )
        }
        inputFn={() => {
          setPIndex(selectMultiple(pIndex, 1));
        }}
        inputVal={npassword}
      />
      <GlobalInput
        label="Confirm Password"
        preIcon={<LockOutlined style={{ color: "#ccc", fontSize: 20 }} />}
        getValue={(val) => {
          set_confirm_password(val);
        }}
        inputPlaceholder="re-enter password"
        inputType={pIndex.includes(2) ? "text" : "password"}
        postIcon={
          !pIndex.includes(2) ? (
            <EyeOutlined style={{ color: "#ccc", fontSize: 20 }} />
          ) : (
            <EyeInvisibleOutlined style={{ color: "#ccc", fontSize: 20 }} />
          )
        }
        inputFn={() => {
          setPIndex(selectMultiple(pIndex, 2));
        }}
        inputVal={confirm_password}
        error={error}
      />
    </>
  );
};

export default Password;
