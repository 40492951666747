import React, { useEffect, useState } from "react";
import Dropdown from "../../../shared/components/dropdown";
import useGetPublicData from "../../../hooks/useGetPublicData";
import GlobalInput from "../../../shared/components/globalInput";
import {
  acceptPositiveIntegers,
  calculateValueAndPercentage,
} from "../../../util/helper_fun";

const Profit = () => {
  const { getPublicData} = useGetPublicData();
  const [data, setdata] = useState([]);
  const [value, setValue] = useState();
  const [error, setError] = useState();
  const [output, setOutput] = useState();
  const [selected_plan, setSelectedPlan] = useState();
  useEffect(() => {
    getPublicData("/plan", (data) => {
      setdata(data);
    });
  }, []);
  useEffect(() => {
    setError();
    setOutput();
  }, [value]);

  function calculateProfit(params) {
    setError();
    if (isNaN(Number(selected_plan.max))) {
      if (Number(selected_plan.min) > Number(value)) {
        setError(
          `Amount must be greater than or equal to ${Number(
            selected_plan.min
          ).toLocaleString()}USD`
        );
        return;
      } else {
        setOutput(
          calculateValueAndPercentage(Number(value), selected_plan.profit)
        );
      }
    } else {
      if (
        Number(selected_plan.min) > Number(value) ||
        Number(selected_plan.max) < Number(value)
      ) {
        setError(
          `Amount must be between ${Number(
            selected_plan.min
          ).toLocaleString()}USD and ${Number(
            selected_plan.max
          ).toLocaleString()}USD`
        );
        return;
      } else {
        setOutput(
          calculateValueAndPercentage(Number(value), selected_plan.profit)
        );
      }
    }
  }
  return (
    <div class="col-12 col-sm-10 col-md-8 col-lg-6 mb-30">
      <div
        style={{ background: "url(/assets/images/bg/bg-4.png)" }}
        class="package-card text-center bg_img"
      >
        <h4 class="package-card__title base--color mb-2">Calculate</h4>
        <div class="package-card__range mt-5 ">
          {output && (
            <>
              <span className="base--color">${Number(output).toLocaleString()} </span>{" "}
              after {selected_plan.duration}
            </>
          )}
        </div>
        <Dropdown
          data={data}
          label={"Calculate profit"}
          current={"Select plan"}
          get_index={(i) => {
            setSelectedPlan(i);
            setOutput();
            setError();
          }}
        />
        <GlobalInput
          error={error}
          inputType={"text"}
          label={"Amount to invest"}
          getValue={(v) => {
            setValue(acceptPositiveIntegers(v));
          }}
          inputVal={value}
        />
        <button
          disabled={!selected_plan || !value}
          onClick={calculateProfit}
          class="cmn-btn btn-md mt-4"
        >
          Calculate Now
        </button>
      </div>
    </div>
  );
};

export default Profit;
