import React from "react";
import TradingViewWidget, { Themes } from "react-tradingview-widget";

const Livechart = () => {
  return (
    <section class="pt-120 pb-120">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 text-center">
            <div class="section-header">
              <h2 class="section-title">
                <span class="font-weight-normal">Live</span>{" "}
                <b class="base--color">Chart</b>
              </h2>
            </div>
          </div>
        </div>

        <div
          class=" bg_img"
          style={{height:"100vh", background: "url(/assets/images/bg/bg-4.png)" }}
        >
          <TradingViewWidget
            theme={Themes.DARK}
            locale="en"
            autosize
            symbol="EURUSD"
          />
        </div>
      </div>
    </section>
  );
};

export default Livechart;
