import React from "react";

const PopTooltip = ({ active }) => {
  return (
    <div
      className={`${
        active ? "d-block" : "d-none"
      } myTooltip rounded base--color glow p-1`}
    >
      Copied
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="goldenrod"
        class="bi bi-check bg--color"
        viewBox="0 0 16 16"
      >
        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
      </svg>
    </div>
  );
};

export default PopTooltip;
