import Hero from "../../shared/components/hero";
import usePageTitle from "../../hooks/usePageTitle";
import React, { useState } from "react";
import EditDelete from "../../shared/components/editDelete";
import { useAppContext } from "../../contexts/app_context";
import {
  generateJSXMultipleTimes,
  handleSetModal,
  sanitizeUndefinedValues,
} from "../../util/helper_fun";
import AddNewTest from "./AddNewTest";
import useUpdateModal from "../../hooks/useUpdateModal";
import usePostPrivateFormData from "../../hooks/usePostPrivateFormData";
import usePutPrivateFormData from "./../../hooks/usePutPrivateFormData";
import EmptyArray from "../../shared/components/EmptyArray";
import AddNewBtn from "../../shared/components/addNewBtn";
import useGetDataOnLoad from "../../hooks/useGetLoadData";
const Testimony = ({ isAdmin }) => {
  const [testimony, setTestimony] = useState([]);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const { setShow, siteData } = useAppContext();
  usePageTitle(`Client Stories: Real Experiences with ${siteData?.app_name}`);

  useGetDataOnLoad(
    "testimony?page=" + page,
    (d) => {
      setTestimony(d.data);
      setTotalPage(d.metadata);
    },
    [page]
  );
  const { postFormData } = usePostPrivateFormData();
  const {
    postFormData: updateTest,
    loadin: updating,
    message: updateMsg,
  } = usePutPrivateFormData();
  const formData = new FormData();

  function handlePostAddTest(params) {
    formData.append("name", params.name);
    formData.append("rating", params.rating);
    formData.append("photo", params.photo);
    formData.append("text", params.text);
    formData.append("video", params.video);
    postFormData("testimony", formData, (data) => {
      setShow([]);
      setTestimony((prev) => [
        ...prev,
        { ...params, photo: params.img, _id: data },
      ]);
    });
  }
  function handleUpdateTest(params, id) {
    formData.append("name", params.name);
    formData.append("rating", params.rating);
    formData.append("photo", params.photo);
    formData.append("text", params.text);
    formData.append("video", params.video);
    updateTest("testimony/" + id, formData, (data) => {
      setShow([]);
      setTestimony(
        testimony.map((item) => {
          if (item._id === id) {
            return {
              ...params,
              _id: id,
            };
          }
          return item;
        })
      );
    });
  }
  function addNewTest(params) {
    let id;
    setShow((prev) => [
      ...prev,
      handleSetModal(
        <AddNewTest
          getData={(d) => {
            id = d;
          }}
          error={(e) => {
            setError(e);
          }}
          id={params}
        />,
        !params ? "Add" : "Edit",
        !params ? "Add New Testimony" : "Edit this Testimony",
        () => {
          params ? handleUpdateTest(id, params) : handlePostAddTest(id);
        }
      ),
    ]);
  }
  useUpdateModal([error], "Add New Testimony" || "Edit this Testimony", error);

  return (
    <>
      <Hero />
      <section class="pt-120 pb-120 border-top-1">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6 text-center">
              <div class="section-header">
                <h2 class="section-title">
                  <span class="font-weight-normal">
                    Discover What Our Clients{" "}
                  </span>{" "}
                  <b class="base--color">Say About Us</b>
                </h2>
                <p>
                  Explore testimonials and reviews from our valued clients. Hear
                  firsthand experiences about our services, investment
                  strategies, customer support, and the impact we've made on
                  their financial journeys. Get insights from our community to
                  aid in your decision-making process.
                </p>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-10">
              {isAdmin && <AddNewBtn doStn={() => addNewTest(undefined)} />}
              {testimony.map((item, index) => (
                <div
                  key={index}
                  class="subscribe-wrapper styled-font p-2 my-4 bg_img"
                >
                  <div class="row align-items-center w-100">
                    <div className="d-flex justify-content-between align-items-center w-100 p-2">
                      <div className="d-flex p-2 align-items-center">
                        <img
                          src={item.photo}
                          style={{ width: 50, height: 50, maxWidth: 50 }}
                          className="rounded-circle"
                          alt=""
                        />
                        <div className="d-flex justify-content-center ml-4 flex-column">
                          <span style={{ fontWeight: "bold" }} className="">
                            {item.name}
                          </span>
                          <span style={{ color: "#ccc" }}>{item.text}</span>
                        </div>
                      </div>
                      <div className="">
                        {sanitizeUndefinedValues(item.video) && (
                          <a href={item.video}>see video</a>
                        )}
                        <div style={{ color: "#ccc" }} class="ratings">
                          Rating:{" "}
                          {generateJSXMultipleTimes(
                            <i class="las la-star"></i>,
                            item.rating
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {isAdmin && (
                    <EditDelete
                      isDelete={true}
                      handleEdit={() => addNewTest(item._id)}
                      url={"testimony/"}
                      id={item._id}
                      successFn={() =>
                        setTestimony(
                          testimony.filter((it) => it._id !== item._id)
                        )
                      }
                    />
                  )}
                </div>
              ))}
              {testimony.length === 0 && <EmptyArray />}
              {totalPage > 1 && (
                <div className="w-100 d-flex justify-content-between py-5">
                  <button
                    className="cmn-btn w-auto px-3 py-2"
                    onClick={() => setPage(page - 1)}
                    disabled={page === 1}
                  >
                    Load Less
                  </button>
                  <button
                    onClick={() => setPage(page + 1)}
                    className="cmn-btn w-auto px-3 py-2"
                    disabled={!totalPage?.hasMore}
                  >
                    Load More
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimony;
