import React from "react";
import useDelePrivateData from "./useDeletePrivateData";
import { useAppContext } from "../contexts/app_context";
import { handleSetModal } from "../util/helper_fun";

const useGlobalDeleteData = (url, successFn = () => {}) => {
  const { setShow } = useAppContext();
  const { deletePrivateData } = useDelePrivateData();

  function handleDelete(id, headerTxt) {
    setShow((prev) => [
      ...prev,
      handleSetModal(
        <p className="styled-font">Are you sure?</p>,
        "Delete",
        headerTxt,
        () => {
          deletePrivateData(url + (id || ""), () => {
            successFn();
            setShow([]);
          });
        }
      ),
    ]);
  }
  return { handleDelete };
};

export default useGlobalDeleteData;
