import React from "react";
import Cookies from "js-cookie";
import Uppy from "@uppy/core";
import Webcam from "@uppy/webcam";
import ImageEditor from "@uppy/image-editor";
import "@uppy/webcam/dist/style.min.css";
import "@uppy/core/dist/style.min.css";
import "@uppy/image-editor/dist/style.min.css";

export const small_screen = 576;
export const med_screen = 768;
export const large_screen = 1200;
export const golden_color = "#cca354";
export const small_fontszie = "14px";
export const myStyles = {
  borderColor: "#333",
};

export const domain = "https://backend-gilt-ten.vercel.app/api/";
export const baseDomain = window.location.origin;
export const isUser = Cookies.get("user");
export const gotoLogin = () => {
  return (window.location.href = "/login");
};
export const gotoLocation = (location) => {
  return (window.location.href = location);
};
export const uppy = new Uppy({
  autoProceed: true,
  restrictions: {
    maxNumberOfFiles: 5,
    maxFileSize: 1024 * 1024 * 10, // 10 MB
    allowedFileTypes: ["image/*"],
  },
})
  .use(ImageEditor, {
    quality: 0.8,
  })
  .use(Webcam);
export const Tawkto_ID = process.env.REACT_APP_TAWKTO_ID;
export function hideSensitiveData(input) {
  if (typeof input === "string" && input.length >= 3) {
    const lastThreeDigits = input.slice(-3);
    const maskedString = "*".repeat(input.length - 3) + lastThreeDigits;
    return maskedString;
  }
  return input;
}
export function getEmail(params, d) {
  const email = d.find((item) => {
    const getemail = Object.entries(item).map(([key, value]) => {
      if (value === params) {
        return item.email;
      }
    });
    return getemail;
  });
  return email.email;
}
// export function getId(params, d) {
//   const getid = Object.entries(d).map(([key, value]) => {
//     if (value === params) {
//       return item.id;
//     }
//   });
//   return getid;
// }
