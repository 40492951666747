import { useState, useLayoutEffect } from "react";
import Cookies from "js-cookie";
import { gotoLogin } from "../util/util";

export const useUserAuthentication = () => {
  const [isUser, setIsUser] = useState(null);

  useLayoutEffect(() => {
    const userCookie = Cookies.get("user");
    userCookie && setIsUser(true);
    !userCookie && gotoLogin();
  }, []);

  return isUser;
};
