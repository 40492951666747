import React from "react";

const Spinner = ({ size }) => {
  return (
    <div class="w-100 h-100 d-flex align-items-center">
      <span
        style={{ height: size || 24, width: size || 24 }}
        className="spinner"
      ></span>
    </div>
  );
};

export default Spinner;
