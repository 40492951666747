import React from "react";

const NotFound = () => {
  return (
    <div class="error-area">
      <div class="error-content text-center">
        <div class="error-num">
          404
          <div class="error-num__clip">404</div>
        </div>
        <h2>Page Not Found</h2>
        <a href="/" class="cmn-btn mt-4">
          Go back to Home
        </a>
      </div>
    </div>
  );
};

export default NotFound;
