import { CheckCircleOutlined } from "@ant-design/icons";
import React from "react";
import { purchaseUSDT } from "./../../../../data";

const BinanceModal = () => {
  return (
    <div className="">
      {purchaseUSDT.map((item, index) => (
        <div
          key={index}
          className="w-100 d-flex my-1 base--color align-items-center styled-font text-left"
        >
          <CheckCircleOutlined style={{ fontSize: 20 }} />
          <p className="ml-2">{item}</p>
        </div>
      ))}
    </div>
  );
};

export default BinanceModal;
