import { ExclamationCircleFilled } from "@ant-design/icons";
import React from "react";
import { sanitizeInput } from "../../util/helper_fun";

const GlobalInput = ({
  label,
  preIcon,
  postIcon,
  getValue,
  inputFn,
  inputVal,
  inputType,
  inputPlaceholder,
  error,
  readOnly,
  inputRef,
  maxLength,
  minLength,
  width = "w-100",
  noSanitize,
  customCSS,
  isTextArea,
  inputCustomSize = "",
}) => {
  return (
    <div className={`${customCSS} form-group`}>
      <>
        <label className="text-left w-100">{label}</label>
        <div className={`${width} input-group mb-3`}>
          {preIcon && <span class="input-group-text">{preIcon}</span>}
          {!isTextArea ? (
            <input
              ref={inputRef}
              type={inputType || "text"}
              className={`form-control form-control${inputCustomSize}`}
              placeholder={inputPlaceholder}
              value={inputVal}
              onChange={(e) =>
                noSanitize
                  ? getValue(e.target.value)
                  : getValue(sanitizeInput(e.target.value))
              }
              readOnly={readOnly}
              maxLength={maxLength}
              minLength={minLength}
            />
          ) : (
            <textarea
              ref={inputRef}
              type={inputType || "text"}
              className={`form-control`}
              placeholder={inputPlaceholder}
              value={inputVal}
              onChange={(e) => {
                getValue(e.target.value);
              }}
              class="form-control"
            />
          )}
          {postIcon && (
            <span class="input-group-text" style={{fontSize:24}} onClick={inputFn} id="basic-addon1">
              {postIcon}
            </span>
          )}
        </div>
      </>

      {error && (
        <span className="d-flex p-1 rounded glow">
          <ExclamationCircleFilled
            className=""
            style={{ color: "#cca354", fontSize: 18 }}
          />
          <p className="ml-2" style={{ fontSize: 12 }}>
            {error}
          </p>
        </span>
      )}
    </div>
  );
};

export default GlobalInput;
