import React, { useState } from "react";
import { axiosFormPrivate } from "../api/axios";
import { useAppContext } from "./../contexts/app_context";

const usePostPrivateFormData = () => {
  const { handleNotice } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  async function postFormData(url, info, successFunction, noticeType) {
    try {
      setMessage();
      setLoading(true);
      const response = await axiosFormPrivate.post(url, info);
      if (noticeType === "alert") {
        successFunction(response.data.data);
        setMessage({ msg: response.data.message, type: response.data.type });
      } else {
        handleNotice(response.data.message, response.data.type, () => {
          successFunction(response.data.data);
        });
      }
      setLoading(false);
    } catch (error) {
      setMessage({
        msg: error.response?.data.message || error.message,
        type: error.response?.data.type || "error",
      });

      setLoading(false);
    }
  }
  return { postFormData, loading, message };
};

export default usePostPrivateFormData;
