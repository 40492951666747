import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useGetPrivateData from "../../../../hooks/useGetPrivateData";
import {
  capitalizeFirstLetter,
  handleSetModal,
} from "../../../../util/helper_fun";
import { useAppContext } from "../../../../contexts/app_context";
import usePostPrivateData from "./../../../../hooks/usePostPrivateData";
import { purchaseData } from "../../../../data";
import { CheckCircleOutlined } from "@ant-design/icons";
import useGlobalLoading from "../../../../hooks/useGlobalLoading";
import { useUserAuthentication } from "../../../../hooks/useUserAuth";
import CustomError from "../../../../shared/components/customError";
import Alert from "../../../../shared/components/alert";
const PurchasePlan = () => {
  useUserAuthentication();
  const { getPrivateData } = useGetPrivateData();
  const { postPrivateData, message } = usePostPrivateData();
  const [selected_plan, setSelectedPlan] = useState();
  const { name } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { setShow, setOpenModal, setHideServiceTab, siteData } =
    useAppContext();
  useLayoutEffect(() => {
    getPrivateData(`/purchase/${capitalizeFirstLetter(name)}`, (data) => {
      setSelectedPlan(data);
    });
  }, []);

  const isSystemError = "System error. Try again later" || "Network Error";
  function activatePlan(params) {
    postPrivateData(`purchase/${selected_plan?.name}`, {}, (data) => {
      navigate("/dashboard/purchase");
    });
  }
  useEffect(() => {
    if (message && message?.msg !== isSystemError) {
      setShow((prev) => [
        ...prev,
        handleSetModal(
          message.msg,
          "Deposit Now",
          "Insufficient Balance",
          () => {
            navigate("/dashboard/deposit");
            setOpenModal([]);
          }
        ),
      ]);
    }
  }, [message]);
  useEffect(() => {
    if (selected_plan && Object.keys(selected_plan).length > 0) {
      setHideServiceTab(
        location.pathname
          .toLowerCase()
          .includes(selected_plan.name.toLowerCase())
      );
    }
  }, [selected_plan, location]);
  return selected_plan && Object.keys(selected_plan).length > 0 ? (
    <div class="col-12 col-sm-10 col-md-8 col-lg-6 mb-30">
      {message && message?.msg === isSystemError && (
        <Alert type="error" msg={message?.msg} />
      )}
      <div
        class="package-card text-center bg_img"
        style={{ background: "url(/assets/images/bg/bg-4.png)" }}
      >
        <div class="package-card__range mb-5 ">
          {selected_plan && (
            <>
              <span className="base--color">
                ${Number(selected_plan.price).toLocaleString()}{" "}
              </span>{" "}
              for {selected_plan.duration}
            </>
          )}
        </div>
        {purchaseData.map((item, index) => (
          <div
            key={index}
            className="w-100 d-flex my-1 base--color align-items-center styled-font text-left"
          >
            <CheckCircleOutlined style={{ fontSize: 20 }} />
            <p className="ml-2">{item}</p>
          </div>
        ))}
        <div className="w-100 mt-3 styled-font">
          <p style={{ fontSize: 14, color: "#ccc" }} className="">
            By continuing, you have agreed to {siteData?.app_name}'s{" "}
            <a href="/legal" className="base--color styled-font">
              terms
            </a>{" "}
            &{" "}
            <a className="base--color styled-font" href="/legal">
              conditions
            </a>
          </p>
        </div>
        <button onClick={activatePlan} class="cmn-btn btn-md mt-4">
          Proceed
        </button>
      </div>
    </div>
  ) : (
    <CustomError
      title={"Item"}
      description={
        "The item you requested was not found. Choose a dfferent item"
      }
    />
  );
};

export default PurchasePlan;
