import { useEffect, useState } from "react";
import useGetPublicData from "./useGetPublicData";
import useGlobalLoading from "./useGlobalLoading";

const useGetSiteData = (params, getData = () => {}) => {
  const { getPublicData, loading } = useGetPublicData();
  useEffect(() => {
    getPublicData(`site?params=${params}`, (data) => {
      getData(data);
    });
  }, []);
  useGlobalLoading([loading], loading);
};

export default useGetSiteData;
