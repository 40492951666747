import React, { useEffect, useState } from "react";
import useGetPublicData from "../../../../hooks/useGetPublicData";
import { useNavigate } from "react-router-dom";
import useGlobalLoading from "../../../../hooks/useGlobalLoading";
import useIsUserError from "../../../../hooks/useIsUserError";
import { gotoLocation } from "../../../../util/util";
import EditDelete from "./../../../../shared/components/editDelete";
import {
  capitalizeFirstLetter,
  handleSetModal,
} from "../../../../util/helper_fun";
import { useAppContext } from "../../../../contexts/app_context";
import AddNewPlan from "../../components/addNewPlan";
import useUpdateModal from "../../../../hooks/useUpdateModal";
import AddNewBtn from "../../../../shared/components/addNewBtn";
import usePostPrivateData from "./../../../../hooks/usePostPrivateData";
import usePutPrivateData from "../../../../hooks/usePutPrivateData";

const Plans = ({ isAdmin, isUser }) => {
  const { getPublicData, loading } = useGetPublicData();
  const [data, setdata] = useState([]);
  const [error, setError] = useState();
  const { setShow } = useAppContext();
  const { postPrivateData } = usePostPrivateData();
  const { postData } = usePutPrivateData();
  useEffect(() => {
    getPublicData("/plan", (data) => {
      setdata(data);
    });
  }, []);
  const { handleError } = useIsUserError(
    <span className=" ">
      You must be a logged user to choose a plan. Go to{" "}
      <a className="base--color styled-font" href="/login">
        Log In
      </a>{" "}
      or{" "}
      <a className="base--color styled-font" href="/register">
        Register{" "}
      </a>
      .
    </span>
  );
  function handleInvestNow(name) {
    isUser ? gotoLocation(`/plan/${name}`) : handleError();
  }
  function handleAddNewPlan(params) {
    postPrivateData("/plan", params, () => {
      setShow([]);
      setdata((prev) => [...prev, { ...params, _id: data }]);
    });
  }
  function handleEditPlan(name, params) {
    postData("plan/" + capitalizeFirstLetter(name), params, () => {
      setShow([]);
      setdata(
        data.map((item) => {
          if (item.name === name) {
            return {
              ...params,
            };
          }
          return item;
        })
      );
    });
  }
  function addNewPlan(params) {
    let id;
    setShow((prev) => [
      ...prev,
      handleSetModal(
        <AddNewPlan
          getData={(d) => {
            id = d;
          }}
          error={(e) => {
            setError(e);
          }}
          isEdit={params}
        />,
        !params ? "Add" : "Edit",
        !params ? "Add New Plan" : "Edit Plan",
        () => {
          params ? handleEditPlan(params, id) : handleAddNewPlan(id);
        }
      ),
    ]);
  }
  useUpdateModal([error], "Add New Plan" || "Edit Plan", error);
  return (
    <>
      {" "}
      {isAdmin && <AddNewBtn doStn={() => addNewPlan(undefined)} />}
      {data.map((item, index) => (
        <div key={item._id} class="col-xl-3 col-lg-4 col-md-6 mt-3 mb-30">
          {isAdmin && (
            <EditDelete
              handleEdit={() => addNewPlan(item.name)}
              isDelete={true}
              url={"plan/"}
              id={item._id}
              successFn={() =>
                setdata(data.filter((it) => it._id !== item._id))
              }
            />
          )}
          <div
            style={{ background: "url(/assets/images/bg/bg-4.png)" }}
            class="package-card text-center bg_img"
          >
            <h4 class="package-card__title base--color mb-2">
              {item.name} Plan
            </h4>
            <ul class="package-card__features mt-4">
              <li>Profit of {item.profit}</li>
              <li>After {item.duration}</li>
              <li>Referral Percentage of {item.commission}</li>
            </ul>
            <div class="package-card__range mt-5 base--color">
              ${Number(item.min).toLocaleString()} -{" "}
              {!isNaN(Number(item.max)) ? "$" : ""}
              {isNaN(Number(item.max))
                ? item.max
                : Number(item.max).toLocaleString()}{" "}
            </div>
            <button
              onClick={() => handleInvestNow(item.name.toLowerCase())}
              class="cmn-btn btn-md mt-4"
            >
              Invest Now
            </button>
          </div>
        </div>
      ))}
    </>
  );
};

export default Plans;
