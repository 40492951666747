import React, { useState } from "react";
import { axiosPuplic } from "../api/axios";
import { useAppContext } from "../contexts/app_context";
import useGlobalLoading from "./useGlobalLoading";

const useGetPublicData = (isError = false) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const { setInternalError } = useAppContext();
  useGlobalLoading([loading], loading);
  async function getPublicData(url, successFunction) {
    setLoading(true);
    try {
      const response = await axiosPuplic.get(url);
      successFunction(response.data.data);
      setLoading(false);
      setInternalError(false);
    } catch (error) {
      setMessage({
        msg: error.response?.data.message || error.message,
        type: error.response?.data.type || "error",
      });
      setLoading(false);
      setInternalError(isError);
    }
  }
  return { getPublicData, loading, message };
};

export default useGetPublicData;
