import React from "react";
import Logo from "./components/logo";

const AuthHeader = () => {
  return (
    <div
      style={{ top: 0, left: 0, zIndex: 99, background: "#000", opacity: 0.7 }}
      className="position-fixed p-3 w-100"
    >
      <div style={{ width: 200 }}>
        <Logo />
      </div>
    </div>
  );
};

export default AuthHeader;
