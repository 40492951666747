import axios from "axios";
import Cookies from "js-cookie";
import { domain } from "../util/util";
const token = Cookies.get("user");

export const axiosPuplic = axios.create({
  baseURL: domain,
  // timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
});
export const axiosPrivate = axios.create({
  baseURL: domain,
  headers: {
    "Content-Type": "application/json",
    "access-token": token,
  },
});
export const axiosFormPrivate = axios.create({
  baseURL: domain,
  headers: {
    "Content-Type": "multipart/form-data",
    "access-token": token,
  },
});
