import React, { useEffect, useState } from "react";
import useGetPublicData from "../../../../hooks/useGetPublicData";
import useGlobalLoading from "./../../../../hooks/useGlobalLoading";
import useIsUserError from "../../../../hooks/useIsUserError";
import { gotoLocation } from "../../../../util/util";
import EditDelete from "../../../../shared/components/editDelete";
import usePostPrivateData from "../../../../hooks/usePostPrivateData";
import usePutPrivateData from "../../../../hooks/usePutPrivateData";
import { useAppContext } from "../../../../contexts/app_context";
import AddNewPurchase from "../../components/AddNewPurchase";
import useUpdateModal from "./../../../../hooks/useUpdateModal";
import AddNewBtn from "./../../../../shared/components/addNewBtn";
import {
  capitalizeFirstLetter,
  handleSetModal,
} from "../../../../util/helper_fun";

const PurchasePlans = ({ isAdmin, isUser }) => {
  const { getPublicData } = useGetPublicData();
  const { setShow } = useAppContext();
  const { postPrivateData } = usePostPrivateData();
  const { postData } = usePutPrivateData();
  const { handleError } = useIsUserError(
    <span className=" ">
      You must be a logged user to purchase this item. Go to{" "}
      <a className="base--color styled-font" href="/login">
        Log In
      </a>{" "}
      or{" "}
      <a className="base--color styled-font" href="/register">
        Register{" "}
      </a>
      .
    </span>
  );
  function handleInvestNow(name) {
    isUser ? gotoLocation(`/plan/purchase/${name}`) : handleError();
  }
  const [data, setdata] = useState([]);
  const [error, setError] = useState();
  useEffect(() => {
    getPublicData("/purchase", (data) => {
      setdata(data);
    });
  }, []);
  function handleAddNewPurchase(params) {
    postPrivateData("/purchase", params, () => {
      setShow([]);
      setdata((prev) => [...prev, { ...params, _id: data }]);
    });
  }
  function handleEditPurchase(name, params) {
    postData("purchase/" + capitalizeFirstLetter(name), params, () => {
      setShow([]);
      setdata(
        data.map((item) => {
          if (item.name === name) {
            return {
              ...params,
            };
          }
          return item;
        })
      );
    });
  }
  useUpdateModal([error], "Add New Purchase" || "Edit Purchase", error);
  function addNewPlan(params) {
    let id;
    setShow((prev) => [
      ...prev,
      handleSetModal(
        <AddNewPurchase
          getData={(d) => {
            id = d;
          }}
          error={(e) => {
            setError(e);
          }}
          isEdit={params}
        />,
        !params ? "Add" : "Edit",
        !params ? "Add New Purchase" : "Edit Purchase",
        () => {
          params ? handleEditPurchase(params, id) : handleAddNewPurchase(id);
        }
      ),
    ]);
  }
  return (
    <>
      {isAdmin && <AddNewBtn doStn={() => addNewPlan(undefined)} />}
      {data.map((item, index) => (
        <div key={item._id} class="col-xl-3 col-lg-4 col-md-6 mt-3 mb-30">
          {isAdmin && (
            <EditDelete
              handleEdit={() => addNewPlan(item.name)}
              isDelete={true}
              url={"purchase/"}
              id={item._id}
              successFn={() =>
                setdata(data.filter((it) => it._id !== item._id))
              }
            />
          )}
          <div
            class="package-card text-center bg_img"
            style={{ background: "url(/assets/images/bg/bg-4.png)" }}
          >
            <h4 class="package-card__title base--color mb-2">
              {item.name} Plan
            </h4>
            <div class="package-card__range mt-5 ">
              <span className="base--color">
                ${Number(item.price).toLocaleString()}
              </span>{" "}
              for {item.duration}
            </div>
            <ul class="package-card__features mt-4">
              <li>Referral Percentage of {item.commission}</li>
            </ul>

            <button
              onClick={() => handleInvestNow(item.name.toLowerCase())}
              class="cmn-btn btn-md mt-4"
            >
              Purchase Now
            </button>
          </div>
        </div>
      ))}
    </>
  );
};

export default PurchasePlans;
