import React, { useEffect } from "react";
import { useAppContext } from "./../contexts/app_context";

const useGlobalLoading = (params = [], condtion) => {
  const { setGlobalLoading } = useAppContext();
  useEffect(() => {
    setGlobalLoading(condtion);
  }, params);
};

export default useGlobalLoading;
