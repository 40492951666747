import React from "react";

const Error401 = () => {
  return (
    <div class="text-light col styled-font">
      <div class="container d-flex justify-content-center align-items-center min-vh-100">
        <div class="text-center">
          <h1 class="display-3 base--color">Oops!</h1>
          <h2 class="display-5 mb-4">Unauthorized Access</h2>
          <p class="lead">You are not authorized to access this page.</p>
          <p>
            Get proper authorization,{" "}
            <a href="/register" className="base--color">
              {" "}
              Register{" "}
            </a>
            ,{" "}
            <a href="/login" className="base--color">
              {" "}
              Login{" "}
            </a>{" "}
            or go to{" "}
            <a className="base--color" href="/">
              {" "}
              Homepage
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default Error401;
