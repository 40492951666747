import React, { useRef, useState } from "react";
import useOutsideClick from "./../../hooks/useOutsideClick";

const MiniDropdown = ({ id, item = [], name, data, disabled = false }) => {
  const [active, setactive] = useState();
  const btnRef = useRef(null);
  const modalRef = useRef(null);
  useOutsideClick(btnRef, modalRef, () => {
    setactive();
  });
  return (
    <div class="dropdown w-auto">
      <button
        class="btn btn-secondary dropdown-toggle"
        onClick={() => {
          setactive(active ? undefined : id);
        }}
        type="button"
        ref={btnRef}
        disabled={disabled}
      >
        {name}
      </button>
      {active && (
        <ul
          style={{ right: 0, left: "auto" }}
          class="dropdown-menu d-block right-0"
          aria-labelledby={"dropdownMenuButton" + id}
          ref={modalRef}
        >
          {item.map((it, index) => (
            <li
              role="button"
              onClick={() => {
                it.fnx(data);
              }}
              class="dropdown-item"
              key={index}
            >
              {it.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MiniDropdown;
