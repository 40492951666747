import React, { useEffect, useState } from "react";
import { useAppContext } from "../../../contexts/app_context";
import Main from "../components/main_data";
import HistoryData from "../components/history_data";
import useGetPrivateData from "../../../hooks/useGetPrivateData";
import { handleSetModal } from "../../../util/helper_fun";
import CryptoModal from "../components/modals/cryptoModal";
import { baseDomain } from "../../../util/util";

const Transaction = () => {
  const [referral, set_referral] = useState();
  const [raw_data, set_raw_data] = useState([]);
  const [data, set_data] = useState([]);
  const [refID, setRefId] = useState();
  const { screenWidth, setShow } = useAppContext();
  const { getPrivateData: getEarning } = useGetPrivateData();
  const { getPrivateData: getReferral } = useGetPrivateData();
  const { getPrivateData: getRefID } = useGetPrivateData();
  useEffect(() => {
    getEarning("total?params=referral", (d) => {
      set_referral(d);
    });
    getReferral("referral", (d) => {
      set_raw_data(d);
    });
    getRefID("user?params=my_ref_id", (d) => {
      setRefId(`${baseDomain}/register?ref=${d}`);
    });
  }, []);
  useEffect(() => {
    const d = raw_data?.map((item) => {
      if (screenWidth <= 992) {
        return {
          Earning: item.refEarning,
          ID: item.id,
          Capital: item.amount,
          Plan: item.plan,
          Status: item.status,
          Date: item.date,
          Time: item.time,
        };
      } else {
        // Otherwise, arrange by ID
        return {
          ID: item.id,
          Earning: item.refEarning,
          Capital: item.amount,
          Plan: item.plan,
          Status: item.status,
          Date: item.date,
          Time: item.time,
        };
      }
    });
    d && set_data(d);
  }, [screenWidth, raw_data]);
  function copyRefId(params) {
    setShow((prev) => [
      ...prev,
      handleSetModal(
        <CryptoModal
          text={refID}
          additionText={"Copy your link or use the QR Code to get link."}
        />,
        false,
        "Get Referral Link",
        () => {}
      ),
    ]);
  }
  return (
    <>
      <Main
        btn_text={"See Details"}
        btnFn={() => {
          copyRefId();
        }}
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            fill="currentColor"
            class="bi bi-people"
            viewBox="0 0 16 16"
          >
            <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4" />
          </svg>
        }
        totalText={"Total Ref. Earnings"}
        totalValue={referral?.sum}
        value={referral?.length}
        name={"Number of Refs."}
      />
      <HistoryData
        data={data || []}
        tab={[]}
        activeTabIndex={() => {}}
        condition={"Earning"}
      />
    </>
  );
};

export default Transaction;
