import React, { useEffect, useRef, useState } from "react";
import Main from "../../dashboard/components/main_data";
import useGetDataOnLoad from "../../../hooks/useGetLoadData";
import HistoryData from "../../dashboard/components/history_data";
import GlobalInput from "./../../../shared/components/globalInput";
import { SearchOutlined } from "@ant-design/icons";
import PageNavigation from "../../../shared/components/PageNavigation";
import useGlobalDeleteData from "./../../../hooks/useGlobalDeleteData";
import usePutPrivateData from "../../../hooks/usePutPrivateData";
import { handleSetModal, truncateEmail } from "../../../util/helper_fun";
import { useAppContext } from "../../../contexts/app_context";
import EmailModal from "../../../shared/components/EMailModal";
import usePostPrivateData from "../../../hooks/usePostPrivateData";

const AdminMessage = () => {
  const [data, set_data] = useState([]);
  const [data_raw, set_data_raw] = useState([]);
  const [value, setValue] = useState();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [updated, setUpdated] = useState(false);
  const [da, set_d] = useState([]);
  const { setShow } = useAppContext();
  useGetDataOnLoad(
    "support?page=" + page,
    (d) => {
      set_data_raw(
        d?.data.map((item, index) => {
          return {
            Email: <span>{truncateEmail(item.sender_email)}</span>,
            Name: item.sender_name,
            ID: item._id,
            Message: (
              <span
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  width: 100,
                }}
                className={`${!item.read ? "base--color" : ""}`}
              >
                {item.message}
              </span>
            ),
            Registered: (
              <span
                className={`${
                  item.isRegistered ? "bg-success" : "bg-danger"
                } p-1 rounded text-white`}
              >
                {item.isRegistered ? "true" : "false"}
              </span>
            ),
            Date: <span>{item.date}</span>,
            Read: item.read,
          };
        })
      );
      setTotalPage(d.metadata.totalPages);
      set_d(d?.data);
    },
    [page, updated]
  );
  useEffect(() => {
    if (value) {
      const searchRaw = da
        .filter((i) => {
          if (i.email.toLowerCase().includes(value.toLowerCase())) {
            return i;
          }
        })
        .map((i) => i._id);
      const search = data.filter((item) => searchRaw.includes(item.id));
      set_data(search);
    } else {
      set_data(data_raw);
    }
  }, [value, data_raw]);
  const { handleDelete } = useGlobalDeleteData("admin/email/", () =>
    setUpdated(!updated)
  );
  const { postData } = usePutPrivateData();
  const { postPrivateData } = usePostPrivateData();
  function handleSendMessage(email) {
    let data;
    setShow((prev) => [
      ...prev,
      handleSetModal(
        <EmailModal
          getValue={(e) => (data = e)}
          xEmail={email}
          headerTxt={"Send Message" + (email ? " to " + email : "")}
        />,
        "Send",
        "Send Message" + (email ? " to " + email : ""),
        () => {
          postPrivateData("admin/email", data, () => {
            setShow([]);
          });
        }
      ),
    ]);
  }
  function openMessage(email, message) {
    setShow((prev) => [
      ...prev,
      handleSetModal(<>{message}</>, false, "Message by " + email, () => {}),
    ]);
  }
  function handleRead(email, message, id, read = false) {
    read
      ? openMessage(email, message)
      : postData(
          "support/" + id,
          { read: true },
          () => {
            setUpdated(!updated);
            openMessage(email, message);
          },
          "alert"
        );
  }
  function findEmail(params) {
    let foundEmail = null;
  
    da.forEach(item => {
      const itemKeys = Object.keys(item);
      if (itemKeys.includes('_id') && item['_id'] === params) {
        foundEmail = item['sender_email'];
      }
    });
  
    return foundEmail;
  }
  

  



  return (
    <>
      <Main
        totalText={"Total Messages"}
        totalValue={data.length}
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            class="bi bi-chat-left-text"
            viewBox="0 0 16 16"
          >
            <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
            <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6m0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5" />
          </svg>
        }
        isDollar={false}
        btn_text={"Send Message"}
        btnFn={() => {
          handleSendMessage("");
        }}
      />
      {data.length > 0 && (
        <div className="col-10 col-md-6 col-lg-4">
          <GlobalInput
            postIcon={<SearchOutlined className="base--color" />}
            inputCustomSize="-sm"
            inputPlaceholder={"search message by email"}
            getValue={(v) => setValue(v)}
            inputVal={value}
          />
        </div>
      )}
      <HistoryData
        data={data}
        condition={"Email"}
        tab={[]}
        activeTabIndex={(data) => {}}
        data_name={"user"}
        isUSD={false}
        dropdownItem={[
          {
            name: "Read Message",
            fnx: (d) => {
              const id = d.ID;
              const read = d.Read;
              const message = d.Message.props.children;

              handleRead(findEmail(id), message, id, read);
            },
          },
          {
            name: "Reply Message",
            fnx: (d) => {
              handleSendMessage(findEmail(d.ID));
            },
          },
          {
            name: "Delete",
            fnx: (d) => {
              const id = d.ID;
              handleDelete(id, "Delete this message");
            },
          },
        ]}
        dropdownBtnName="Action"
        hideColumn={["ID", "Read"]}
      />
      {totalPage > 10 && (
        <PageNavigation totalPage={totalPage} getPage={(d) => setPage(d)} />
      )}
    </>
  );
};

export default AdminMessage;
