import React, { useEffect, useState } from "react";
import useGetPublicData from "../../../hooks/useGetPublicData";
import { formatDate } from "../../../util/helper_fun";
import useGlobalLoading from "../../../hooks/useGlobalLoading";

const Blog = () => {
  const [data, setdata] = useState();
  const { getPublicData, loading } = useGetPublicData();
  useEffect(() => {
    getPublicData("/news?page=1", (data) => {
      setdata(data.data.filter((item) => item.isPopular));
    });
  }, []);
  useGlobalLoading([loading], loading);
  return (
    data && (
      <section class="pt-120 pb-120 border-top-1">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6 text-center">
              <div class="section-header">
                <h2 class="section-title">
                  <span class="font-weight-normal">Stay Informed with Our</span>{" "}
                  <b class="base--color">Latest Insights</b>
                </h2>
                <p>
                  Explore our comprehensive repository of the most recent news,
                  updates, and insights from the financial world. Stay ahead
                  with expert analysis, market trends, company announcements,
                  and articles covering a spectrum of topics crucial for
                  informed decision-making in the ever-evolving financial
                  landscape.
                </p>
              </div>
            </div>
          </div>
          <div class="row justify-content-center mb-none-30">
            {data.map((item, index) => (
              <div key={index} class="col-lg-4 col-md-6 mb-30">
                <div class="blog-card">
                  <div class="blog-card__thumb">
                    <img src={item.urlToImage} alt="image" />
                  </div>
                  <div class="blog-card__content">
                    <h4 class="blog-card__title mb-3">
                      <a href={"/blog/" + item._id}>{item.title}</a>
                    </h4>
                    <ul class="blog-card__meta d-flex flex-wrap mb-4">
                      <li>
                        <i class="las la-user"></i>
                        <a onClick={(e) => e.preventDefault()} href="#0">
                          {item.author || "Anonymous"}
                        </a>
                      </li>
                      <li>
                        <i class="las la-calendar"></i>
                        <a onClick={(e) => e.preventDefault()} href="/blog">
                          {formatDate(item.publishedAt)}
                        </a>
                      </li>
                    </ul>
                    <p>{item.description}</p>
                    <a href={"/blog/" + item._id} class="cmn-btn btn-md mt-4">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div class="col-lg-12 mt-5 text-center">
          <a href="/blog" class="cmn-btn w-auto px-3 py-2">
            Go to Blog
          </a>
        </div>
      </section>
    )
  );
};

export default Blog;
