import React, { useEffect, useRef, useState } from "react";
import useGetPrivateData from "../../../hooks/useGetPrivateData";
import GlobalInput from "../../../shared/components/globalInput";

const AddFAQ = ({ getData = () => {}, error = () => {}, id }) => {
  const [title, settitle] = useState();
  const [text, settext] = useState();
  useEffect(() => {
    getData({
      title,
      text,
    });
    !title || !text ? error(false) : error(true);
  }, [title, text]);
  const { getPrivateData } = useGetPrivateData();
  useEffect(() => {
    id &&
      getPrivateData(`faq/${id}`, (d) => {
        settext(d.text);
        settitle(d.title);
      });
  }, [id]);
  return (
    <>
      <GlobalInput
        noSanitize={true}
        getValue={(v) => {
          settitle(v);
        }}
        label={"Title"}
        inputVal={title}
      />
      <GlobalInput
        noSanitize={true}
        getValue={(v) => {
          settext(v);
        }}
        label={"Enter text here"}
        inputVal={text}
        isTextArea={true}
      />
    </>
  );
};

export default AddFAQ;
