import React, { useState } from "react";
import usePageTitle from "../../hooks/usePageTitle";
import GlobalInput from "../../shared/components/globalInput";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LockOutlined,
  MailOutlined,
} from "@ant-design/icons";
import useToggle from "./../../hooks/useToggle";
import usePostData from "../../hooks/usePostData";
import Cookies from "js-cookie";
import Spinner from "../../shared/components/spinner";
import Alert from "../../shared/components/alert";
import { gotoLocation } from "../../util/util";
import { useAppContext } from "../../contexts/app_context";

const Login = () => {
  const [email, set_email] = useState();
  const [password, set_password] = useState();
  const [remember_me, toggle_remember_me] = useToggle(false);
  const [show_password, toggle_show_password] = useToggle(false);
  const { postData, loading, message } = usePostData();
  const { siteData } = useAppContext();
  function handleLogin(e) {
    const data = { email, password };
    e.preventDefault();
    postData("user/login", data, (data) => {
      Cookies.set("user", data.JWTToken);
      gotoLocation(
        data.isEmailVerified ? "/dashboard" : "/resend-email?email=" + email
      );
    });
  }

  usePageTitle(`Sign In to Your Account: ${siteData?.app_name} Login`);

  return (
    <div
      class="account-section bg_img"
      style={{ background: "url(/assets/images/bg/bg-5.jpg)" }}
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-5 col-lg-7">
            <div class="account-card">
              <div class="account-card__header login_bg bg_img overlay--one">
                <h2 class="section-title">Secure Access to Your Account</h2>
              </div>
              <div class="account-card__body">
                {message && <Alert type={message.type} msg={message.msg} />}
                <form onSubmit={handleLogin} class="mt-4">
                  <GlobalInput
                    label="Email"
                    preIcon={
                      <MailOutlined style={{ color: "#ccc", fontSize: 20 }} />
                    }
                    getValue={(val) => {
                      set_email(val.toLowerCase());
                    }}
                    inputPlaceholder="enter your email here"
                    inputType="text"
                    inputVal={email}
                  />

                  <GlobalInput
                    label="Password"
                    preIcon={
                      <LockOutlined style={{ color: "#ccc", fontSize: 20 }} />
                    }
                    getValue={(val) => {
                      set_password(val);
                    }}
                    inputPlaceholder="enter password"
                    inputType={show_password ? "text" : "password"}
                    postIcon={
                      !show_password ? (
                        <EyeOutlined style={{ color: "#ccc", fontSize: 20 }} />
                      ) : (
                        <EyeInvisibleOutlined
                          style={{ color: "#ccc", fontSize: 20 }}
                        />
                      )
                    }
                    inputFn={() => {
                      toggle_show_password();
                    }}
                    inputVal={password}
                  />
                  <div class="form-row d-flex align-items-center ">
                    <div class="w-100 d-flex justify-content-between">
                      <div class="form-group form-check">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          checked={remember_me}
                          onChange={() => toggle_remember_me()}
                        />
                        <label class="form-check-label" for="exampleCheck1">
                          Remember me
                        </label>
                      </div>
                      <a
                        href="/forgot-password"
                        className="float-right base--color mt-1"
                        style={{ fontSize: 13 }}
                      >
                        Forgot password?
                      </a>
                    </div>

                    <div class="col-sm-6">
                      <p class="f-size-14">
                        Don't have an account?{" "}
                        <a href="/register" class="base--color">
                          Register
                        </a>
                      </p>
                    </div>
                  </div>
                  <div class="mt-3">
                    <button
                      type="submit"
                      disabled={loading || !email || !password}
                      class="cmn-btn "
                    >
                      {loading ? (
                        <>
                          <Spinner />
                          Please wait...
                        </>
                      ) : (
                        "Log In"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
