import React from "react";
import { Outlet } from "react-router-dom";
import useToggle from "../../hooks/useToggle";
import Sidebar from "../../shared/components/Sidebar";
import { admin_nav_items } from "../../data";
import usePageTitle from "../../hooks/usePageTitle";
import Preloader from "../../shared/components/preloader";
import Error401 from "../../shared/components/Error401";

const Admin = ({ isAdmin = null }) => {
  usePageTitle("Admin Dashboard: Empowering Your Financial Growth");
  const [show, toggleShow] = useToggle(false);

  if (isAdmin === null) {
    // Handle loading state or condition not yet determined
    return <Preloader customDisplay={true} />;
  }
  if (isAdmin === false) {
    return <Error401 />;
  }

  return (
    isAdmin===true && (
      <div class="pt-5 mt-5 mt-lg-3 pb-120">
        <div style={{ flexWrap: "nowrap" }} className={`row w-100 h-100`}>
          <div className="col-auto pt-5">
            <Sidebar
              show={show}
              toggle_show={toggleShow}
              data={admin_nav_items}
            />
          </div>
          <div className="col-10 p-0 ml-2">
            <Outlet />
          </div>
        </div>
      </div>
    )
  );
};

export default Admin;
