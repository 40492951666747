import React, { useEffect, useState } from "react";
import PaymentMethodModal from "./paymentMethodModal";

const WithdrawalModal = ({getData, text}) => {
  const [value, setValue] = useState();
  const [s_payment, set_s_payment] = useState();
  useEffect(() => {
    if (s_payment && value) {
      getData({
        recommended: s_payment.recommended,
        value,
        name: s_payment.name,
      });
    }
  }, [s_payment, value]);

  return (
    <PaymentMethodModal
      get_s_payment={(s) => {
        set_s_payment(s);
      }}
      get_value={(v) => setValue(v)}
      text={text}
      getData={()=>{}}
      getRate={()=>{}}
    />
  );
};

export default WithdrawalModal;
