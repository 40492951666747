import React from "react";
import Profit from "../../plan/content/profit";

const Calculator = () => {
  return (
    <section class="pt-120 pb-120">
      <div class="container">
        <div class="row align-items-center flex-column justify-content-center">
          <div class="col-lg-6">
            <div class="section-header text-center">
              <h2 class="section-title">
                <span class="font-weight-normal">
                  Precision in Profit Planning:
                </span>{" "}
                <b class="base--color">Use Our Intuitive Calculator</b>
              </h2>
              <p>
                Make informed decisions swiftly with our Profit Calculator.
                Tailored for traders and investors, it offers real-time
                projections for potential returns. Simplify your analysis,
                assess risks, and plan your financial future with ease.
                Empowering your investment decisions, our tool ensures clarity
                and precision in achieving your goals
              </p>
            </div>
          </div>
          <Profit />
        </div>
      </div>
    </section>
  );
};

export default Calculator;
