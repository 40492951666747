import React, { useEffect, useState } from "react";
import { selectMultiple } from "../../../util/helper_fun";
import { useAppContext } from "./../../../contexts/app_context";
import EmptyArray from "../../../shared/components/EmptyArray";
import { isUser } from "./../../../util/util";
import MiniDropdown from "../../../shared/components/MiniDropdown";

const HistoryData = ({
  data,
  tab,
  data_name,
  condition,
  activeTabIndex,
  isUSD = true,
  dropdownItem,
  dropdownBtnName,
  disabled = [],
  hideColumn = [],
}) => {
  const { screenWidth } = useAppContext();
  const [is_active, set_is_active] = useState(0);
  const [activeIndexes, setActiveIndexes] = useState([]);
  useEffect(() => {
    screenWidth >= 992 && setActiveIndexes([]);
  }, [screenWidth]);
  useEffect(() => {
    activeTabIndex(is_active);
  }, [is_active]);
  return (
    <div class="row">
      <div className="d-flex w-100 justify-content-start ml-4 mb-3">
        {tab.map((item, index) => (
          <span
            key={index}
            className={`${
              is_active === index && "active_bottom"
            } styled-font p-2`}
            onClick={() => set_is_active(index)}
            role="button"
          >
            <span className="">{item.name}</span>
            <span className="badge my_bg rounded-circle px-2 py-1 ml-2">
              {item.length}
            </span>
          </span>
        ))}
      </div>
      <div class="w-100 d-flex">
        <div style={{ width: "95%" }} class="table-responsive--md mx-auto my-0">
          <table
            class="table style--two"
            style={{ overflowY: "auto", overflowX: "hidden" }}
          >
            <thead>
              <tr>
                {data.length > 0 &&
                  Object.entries(data[0]).map(([key, value], index) => (
                    <th
                      className={`${hideColumn.includes(key) ? "d-none" : ""}`}
                      key={index}
                    >
                      {key}
                    </th>
                  ))}
                {dropdownBtnName && data.length > 0 && <th>Action</th>}
              </tr>
            </thead>
            <tbody className="styled-font w-100">
              {data &&
                data.length > 0 &&
                data.map((item, index) => (
                  <tr className="w-100" key={index}>
                    {screenWidth >= 992 ||
                    (screenWidth <= 992 && activeIndexes.includes(index)) ? (
                      <>
                        {Object.entries(item).map(
                          ([key, value], entryIndex) => (
                            <>
                              <td
                                className={`${
                                  hideColumn.includes(key) ? "d-none" : ""
                                } mb-2 w-100`}
                                key={entryIndex}
                                data-label={key}
                              >
                                {value}{" "}
                                {key === "Amount" ||
                                key === "Profit" ||
                                key === "Earning" ||
                                key === "Capital"
                                  ? "USD"
                                  : ""}
                                {key === condition && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20px"
                                    height="20px"
                                    fill="currentColor"
                                    className={`${
                                      activeIndexes.includes(index)
                                        ? "rotate-180"
                                        : "rotate-0"
                                    } bi bi-caret-down-fill d-lg-none ml-2 base--color`}
                                    viewBox="0 0 16 16"
                                    onClick={() => {
                                      setActiveIndexes(
                                        selectMultiple(activeIndexes, index)
                                      );
                                    }}
                                  >
                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"></path>
                                  </svg>
                                )}
                              </td>
                            </>
                          )
                        )}
                        {dropdownBtnName && (
                          <td className="mb-2" data-label={"Action"}>
                            <MiniDropdown
                              id={index.toString()}
                              item={dropdownItem}
                              name={dropdownBtnName}
                              data={item}
                              disabled={disabled[index]}
                            />
                          </td>
                        )}
                      </>
                    ) : (
                      <td
                        data-label={Object.entries(item)[0][0]}
                        className="mb-2 w-100"
                      >
                        {Object.entries(item)[0][1]} {isUSD && "USD"}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20px"
                          height="20px"
                          fill="currentColor"
                          className={`${
                            activeIndexes.includes(index)
                              ? "rotate-180"
                              : "rotate-0"
                          } bi bi-caret-down-fill d-lg-none ml-2 base--color`}
                          viewBox="0 0 16 16"
                          onClick={() => {
                            setActiveIndexes(
                              selectMultiple(activeIndexes, index)
                            );
                          }}
                        >
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"></path>
                        </svg>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>

          {data.length === 0 && <EmptyArray data_name={data_name} />}
        </div>
      </div>
    </div>
  );
};

export default HistoryData;
