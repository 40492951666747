import React, { useState } from "react";
import GlobalInput from "./../../../shared/components/globalInput";

const SiteEmail = ({ siteemail, saveFnx = () => {} }) => {
  const [siteEmail, setSiteEmail] = useState(siteemail);

  return (
    <div className="mt-5">
      <GlobalInput
        noSanitize={true}
        inputVal={siteEmail}
        getValue={(v) => setSiteEmail(v)}
        label={"Change Site Email"}
      />
      <button
        disabled={!siteEmail || siteEmail === siteemail}
        className="cmn-btn col-12 col-lg-6 mx-auto"
        onClick={() => saveFnx({ email: siteEmail })}
      >
        save
      </button>
    </div>
  );
};

export default SiteEmail;
