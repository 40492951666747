import React, { useState } from "react";
import Main from "../../dashboard/components/main_data";
import useGetDataOnLoad from "../../../hooks/useGetLoadData";
import PageNavigation from "../../../shared/components/PageNavigation";
import useGlobalDeleteData from "./../../../hooks/useGlobalDeleteData";
import usePutPrivateData from "../../../hooks/usePutPrivateData";
import {
  capitalizeFirstLetter,
  handleSetModal,
  isHTML,
} from "../../../util/helper_fun";
import { useAppContext } from "../../../contexts/app_context";
import { gotoLocation } from "../../../util/util";
import EmptyArray from "../../../shared/components/EmptyArray";

const NotificationList = () => {
  const [data, set_data] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [updated, setUpdated] = useState(false);
  const { setShow } = useAppContext();
  useGetDataOnLoad(
    "notification?page=" + page,
    (d) => {
      set_data(d?.data);
      setTotalPage(d.metadata.totalPages);
    },
    [page, updated]
  );
  const { handleDelete } = useGlobalDeleteData("notification/", () =>
    setUpdated(!updated)
  );
  const { postData } = usePutPrivateData();
  function openMessage(subject, message) {
    setShow((prev) => [
      ...prev,
      handleSetModal(
        <div>
          {isHTML(message) ? (
            <div dangerouslySetInnerHTML={{ __html: message }} />
          ) : (
            <p>{message}</p>
          )}
        </div>,
        false,
        subject,
        () => {}
      ),
    ]);
  }
  function handleRead(subject, message, id, read = false) {
    read
      ? openMessage(subject, message)
      : postData(
          "notification/" + id,
          {},
          () => {
            setUpdated(!updated);
            openMessage(subject, message);
          },
          "alert"
        );
  }
  return (
    <>
      <Main
        totalText={"Total Messages"}
        totalValue={data.length}
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            class="bi bi-chat-left-text"
            viewBox="0 0 16 16"
          >
            <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
            <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6m0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5" />
          </svg>
        }
        isDollar={false}
        btn_text={"Send Message"}
        btnFn={() => {
          gotoLocation("/support");
        }}
      />
      <div class="row justify-content-center">
        <div class="col-lg-10">
          {data.map((item, index) => (
            <div
              role="button"
              onClick={() => {
                handleRead(item.subject, item.message, item._id, item.read);
              }}
              key={index}
              class="subscribe-wrapper styled-font p-2 my-4 bg_img"
            >
              <div class="row align-items-center w-100">
                <div className="d-flex justify-content-between align-items-center w-100 p-2">
                  <div className="d-flex p-2 align-items-center">
                    <img
                      src={"/assets/images/system/" + item.sender + ".png"}
                      width={50}
                      height={50}
                      className="rounded-circle"
                      alt=""
                    />
                    <div className="d-flex w-100 justify-content-center ml-4 flex-column">
                      <span style={{ fontWeight: "bold", color: "#ccc" }}>
                        {capitalizeFirstLetter(item.sender)}
                      </span>
                      <span
                        className={item.read ? "text-white" : "base--color"}
                      >
                        {item.subject}
                      </span>
                    </div>
                  </div>
                  <div className="ml-3">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(item._id, "Delete this message");
                      }}
                      className="btn bg-transparent text-warning"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {data.length === 0 && <EmptyArray />}
        </div>
      </div>

      {totalPage > 10 && (
        <PageNavigation totalPage={totalPage} getPage={(d) => setPage(d)} />
      )}
    </>
  );
};

export default NotificationList;
