import React, { useEffect, useState } from "react";
import useGetPrivateData from "./../../../hooks/useGetPrivateData";
import HistoryData from "../components/history_data";
import { useAppContext } from "../../../contexts/app_context";
import Main from "../components/main_data";
import { handleSetModal } from "../../../util/helper_fun";
import DepositModal from "../components/modals/depositModal"
import Preloader from "../../../shared/components/preloader";
const Deposit = () => {
  const [data, set_data] = useState([]);
  const [raw_data, set_raw_data] = useState([]);
  const [dat, set_dat] = useState([]);
  const [index, set_index] = useState(0);
  const [completed, set_completed] = useState(0);
  const [pending, set_pending] = useState(0);
  const [deposit, set_deposit] = useState();
  const { getPrivateData, loading } = useGetPrivateData();
  const { getPrivateData: getDeposit, loading: dLoading } = useGetPrivateData();

  const { screenWidth, setShow, globalLoading } = useAppContext();
  useEffect(() => {
    getPrivateData("/deposit", (data) => {
      set_dat(data);
    });
  }, []);
  useEffect(() => {
    getDeposit("total?params=deposit", (data) => {
      set_deposit(data);
    });
  }, []);
  useEffect(() => {
    const d = dat.map((item) => {
      if (screenWidth <= 992) {
        return {
          Amount: item.amount,
          "Deposit ID": item.id,
          Payment: item.method,
          Status: item.status,
          Date: item.date,
          Time: item.time,
        };
      } else {
        // Otherwise, arrange by ID
        return {
          "Deposit ID": item.id,
          Amount: item.amount,
          Payment: item.method,
          Status: item.status,
          Date: item.date,
          Time: item.time,
        };
      }
    });
    set_raw_data(d);
  }, [screenWidth, dat]);
  const tabData = [
    { name: "All", length: raw_data.length },
    { name: "Pending", length: pending },
    { name: "Completed", length: completed },
  ];
  useEffect(() => {
    const filteredDataPending = raw_data.filter(
      (item) => item.Status === "pending"
    );
    const filteredDataCompleted = raw_data.filter(
      (item) => item.Status === "completed"
    );
    set_completed(filteredDataCompleted.length);
    set_pending(filteredDataPending.length);
    if (index === 0) {
      set_data(raw_data);
    }
    if (index === 1) {
      set_data(filteredDataPending);
    }
    if (index === 2) {
      set_data(filteredDataCompleted);
    }
  }, [index, raw_data]);

  function depositNow(params) {
    let payment;
    setShow((prev) => [
      ...prev,
      handleSetModal(
        <DepositModal
          getData={(d) => {
            payment = d;
          }}
          text={"Deposit"}
        />,
        "Continue",
        "Deposit",
        () => {
          proceedDeposit(payment);
        }
      ),
    ]);
  }
  function proceedDeposit(params) {
    const { name, content, btn_text, fxn } = params;
    setShow((prev) => [
      ...prev,
      handleSetModal(content, btn_text, name, () => {
        fxn();
      }),
    ]);
  }

  return (
    <>
      <Main
        btn_text={"Deposit Now"}
        btnFn={() => {
          depositNow();
        }}
        icon={<i class="las la-wallet"></i>}
        totalText={"Total Deposit"}
        totalValue={deposit?.sum.toLocaleString()}
        value={deposit?.length}
        name={"Number of Deposits"}
      />{" "}
      <HistoryData
        data={data}
        condition={"Amount"}
        tab={tabData}
        activeTabIndex={(data) => {
          set_index(data);
        }}
        data_name={"deposit"}
      />
      {(globalLoading || dLoading) && <Preloader opacity={true} />}
    </>
  );
};

export default Deposit;
