import React from "react";
import { useAppContext } from "../../../contexts/app_context";

const WhyUs = () => {
  const { siteData } = useAppContext();
  return (
    <section
      class="pt-120 pb-120 overlay--radial bg_img"
      style={{ background: "url(/assets/images/bg/bg-3.jpg)" }}
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 text-center">
            <div class="section-header">
              <h2 class="section-title">
                <span class="font-weight-normal">Why Choose</span>{" "}
                <b class="base--color">{siteData?.app_name}</b>
              </h2>
              <p>
                Our goal is to provide our investors with a reliable source of
                high income, while minimizing any possible risks and offering a
                high-quality service.
              </p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center mb-none-30">
          <div class="col-xl-4 col-md-6 mb-30">
            <div class="choose-card border-radius--5">
              <div class="choose-card__header mb-3">
                <div class="choose-card__icon">
                  <i class="lar la-copy"></i>
                </div>
                <h4 class="choose-card__title base--color">Legal Company</h4>
              </div>
              <p>
                Our company conducts absolutely legal activities in the legal
                field. We are certified to operate investment business, we are
                legal and safe.
              </p>
            </div>
          </div>
          <div class="col-xl-4 col-md-6 mb-30">
            <div class="choose-card border-radius--5">
              <div class="choose-card__header mb-3">
                <div class="choose-card__icon">
                  <i class="las la-lock"></i>
                </div>
                <h4 class="choose-card__title base--color">High reliability</h4>
              </div>
              <p>
                We are trusted by a huge number of people. We are working hard
                constantly to improve the level of our security system and
                minimize possible risks.
              </p>
            </div>
          </div>
          <div class="col-xl-4 col-md-6 mb-30">
            <div class="choose-card border-radius--5">
              <div class="choose-card__header mb-3">
                <div class="choose-card__icon">
                  <i class="las la-user-lock"></i>
                </div>
                <h4 class="choose-card__title base--color">Anonymity</h4>
              </div>
              <p>
                Anonymity and using cryptocurrency as a payment instrument. In
                the era of electronic money – this is one of the most convenient
                ways of cooperation.
              </p>
            </div>
          </div>
          <div class="col-xl-4 col-md-6 mb-30">
            <div class="choose-card border-radius--5">
              <div class="choose-card__header mb-3">
                <div class="choose-card__icon">
                  <i class="las la-shipping-fast"></i>
                </div>
                <h4 class="choose-card__title base--color">Quick Withdrawal</h4>
              </div>
              <p>
                Our all retreats are treated spontaneously once requested. There
                are high maximum limits.
              </p>
            </div>
          </div>
          <div class="col-xl-4 col-md-6 mb-30">
            <div class="choose-card border-radius--5">
              <div class="choose-card__header mb-3">
                <div class="choose-card__icon">
                  <i class="las la-users"></i>
                </div>
                <h4 class="choose-card__title base--color">Referral Program</h4>
              </div>
              <p>
                We are offering a certain level of referral income through our
                referral program. you can increase your income by simply refer a
                few people.
              </p>
            </div>
          </div>
          <div class="col-xl-4 col-md-6 mb-30">
            <div class="choose-card border-radius--5">
              <div class="choose-card__header mb-3">
                <div class="choose-card__icon">
                  <i class="las la-headset"></i>
                </div>
                <h4 class="choose-card__title base--color">24/7 Support</h4>
              </div>
              <p>
                We provide 24/7 customer support through e-mail and telegram.
                Our support representatives are periodically available to
                elucidate any difficulty..
              </p>
            </div>
          </div>
          <div class="col-xl-4 col-md-6 mb-30">
            <div class="choose-card border-radius--5">
              <div class="choose-card__header mb-3">
                <div class="choose-card__icon">
                  <i class="las la-server"></i>
                </div>
                <h4 class="choose-card__title base--color">Dedicated Server</h4>
              </div>
              <p>
                We are using a dedicated server for the website which allows us
                exclusive use of the resources of the entire server.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
