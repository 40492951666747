import React, { useState } from "react";
import GlobalInput from "./../../../shared/components/globalInput";

const Facebook = ({ facebook, saveFnx = () => {} }) => {
  const [faceBook, setFaceBook] = useState(facebook);

  return (
    <div className="mt-5">
      <GlobalInput
        noSanitize={true}
        inputVal={faceBook}
        getValue={(v) => setFaceBook(v)}
        label={"Change Facebook Username"}
      />
      <button
        disabled={!faceBook || faceBook === facebook}
        className="cmn-btn col-12 col-lg-6 mx-auto"
        onClick={() => saveFnx({ facebook: faceBook })}
      >
        save
      </button>
    </div>
  );
};

export default Facebook;
