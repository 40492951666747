import React from "react";
import { isUser } from "../../../util/util";

const CTA = () => {
  return (
    <section class="pb-120">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-8">
            <div
              class="cta-wrapper bg_img border-radius--10 text-center"
              style={{ background: "url(/assets/images/bg/bg-8.jpg)" }}
            >
              <h2 class="title mb-3">Get Started Today With Us</h2>
              <p>
                Welcome to our global financial firm, specializing in Forex
                trading excellence. Our seasoned traders are dedicated to
                multiplying your funds through skillful Forex trades. In
                addition to our Forex expertise, we offer a proven, guaranteed
                trading bot. Experience consistent results and secure your
                financial future with us. Explore our expertise in Forex
                trading, led by seasoned traders and powered by robust
                automation.
              </p>
              {!isUser && (
                <a href="/register" class="cmn-btn mt-4 w-auto px-3 py-2">
                  Join Us
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTA;
