import React, { useEffect, useState } from "react";
import GlobalInput from "../../../../shared/components/globalInput";
import { useLocation, useNavigate } from "react-router-dom";
import {
  acceptPositiveIntegers,
  handleSetModal,
} from "../../../../util/helper_fun";
import { useAppContext } from "../../../../contexts/app_context";
import usePostPrivateData from "./../../../../hooks/usePostPrivateData";
import { managementData } from "../../../../data";
import { CheckCircleOutlined } from "@ant-design/icons";
import useIsUserError from "../../../../hooks/useIsUserError";
const Management = ({ isUser }) => {
  const { postPrivateData, message } = usePostPrivateData();
  const [amountError, setAmountError] = useState();
  const [error, setError] = useState();
  const [duration, setDuration] = useState();
  const [amount, setAmount] = useState();
  const navigate = useNavigate();
  const { setShow, setOpenModal, siteData } = useAppContext();
  const minDays = 20;
  const { handleError } = useIsUserError(
    <span className=" ">
      You must be a logged user to hire manager. Go to{" "}
      <a className="base--color styled-font" href="/login">
        Log In
      </a>{" "}
      or{" "}
      <a className="base--color styled-font" href="/register">
        Register{" "}
      </a>
      .
    </span>
  );

  function checkError(params) {
    setError();
    if (minDays > Number(duration)) {
      setError(
        `Number of days must be greater than or equal to 20 working Days`
      );
      return true;
    }
  }
  function checkAmountError(params) {
    setAmountError();
    if (Number(amount) < 500) {
      setAmountError(`Minimum amount must be greater than or equal $500`);
      return true;
    }
  }
  useEffect(() => {
    if (amount) {
      checkAmountError();
    } else {
      setAmountError();
    }
  }, [amount]);
  useEffect(() => {
    if (duration) {
      checkError();
    } else {
      setError();
    }
  }, [duration]);
  function activatePlan(params) {
    postPrivateData(`management`, { duration, amount }, (data) => {
      navigate("/dashboard/management");
    });
  }
  useEffect(() => {
    if (message) {
      setShow((prev) => [
        ...prev,
        handleSetModal("", "Deposit Now", "Insufficient Balance", () => {
          navigate("/dashboard/deposit");
          setOpenModal([]);
        }),
      ]);
    }
  }, [message]);

  return (
    <div class="col-12 col-sm-10 col-md-8 col-lg-6 mb-30">
      <div
        style={{ background: "url(/assets/images/bg/bg-4.png)" }}
        class="package-card text-center bg_img"
      >
        <h4 class="package-card__title base--color mb-2">
          Hire <span>Manager</span>{" "}
        </h4>
        <div class="package-card__range mt-5 ">
          {!amountError && !error && (
            <>
              <span className="base--color">
                10% of the total trading balance{" "}
              </span>{" "}
              will be charged after {duration} days of trading.
            </>
          )}
        </div>
        <div className="mt-5 mb-3">
          {managementData.map((item, index) => (
            <div
              key={index}
              className="w-100 d-flex my-1 base--color align-items-center styled-font text-left"
            >
              <CheckCircleOutlined style={{ fontSize: 20 }} />
              <p className="ml-2">{item}</p>
            </div>
          ))}
        </div>
        <GlobalInput
          error={error}
          inputType={"text"}
          label={"Number of Trading Days"}
          getValue={(v) => {
            setDuration(acceptPositiveIntegers(v));
          }}
          inputVal={duration}
        />
        <GlobalInput
          error={amountError}
          inputType={"text"}
          label={"How much do you wish to trade?"}
          getValue={(v) => {
            setAmount(acceptPositiveIntegers(v));
          }}
          inputVal={amount}
        />
        <div className="w-100 mt-3 styled-font">
          <p style={{ fontSize: 14, color: "#ccc" }} className="">
            By continuing, you have agreed to {siteData?.app_name}'s{" "}
            <a href="/legal" className="base--color styled-font">
              terms
            </a>{" "}
            &{" "}
            <a className="base--color styled-font" href="/legal">
              conditions
            </a>
          </p>
        </div>
        <button
          disabled={!amount || error || !duration}
          onClick={() => (isUser ? activatePlan() : handleError())}
          class="cmn-btn btn-md mt-4"
        >
          Hire Manager
        </button>
      </div>
    </div>
  );
};

export default Management;
