import React, { useEffect, useState } from "react";
import useGetPrivateData from "./../../../hooks/useGetPrivateData";
import HistoryData from "../components/history_data";
import { useAppContext } from "../../../contexts/app_context";
import Main from "../components/main_data";
import { useNavigate } from "react-router-dom";
import useGlobalLoading from "../../../hooks/useGlobalLoading";

const SignalHistory = () => {
  const [data, set_data] = useState([]);
  const [raw_data, set_raw_data] = useState([]);
  const [dat, set_dat] = useState([]);
  const [index, set_index] = useState(0);
  const [signal, setSignal] = useState();
  const [completed, set_completed] = useState(0);
  const [pending, set_pending] = useState(0);
  const { getPrivateData, loading } = useGetPrivateData();
  const { getPrivateData: getInvest } = useGetPrivateData();
  const navigate = useNavigate();
  const { screenWidth } = useAppContext();
  useGlobalLoading([loading], loading);
  useEffect(() => {
    getPrivateData("user/signal", (data) => {
      set_dat(data);
    });
    getInvest("/total?params=signal", (data) => {
      setSignal(data);
    });
  }, []);
  useEffect(() => {
    const d = dat.map((item) => {
      if (screenWidth <= 992) {
        return {
          Amount: Number(item.amount).toLocaleString(),
          "Purchase ID": item.id,
          Status: (
            <span
              className={`${
                item.status === "active"
                  ? "glow bg-transparent base--color"
                  : "bg-success text-white"
              } p-1 rounded`}
            >
              {item.status}
            </span>
          ),
          Telegram: item.telegram,
          Duration: item.duration,
          Date: item.date,
          Time: item.time,
        };
      } else {
        // Otherwise, arrange by ID
        return {
          "Purchase ID": item.id,
          Amount: Number(item.amount).toLocaleString(),
          Status: (
            <span
              className={`${
                item.status === "active"
                  ? "glow bg-transparent base--color"
                  : "bg-success text-white"
              } p-1 rounded`}
            >
              {item.status}
            </span>
          ),
          Telegram: item.telegram,
          Duration: item.duration,
          Date: item.date,
          Time: item.time,
        };
      }
    });
    set_raw_data(d);
  }, [screenWidth, dat]);
  const tabData = [
    { name: "All", length: raw_data.length },
    { name: "Active", length: pending },
    { name: "Expired", length: completed },
  ];
  useEffect(() => {
    const filteredDataPending = raw_data.filter(
      (item) => item.Status.props.children === "active"
    );
    const filteredDataCompleted = raw_data.filter(
      (item) => item.Status.props.children === "expired"
    );
    set_completed(filteredDataCompleted.length);
    set_pending(filteredDataPending.length);
    if (index === 0) {
      set_data(raw_data);
    }
    if (index === 1) {
      set_data(filteredDataPending);
    }
    if (index === 2) {
      set_data(filteredDataCompleted);
    }
  }, [index, raw_data]);

  return (
    <>
      <Main
        totalText={"Total Signal"}
        totalValue={Number(signal?.sum).toLocaleString()}
        name={"Number of Purchased Signal"}
        icon={<i class="las la-cubes "></i>}
        btn_text={"Purchase Now"}
        btnFn={() => {
          navigate("/plan/signal");
        }}
        value={signal?.length}
      />
      <HistoryData
        data={data}
        condition={"Amount"}
        tab={tabData}
        activeTabIndex={(data) => {
          set_index(data);
        }}
        data_name={"signal"}
      />
    </>
  );
};

export default SignalHistory;
