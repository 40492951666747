import React, { useEffect, useState } from "react";
import Card from "../components/user_components/card";
import { useAppContext } from "../../../contexts/app_context";
import { Dashboard } from "@uppy/react";
import "@uppy/dashboard/dist/style.min.css";
import usePutPrivateFormData from "../../../hooks/usePutPrivateFormData";
import useUploadImage from "../../../hooks/useUploadImage";
import { uppy } from "../../../util/util";

const User = ({ userData }) => {
  const { postFormData } = usePutPrivateFormData();
  const { openImgModal } = useAppContext();
  const [data, setdata] = useState();
  const [photo, set_photo] = useState();
  const [photo_pre, set_photo_pre] = useState();

  useUploadImage((data) => {
    set_photo(data.preview);
    set_photo_pre(data.data);
  });
  const formData = new FormData();
  formData.append("photo", photo_pre?.data);

  useEffect(() => {
    setdata(userData);
    set_photo(userData?.photo);
  }, [userData]);
  return (
    <>
      <div
        style={{ flexWrap: "wrap" }}
        class="mb-5 w-100 d-flex flex-column justify-content-center align-items-center"
      >
        <div class="col-md-3 d-flex flex-column justify-content-center align-items-center mt-2 col-12 mb-30">
          <img
            src={photo}
            alt="photo"
            style={{
              overflow: "hidden",
              height: 200,
              width: 200,
              maxWidth: 200,
            }}
            className="rounded-circle"
          />
          <button
            className="bg-transparent styled-font base--color mt-2"
            onClick={() =>
              photo_pre
                ? postFormData("user/image", formData, () => {
                    set_photo_pre();
                  })
                : openImgModal(
                    <Dashboard
                      uppy={uppy}
                      plugins={["Webcam", "ImageEditor"]}
                    />
                  )
            }
          >
            {photo_pre ? "Save Photo" : " Edit Photo"}
          </button>
        </div>
        <div
          style={{ color: "#ccc" }}
          class="col-md-9 col-12 mb-30 d-flex justify-content-center styled-font px-5"
        >
          <div className="col-12 col-md-8">
            <div className="d-flex border-bottom border-secondary justify-content-between">
              <span>Detected Country: </span>
              <span
                height={15}
                width={21}
                className={`ml-2 rounded fi fi-${data?.countryCode?.toLowerCase()}`}
              ></span>
            </div>
            <div className="d-flex justify-content-between my-2 border-bottom border-secondary ">
              <span>Currency:</span>
              <span className="">{data?.currency}</span>
            </div>
            <div className="d-flex justify-content-between ">
              <span>Date Joined:</span>
              <span className="text-right">{data?.date}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-flex flex-column justify-content-center align-items-center">
        <Card />
      </div>
    </>
  );
};

export default User;
