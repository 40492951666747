import React, { useEffect, useState } from "react";

const PageNavigation = ({totalPage, getPage = () => {} }) => {
  const [page, setPage] = useState(1);
  useEffect(() => {
    getPage(page);
  }, [page]);

  return (
    <div className="w-100 d-flex justify-content-between px-3 py-5">
      <button
        className="cmn-btn w-auto px-3 py-2"
        onClick={() => setPage(page - 1)}
        disabled={page === 1}
      >
        Load Less
      </button>
      <button
        onClick={() => setPage(page + 1)}
        className="cmn-btn w-auto px-3 py-2"
        disabled={!totalPage?.hasMore}
      >
        Load More
      </button>
    </div>
  );
};

export default PageNavigation;
