import React from 'react';

export function checkIfItemExists(arr, itemToCheck) {
  return arr.some((item) => item === itemToCheck);
}
export function getCurrentPageName(params) {
  const url = window.location.pathname;
  const currentPage = url.substring(url.lastIndexOf("/") + 1);

  return checkIfItemExists(params, currentPage);
}
export function selectMultiple(array, params) {
  if (array.includes(params)) {
    return array.filter((item) => item !== params);
  } else {
    return [...array, params];
  }
}
export function isEmailValid(email, emailError = () => {}) {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!email.match(emailPattern)) {
    emailError("Please enter a valid email address");
    return false;
  } else {
    return true;
  }
}
export function validateForm(email, password, name, emailError, passwordError) {
  emailError("");
  passwordError("");
  if (!email || !password || !name) {
    passwordError("All fields must be filled out");
    return false;
  }

  // Check if the email format is valid
  isEmailValid(email, (e) => {
    emailError(e);
  });

  // Check password length
  if (password.length < 6) {
    passwordError("Password should be at least 6 characters");
    return false;
  }

  // Additional validations can be added as needed

  return true; // Form is valid
}
export function sanitizeInput(input) {
  // Trim leading and trailing white spaces
  const trimmedInput = input.trim();

  // Sanitize the input (remove HTML tags using a regular expression)
  const sanitizedInput = trimmedInput.replace(/<[^>]*>?/gm, "");

  return sanitizedInput;
}
export function hideSensitiveData(input) {
  if (typeof input === "string" && input.length >= 3) {
    const lastThreeDigits = input.slice(-3);
    const maskedString = "*".repeat(input.length - 3) + lastThreeDigits;
    return maskedString;
  }
  return input;
}
export function truncateEmail(email) {
  const atIndex = email.indexOf("@");
  if (atIndex > 6) {
    const username = email.substring(0, 2);
    const domain = email.substring(atIndex - 1);
    return `${username}...${domain}`;
  }
  return email;
}
export function activeLink(currentLink, link) {
  return currentLink.pathname === link;
}
export function calculateValueAndPercentage(value, percentage) {
  const numericValue = parseFloat(value);
  const numericPercentage = parseFloat(percentage.replace("%", ""));

  if (isNaN(numericValue) || isNaN(numericPercentage)) {
    return 0;
  }

  const calculatedPercentage = numericValue * (numericPercentage / 100);
  const total = numericValue + calculatedPercentage;

  return total.toFixed(2);
}
export function capitalizeFirstLetter(str) {
  const [firstLetter, ...rest] = str;
  return `${firstLetter.toUpperCase()}${rest.join("")}`;
}

export function handleSetModal(
  content,
  btn_name,
  headerText,
  actionFx,
  condition = true
) {
  return {
    content,
    btn_name,
    headerText,
    actionFx,
    condition,
  };
}
export function acceptPositiveIntegers(params) {
  if (Number.isInteger(Number(params)) && Number(params) > 0) {
    return params;
  } else {
    return "";
  }
}
export function acceptIntegers(params) {
  if (Number.isInteger(Number(params))) {
    return params;
  } else {
    return "";
  }
}
export function includesAny(arr, str) {
  return arr.some((item) => str.toLowerCase().includes(item.toLowerCase()));
}
export const handleCopy = (textRef, closeTooltip) => {
  textRef.current.select();
  document.execCommand("copy");
  setTimeout(() => {
    closeTooltip();
  }, 2000);
};
export function maskCreditCardNumber(cardNumber) {
  // Remove non-digit characters
  const cleanedNumber = cardNumber.toString().replace(/\D/g, "");

  // Format the input: add spaces after every 4 characters
  const formattedNumber = cleanedNumber.replace(/(\d{4})/g, "$1 ").trim();

  return formattedNumber;
}
export function simulateExpiryDateInput(inputValue) {
  const cleanedValue = inputValue.replace(/\D/g, ""); // Remove non-digit characters
  let formattedValue = "";

  if (cleanedValue.length >= 1) {
    formattedValue += cleanedValue.substring(0, 2); // Extract first two characters (MM)
    if (parseInt(formattedValue, 10) > 12) {
      formattedValue = "12"; // Limit month to 12
    }
  }

  if (cleanedValue.length > 2) {
    formattedValue += "/"; // Add '/' after the first two characters (MM)
    formattedValue += cleanedValue.substring(2, 4); // Add last two characters (YY)
  } else if (cleanedValue.length > 1) {
    formattedValue += "/"; // Add '/' after the first two characters (MM) even if length is 2
  }

  // Logic to handle deletion of characters
  if (inputValue.length < formattedValue.length) {
    formattedValue = formattedValue.slice(0, -1); // Remove the last character
  }

  return formattedValue;
}

export function formatDate(inputDate) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const date = new Date(inputDate);
  const year = date.getFullYear();
  const monthIndex = date.getMonth();
  const day = date.getDate();

  const formattedDate = `${months[monthIndex]} ${day}, ${year} at ${formatAMPM(
    date
  )}`;
  return formattedDate;
}

function formatAMPM(date) {
  let hours = date.getUTCHours(); // Use getUTCHours() for UTC time
  let minutes = date.getUTCMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // Handle midnight (0 hours)
  minutes = minutes < 10 ? "0" + minutes : minutes;
  const formattedTime = hours + ":" + minutes + " " + ampm;
  return formattedTime;
}

export function sanitizeUndefinedValues(data) {
  if (data === "null" || data === "undefined") {
    return null;
  }
  return data;
}
export function generateJSXMultipleTimes(element, times) {
  const jsxArray = Array.from({ length: times }, (_, index) => (
    <span key={index}>{element}</span>
  ));
  return jsxArray;
}
export function generateBreadcrumb(url) {
  const segments = url.split("/").filter((segment) => segment !== ""); // Split URL by '/' and filter out empty segments
  const breadcrumb = [];

  // Always add the "Home" breadcrumb as the first item
  breadcrumb.push({ label: "Home", path: "/" });

  segments.forEach((segment, index) => {
    const isLastSegment = index === segments.length - 1;
    const breadcrumbItem = {
      label: segment.charAt(0).toUpperCase() + segment.slice(1), // Capitalize the first letter of each segment
      path: isLastSegment ? "" : `/${segments.slice(0, index + 1).join("/")}`, // Don't set a path for the last segment
    };
    breadcrumb.push(breadcrumbItem);
  });

  return breadcrumb;
}

export function isHTML(str) {
  const doc = new DOMParser().parseFromString(str, "text/html");
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
}
export function trimAndReduceArray(arr, maxItems) {
  if (!Array.isArray(arr) || typeof maxItems !== "number" || maxItems <= 0) {
    return arr;
  }

  return arr.slice(0, maxItems);
}

