import React, { useEffect, useState } from "react";
import GlobalInput from "./globalInput";
import { isEmailValid } from "../../util/helper_fun";
import useUpdateModal from "../../hooks/useUpdateModal";

const EmailModal = ({ xEmail, getValue = () => {}, headerTxt }) => {
  const [email, setEmail] = useState();
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();
  const [emailError, setEmailError] = useState();
  const [error, setError] = useState();
  useEffect(() => {
    !email || !subject || !message || emailError
      ? setError(false)
      : setError(true);
    getValue({ email, subject, message });
  }, [message, subject, email]);
  useEffect(() => {
    setEmail(xEmail);
  }, []);
  useEffect(() => {
    email
      ? isEmailValid(email)
        ? setEmailError()
        : isEmailValid(email, (e) => {
            setEmailError(e);
          })
      : setEmailError();
  }, [email]);
  useUpdateModal([email, subject, message, emailError], headerTxt, error);
  return (
    <>
      <GlobalInput
        label={"Email"}
        readOnly={xEmail ? true : false}
        getValue={(v) => {
          setEmail(v);
        }}
        inputVal={email}
      />
      <GlobalInput
        label={"Message subject"}
        noSanitize={true}
        getValue={(v) => {
          setSubject(v);
        }}
      />
      <GlobalInput
        label={"Message"}
        isTextArea={true}
        getValue={(v) => {
          setMessage(v);
        }}
      />
    </>
  );
};

export default EmailModal;
