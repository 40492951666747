import React, { useEffect, useState } from "react";
import GlobalInput from "../../../../shared/components/globalInput";
import useGetPrivateData from "../../../../hooks/useGetPrivateData";
import { isEmailValid } from "../../../../util/helper_fun";

const Email = ({ getEmail, getEmailError }) => {
  const [email, setemail] = useState();
  const [email_error, set_email_error] = useState();
  const { getPrivateData: getEmailData } = useGetPrivateData();
  useEffect(() => {
    if (email) {
      if (!isEmailValid(email, (e) => {})) {
        isEmailValid(email, (e) => {
          set_email_error(e);
        });
      } else {
        set_email_error();
        getEmail(email);
      }
    } else {
      set_email_error();
      getEmail();
    }
  }, [email]);
  useEffect(() => {
    getEmailError(email_error ? true : false);
  }, [email_error]);

  useEffect(() => {
    getEmailData("user?params=email", (d) => setemail(d));
  }, []);
  return (
    <>
      <GlobalInput
        label={"Email"}
        error={email_error}
        getValue={(v) => setemail(v)}
        inputVal={email}
      />
    </>
  );
};

export default Email;
