import React, { useEffect, useRef, useState } from "react";
import useToggle from "../../hooks/useToggle";
import DownIcon from "./downIcon";
import useOutsideClick from "../../hooks/useOutsideClick";

const Dropdown = ({ label, current, get_index, data }) => {
  const [active, set_active] = useState(current);
  const [active_img, set_active_img] = useState();
  const [select_plan, togglePlan] = useToggle(false);
  const btnRef = useRef(null);
  const modalRef = useRef(null);
  useOutsideClick(modalRef, btnRef, () => {
    togglePlan(false);
  });
  return (
    <div className="my-3 w-100 position-relative">
      <label style={{ color: "#cccccc" }} className="w-100 ml-1 text-left">
        {label}
      </label>
      <div
        className={`${
          select_plan ? "glow" : ""
        } py-2 px-2 my_border w-100 d-flex align-items-center justify-content-between `}
        role="button"
        onClick={() => data && togglePlan()}
        ref={btnRef}
      >
        <span className="d-flex align-items-center h-100">
          {active_img && (
            <img
              src={active_img}
              alt=""
              width={24}
              height={24}
              className="img-fluid rounded mr-2"
            />
          )}
          <span className="base--color">{active}</span>
        </span>
        <DownIcon condition={select_plan} />
      </div>
      {select_plan && (
        <ul class=" px-2 package-card__features my_border mt-2" ref={modalRef}>
          {data.map((item, index) => (
            <li
              onClick={() => {
                set_active(item.name);
                set_active_img(item.img);
                get_index(item);
                togglePlan(false);
              }}
              role="button"
              key={index}
              className="text-left d-flex w-100 d-flex align-items-center justify-content-between"
            >
              <span className="d-flex align-items-center h-100">
                {item.img && (
                  <img
                    src={item.img}
                    alt=""
                    width={24}
                    height={24}
                    className="rounded mr-2"
                  />
                )}
                <span className="base--color">{item.name}</span>
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-arrow-right-square-fill base--color"
                viewBox="0 0 16 16"
              >
                <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1" />
              </svg>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
