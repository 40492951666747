import React, { useEffect, useState } from "react";
import { useAppContext } from "../../../contexts/app_context";
import { useNavigate } from "react-router-dom";
import useGetPrivateData from "./../../../hooks/useGetPrivateData";
import Main from "./../../dashboard/components/main_data";
import HistoryData from "./../../dashboard/components/history_data";
import { handleSetModal, truncateEmail } from "../../../util/helper_fun";
import usePutPrivateData from "../../../hooks/usePutPrivateData";

const AdminInvestment = () => {
  const [data, set_data] = useState([]);
  const [raw_data, set_raw_data] = useState([]);
  const [dat, set_dat] = useState([]);
  const [index, set_index] = useState(0);
  const [completed, set_completed] = useState(0);
  const [pending, set_pending] = useState(0);
  const [totalData, setTotalData] = useState();
  const { getPrivateData } = useGetPrivateData();
  const { postData } = usePutPrivateData();
  const { screenWidth, setShow } = useAppContext();
  useEffect(() => {
    getPrivateData("/admin/invest", (data) => {
      set_dat(data);
      setTotalData(data.length);
    });
  }, []);
  useEffect(() => {
    const d = dat.map((item) => {
      if (screenWidth <= 992) {
        return {
          Profit: item.profit,
          "Investment ID": item.id,
          Amount: item.amount,
          Email: <span>{truncateEmail(item.email)}</span>,
          Status: (
            <span
              role="button"
              onClick={() => {
                item.status === "active" && confirmDeposit(item.id);
              }}
              className={`${
                item.status === "active"
                  ? "glow bg-transparent base--color"
                  : "bg-success text-white"
              } p-1 rounded`}
            >
              {item.status === "active" ? "Confirm" : item.status}
            </span>
          ),
          Plan: item.plan,
          Date: item.date,
        };
      } else {
        return {
          "Investment ID": item.id,
          Amount: item.amount,
          Profit: item.profit,
          Email: <span>{truncateEmail(item.email)}</span>,
          // Status: (
          //   <span
          //     className={`${
          //       item.status === "active"
          //         ? "glow bg-transparent base--color"
          //         : "bg-success text-white"
          //     } p-1 rounded`}
          //   >
          //     {item.status}
          //   </span>
          // ),
          Status: (
            <span
              role="button"
              onClick={() => {
                item.status === "active" && confirmDeposit(item.id);
              }}
              className={`${
                item.status === "active"
                  ? "glow bg-transparent base--color"
                  : "bg-success text-white"
              } p-1 rounded`}
            >
              {item.status === "active" ? "Confirm" : item.status}
            </span>
          ),
          Plan: item.plan,
          Date: item.date,
          Time: item.time,
        };
      }
    });
    set_raw_data(d);
  }, [screenWidth, dat]);
  function confirmDeposit(id) {
    setShow((prev) => [
      ...prev,
      handleSetModal(
        "Are you sure you want to confirm this investment?",
        "Confirm",
        "Confirm Investment",
        () => {
          postData("invest/" + id, {}, () => {
            window.location.reload();
          });
        }
      ),
    ]);
  }
  const tabData = [
    { name: "All", length: raw_data.length },
    { name: "Active", length: pending },
    { name: "Completed", length: completed },
  ];
  useEffect(() => {
    const filteredDataPending = raw_data.filter(
      (item) => item.Status.props.children === "active"
    );
    const filteredDataCompleted = raw_data.filter(
      (item) => item.Status.props.children === "completed"
    );
    set_completed(filteredDataCompleted.length);
    set_pending(filteredDataPending.length);
    if (index === 0) {
      set_data(raw_data);
    }
    if (index === 1) {
      set_data(filteredDataPending);
    }
    if (index === 2) {
      set_data(filteredDataCompleted);
    }
  }, [index, raw_data]);

  return (
    <>
      <Main
        totalText={"Total Investment"}
        totalValue={totalData}
        isDollar={false}
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            class="bi bi-graph-up-arrow"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M0 0h1v15h15v1H0zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5"
            />
          </svg>
        }
      />
      <HistoryData
        data={data}
        condition={"Profit"}
        tab={tabData}
        activeTabIndex={(data) => {
          set_index(data);
        }}
        data_name={"investment"}
      />
    </>
  );
};

export default AdminInvestment;
