import React from "react";
import { Link, useLocation } from "react-router-dom";
import { activeLink } from "../../../util/helper_fun";

const Tab = () => {
  const tab = [
    { name: "Let's Trade For You", link: "/plan" },
    // { name: "Purchase Our Bot", link: "/plan/purchase" },
    { name: "Purchase Signal", link: "/plan/signal" },
    // { name: "Account Management", link: "/plan/management" },
    { name: "Calculate Profit", link: "/plan/profit" },
  ];
  const location = useLocation();
  const plan = activeLink(location, "/plan/purchase");
  return (
    <div className="w-100 mb-3">
      <div className="d-flex justify-content-start ">
        {tab.map((item, index) => (
          <Link
            key={index}
            className={`${
              activeLink(location, item.link) && "active_bottom"
            } styled-font p-2`}
            to={item.link}
          >
            {item.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Tab;
