import React, { useEffect, useState } from "react";
import Preloader from "../../shared/components/preloader";
import Error500 from "../../shared/components/error500";
import usePageTitle from "../../hooks/usePageTitle";

const Legal = () => {
  const [loading, setloading] = useState(true);
  usePageTitle("Legal Information: Understand Our Policies and Terms");
  useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 1000 * 30);
  }, []);
  return (
    <div className="vh-100 vw-100">
      {loading ? (
        <Preloader customDisplay={true} opacity={true} />
      ) : (
        <Error500 />
      )}
    </div>
  );
};

export default Legal;
