import { handleSetModal } from "../util/helper_fun";
import { useAppContext } from "../contexts/app_context";

const useIsUserError = (message) => {
  const { setShow } = useAppContext();
  function handleError() {
    setShow((prev) => [
      ...prev,
      handleSetModal(message, false, "Unauthorized Access", () => {}),
    ]);
  }
  return {handleError};
};

export default useIsUserError;
