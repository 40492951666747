import React, { useEffect, useState } from "react";
import usePageTitle from "../../hooks/usePageTitle";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LockOutlined,
  MailOutlined,
  UserOutlined,
} from "@ant-design/icons";
import GlobalInput from "../../shared/components/globalInput";
import useToggle from "../../hooks/useToggle";
import Alert from "../../shared/components/alert";
import usePostData from "../../hooks/usePostData";
import { validateForm } from "../../util/helper_fun";
import Cookies from "js-cookie";
import Spinner from "../../shared/components/spinner";
import { gotoLocation } from "../../util/util";
import { useSearchParams } from "react-router-dom";
import { useAppContext } from "../../contexts/app_context";
import axios from "axios";

const Register = () => {
  const [show_password, toggle_show_password] = useToggle(false);
  const [email, set_email] = useState();
  const [password, set_password] = useState();
  const [email_error, set_email_error] = useState();
  const [password_error, set_password_error] = useState();
  const [referral, set_referral] = useState();
  const [name, set_name] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const refParams = searchParams.get("ref");
  useEffect(() => {
    if (refParams) {
      set_referral(refParams);
    }
  }, []);
  const { postData, loading, message } = usePostData();

  const { siteData } = useAppContext();

  usePageTitle(
    `Join ${siteData?.app_name}: Start Your Financial Journey Here!`
  );
  async function handleRegister(e) {
    e.preventDefault();
    const isValid = validateForm(
      email,
      password,
      name,
      (msg) => set_email_error(msg),
      (msg) => set_password_error(msg)
    );

    if (isValid) {
      const userCountry = await axios.get("https://ipapi.co/json");
      const data = {
        email,
        password,
        referral,
        name,
        country: userCountry.data.country_name,
        countryCode: userCountry.data.country_code,
        currency: userCountry.data.currency,
      };
      await postData(
        "user",
        data,
        (data) => {
          Cookies.set("user", data);
          // gotoLocation("/login");
        },
        "alert"
      );
    }
  }

  return (
    <>
      <div
        class="account-section bg_img"
        style={{ background: "url(/assets/images/bg/bg-5.jpg)" }}
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-7">
              <div class="account-card">
                <div class="account-card__header login_bg bg_img overlay--one">
                  <h2 class="section-title">Join the Elite Circle</h2>
                </div>
                <div class="account-card__body">
                  {message && <Alert type={message.type} msg={message.msg} />}
                  <form onSubmit={handleRegister} class="mt-4">
                    <GlobalInput
                      label="What can we call you?"
                      preIcon={
                        <UserOutlined style={{ color: "#ccc", fontSize: 20 }} />
                      }
                      getValue={(val) => {
                        set_name(val);
                      }}
                      inputPlaceholder="enter name here"
                      inputType="text"
                      inputVal={name}
                    />
                    <GlobalInput
                      label="Email"
                      preIcon={
                        <MailOutlined style={{ color: "#ccc", fontSize: 20 }} />
                      }
                      getValue={(val) => {
                        set_email(val.toLowerCase());
                      }}
                      inputPlaceholder="enter your email here"
                      inputType="text"
                      error={email_error}
                      inputVal={email}
                    />
                    <GlobalInput
                      label="Password"
                      preIcon={
                        <LockOutlined style={{ color: "#ccc", fontSize: 20 }} />
                      }
                      getValue={(val) => {
                        set_password(val);
                      }}
                      inputPlaceholder="enter password"
                      inputType={show_password ? "text" : "password"}
                      postIcon={
                        !show_password ? (
                          <EyeOutlined
                            style={{ color: "#ccc", fontSize: 20 }}
                          />
                        ) : (
                          <EyeInvisibleOutlined
                            style={{ color: "#ccc", fontSize: 20 }}
                          />
                        )
                      }
                      inputFn={() => {
                        toggle_show_password();
                      }}
                      error={password_error}
                      inputVal={password}
                    />
                    <GlobalInput
                      label="Referral (optional)"
                      getValue={(val) => {
                        set_referral(val);
                      }}
                      inputPlaceholder="enter referral code"
                      inputType={"text"}
                      inputVal={referral}
                      readOnly={refParams}
                    />
                    <div class="form-row mt-4">
                      <div class="col-sm-6">
                        <p class="f-size-14">
                          Already a member?{" "}
                          <a href="/login" class="base--color">
                            Log In
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="w-100 mt-3 styled-font">
                      <p style={{ fontSize: 14, color: "#ccc" }} className="">
                        By continuing, you have agreed to {siteData?.app_name}
                        's{" "}
                        <a href="/legal" className="base--color styled-font">
                          terms
                        </a>{" "}
                        &{" "}
                        <a className="base--color styled-font" href="/legal">
                          conditions
                        </a>
                      </p>
                    </div>
                    <div class="mt-3">
                      <button
                        type="submit"
                        disabled={
                          loading ||
                          !name ||
                          !email ||
                          !password ||
                          email_error ||
                          password_error
                        }
                        class="cmn-btn "
                      >
                        {loading ? (
                          <>
                            <Spinner />
                            Please wait...
                          </>
                        ) : (
                          "Create account"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
