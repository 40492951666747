import React, { useEffect, useState } from "react";
import useGetPrivateData from "./../../../hooks/useGetPrivateData";
import HistoryData from "../components/history_data";
import { useAppContext } from "../../../contexts/app_context";
import Main from "../components/main_data";
import WithdrawalModal from "../components/modals/withdralModal";
import { handleSetModal } from "../../../util/helper_fun";
import CompleteCrypto from "../components/modals/completeCrypto";
import usePostPrivateData from "../../../hooks/usePostPrivateData";
import Preloader from "../../../shared/components/preloader";

const Withdraw = () => {
  const [data, set_data] = useState([]);
  const [raw_data, set_raw_data] = useState([]);
  const [dat, set_dat] = useState([]);
  const [index, set_index] = useState(0);
  const [completed, set_completed] = useState(0);
  const [pending, set_pending] = useState(0);
  const [withdraw, set_withdraw] = useState();
  const { getPrivateData } = useGetPrivateData();
  const { getPrivateData: getAddress } = useGetPrivateData();
  const { postPrivateData, message, loading: posting } = usePostPrivateData();
  const { getPrivateData: getWithdraw, loading: wLoading } =
    useGetPrivateData();

  const {
    screenWidth,
    setShow,
    setGlobalLoading,
    setGlobalError,
    globalLoading,
  } = useAppContext();
  useEffect(() => {
    getPrivateData("/withdraw", (data) => {
      set_dat(data);

    });
    getWithdraw("total?params=withdraw", (data) => {
      set_withdraw(data);
    });
  }, []);
  useEffect(() => {
    const d = dat.map((item) => {
      if (screenWidth <= 992) {
        return {
          Amount: item.amount,
          "Withdrawal ID": item.id,
          Payment: item.method,
          Status: item.status,
          Date: item.date,
          Time: item.time,
        };
      } else {
        // Otherwise, arrange by ID
        return {
          "Withdrawal ID": item.id,
          Amount: item.amount,
          Payment: item.method,
          Status: item.status,
          Date: item.date,
          Time: item.time,
        };
      }
    });
    set_raw_data(d);
  }, [screenWidth, dat]);

  const tabData = [
    { name: "All", length: raw_data.length },
    { name: "Pending", length: pending },
    { name: "Completed", length: completed },
  ];
  useEffect(() => {
    const filteredDataPending = raw_data.filter(
      (item) => item.Status === "pending"
    );
    const filteredDataCompleted = raw_data.filter(
      (item) => item.Status === "completed"
    );
    set_completed(filteredDataCompleted.length);
    set_pending(filteredDataPending.length);
    if (index === 0) {
      set_data(raw_data);
    }
    if (index === 1) {
      set_data(filteredDataPending);
    }
    if (index === 2) {
      set_data(filteredDataCompleted);
    }
  }, [index, raw_data]);
  function handleCreateWithdrawal(params, address) {
    setGlobalError();
    const data = {
      amount: params.value,
      method: params.name,
      isRecommended: params.recommended,
      address,
    };
    postPrivateData("/withdraw", data, () => {
      setShow([]);
    });
  }
  function getUserAddress(params) {
    getAddress("/user?params=address", (data) => {
      data ? handleCreateWithdrawal(params) : completeDeposit(params);
    });
  }
  function withdrawNow(params) {
    setGlobalError();
    let payment;
    setShow((prev) => [
      ...prev,
      handleSetModal(
        <WithdrawalModal
          getData={(d) => {
            payment = d;
          }}
          text={"Withdraw"}
        />,
        "Request Withdrawal",
        "Withdraw",
        () => {
          payment.recommended
            ? getUserAddress(payment)
            : handleCreateWithdrawal(payment);
        }
      ),
    ]);
  }
  function completeDeposit(params) {
    setGlobalError();
    let ad;
    setShow((prev) => [
      ...prev,
      handleSetModal(
        <CompleteCrypto
          getId={(a) => {
            ad = a;
          }}
          headerTxt={"Enter Wallet Address"}
          textLabel={"Enter or Paste Wallet Address "}
        />,
        "Save",
        "Enter Wallet Address",
        () => {
          handleCreateWithdrawal(params, ad);
        }
      ),
    ]);
  }

  useEffect(() => {
    if (message) {
      setGlobalLoading(true);
      setTimeout(() => {
        setGlobalError(message.msg);
        setGlobalLoading(false);
      }, 1000 * 3);
    } else {
      setGlobalError();
      setGlobalLoading(false);
    }
  }, [message]);
  return (
    <>
      <Main
        totalText={"Total Withdrawals"}
        totalValue={withdraw?.sum}
        name={"Number of Withdrawals"}
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            fill="currentColor"
            class="bi bi-wallet-fill"
            viewBox="0 0 16 16"
          >
            <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2z" />
            <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5z" />
          </svg>
        }
        btn_text={"Withdraw Now"}
        btnFn={() => {
          withdrawNow();
        }}
        value={withdraw?.length}
      />
      <HistoryData
        data={data}
        condition={"Amount"}
        tab={tabData}
        activeTabIndex={(data) => {
          set_index(data);
        }}
        data_name={"withdrawal"}
      />
      {(globalLoading || posting || wLoading) && <Preloader opacity={true} />}
    </>
  );
};

export default Withdraw;
